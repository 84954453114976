import React from 'react';
import classNames from 'classnames';
import { Infra } from '@getvim/vim-connect';
import { ClickableDiv, Tooltip } from '@getvim/atomic-ui';
import { SVGIconProps } from '@getvim/vim-icons';
import { NotificationsIndicator } from '../../atoms/notificationsIndicator';
import './index.less';

interface AppMenuIconProps {
  onClick?: () => void;
  iconSource: React.FC<SVGIconProps>;
  id: string;
  loading?: boolean;
  disabled?: boolean;
  whiteBackground?: boolean;
  notifications?: number;
  ignoreNotificationsValue?: boolean;
  tooltip: string;
  hubPosition: Infra.Common.Types.HubPosition;
  selected?: boolean;
  defaultIconColor?: string;
}

export const AppMenuIcon: React.FC<AppMenuIconProps> = ({
  onClick,
  id,
  iconSource,
  loading,
  disabled,
  whiteBackground,
  notifications,
  ignoreNotificationsValue,
  tooltip,
  hubPosition,
  selected = false,
  defaultIconColor = 'var(--app-icon-color)',
}) => {
  const getIconFillColor = () => {
    if (selected) return 'var(--app-icon-selected-color)';
    if (loading) return 'var(--app-icon-color)';
    if (disabled) return 'var(--app-icon-disabled-color)';
    return defaultIconColor;
  };

  const iconFillColor = getIconFillColor();

  const onClickWrapper = () => {
    if (disabled) return;
    return onClick?.();
  };

  const IconSource = iconSource;

  const tooltipPlacement =
    hubPosition.horizontal === Infra.Common.Types.HorizontalHubPosition.Left ? 'right' : 'left';

  return (
    <Tooltip
      pillStyle
      hideArrow
      placement={tooltipPlacement}
      tooltipContent={tooltip}
      bgColor="buttonColor"
      className="app-menu-icon-tooltip"
    >
      <ClickableDiv
        className={classNames(
          'app-menu-icon-wrap',
          id,
          { selected },
          { disabled },
          { loading },
          { white: whiteBackground },
        )}
        onClick={onClickWrapper}
      >
        {notifications && notifications > 0 ? (
          <NotificationsIndicator ignoreValue={!!ignoreNotificationsValue} value={notifications} />
        ) : null}
        <IconSource
          className={classNames('icon', { loading: !selected && loading })}
          fill={iconFillColor}
        />
      </ClickableDiv>
    </Tooltip>
  );
};

export default AppMenuIcon;
