import React, { FC } from 'react';
import './index.less';
import LoaderGif from './please-wait.svg';

const OptumLoader: FC = () => (
  <div className="writeback-frame-container">
    <div className="overlay-loader-modal">
      <div className="logo-container">
        <img
          className="optum-logo"
          alt="OPTUM Order Utility"
          src="https://static.getvim.com/img/logos/Optum-logo-2021.svg"
        />
        <div className="vertical-bar">|</div>
        <div className="img-title-text">Order Utility</div>
      </div>

      <object type="image/svg+xml" data={LoaderGif} className="animated-writeback-loading">
        writeback-loading
      </object>
      <h2 className="main-title">Syncing data, please wait.</h2>
      <div className="main-text">Please refrain from using the mouse or keyboard</div>
      <img
        alt="mouse-keyboard-restrict"
        className="footer-note-img"
        src="http://static.getvim.com/img/no-keyboard-and-mouse.png"
      />
    </div>
  </div>
);


export default OptumLoader;
