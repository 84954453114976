import { Infra, RuntimeContract } from '@getvim/vim-connect';

export const HEIGHT_MARGIN_PIXELS = 10;
export const WRAPPER_HEIGHT_VH = 90;
export const MENU_WIDTH_PIXELS = 50;
export const MENU_WIDTH_PIXELS_SHRINK = 35;
export const MENU_WIDTH_MARGIN = 10;
export const WIDGET_CONTENT_WIDTH = 365;
export const MENU_WRAPPER_TOP_MARGIN = 70;
export const APP_BUTTON_EDGE_LENGTH_PIXELS = 50;
export const APP_BUTTON_EDGE_LENGTH_PIXELS_SHRINK = 35;
export const NOTIFICATION_WIDTH = MENU_WIDTH_PIXELS + 30;

export const verticalMovementToPosition = (
  verticalPosition: Infra.Common.Types.VerticalHubPosition,
  verticalDirection: Infra.Common.Types.VerticalDirection,
) => {
  switch (verticalPosition) {
    case Infra.Common.Types.VerticalHubPosition.High:
      if (verticalDirection === Infra.Common.Types.VerticalDirection.Up) {
        return verticalPosition;
      }
      return Infra.Common.Types.VerticalHubPosition.High_Middle;
    case Infra.Common.Types.VerticalHubPosition.High_Middle:
      if (verticalDirection === Infra.Common.Types.VerticalDirection.Up) {
        return Infra.Common.Types.VerticalHubPosition.High;
      }
      return Infra.Common.Types.VerticalHubPosition.Middle;
    case Infra.Common.Types.VerticalHubPosition.Middle:
      if (verticalDirection === Infra.Common.Types.VerticalDirection.Up) {
        return Infra.Common.Types.VerticalHubPosition.High_Middle;
      }
      return Infra.Common.Types.VerticalHubPosition.Low_Middle;
    case Infra.Common.Types.VerticalHubPosition.Low_Middle:
      if (verticalDirection === Infra.Common.Types.VerticalDirection.Up) {
        return Infra.Common.Types.VerticalHubPosition.Middle;
      }
      return Infra.Common.Types.VerticalHubPosition.Low;
    case Infra.Common.Types.VerticalHubPosition.Low:
      if (verticalDirection === Infra.Common.Types.VerticalDirection.Down) {
        return verticalPosition;
      }
      return Infra.Common.Types.VerticalHubPosition.Low_Middle;
  }
};

export const shouldAddExtraPaddingToToast = () => {
  return navigator?.userAgent?.indexOf('Electron') > 0;
};

export const getBoundingClientRect = (appIcon: HTMLDivElement): DOMRect => {
  return appIcon.getBoundingClientRect();
};

const removePxFromString = (length?: string) => (length ? length.replace('px', '') : undefined);

export const horizontalMovementToPosition = (
  hubHorizontalDirection: Infra.Common.Types.HorizontalHubPosition,
  alignment: RuntimeContract.Incoming.Alignment,
): {
  left: string | undefined;
  right: string | undefined;
  bottom: string | undefined;
  top: string | undefined;
} => {
  let bottom = removePxFromString(alignment.bottom);
  let top = removePxFromString(alignment.top);
  if (top) {
    top = !isNaN(Number(top))
      ? `${Number(top) + HEIGHT_MARGIN_PIXELS}`
      : `calc(${top} + ${HEIGHT_MARGIN_PIXELS}px)`;
  }
  if (bottom) {
    bottom = !isNaN(Number(bottom))
      ? `${Number(bottom) + HEIGHT_MARGIN_PIXELS}`
      : `calc(${bottom} + ${HEIGHT_MARGIN_PIXELS}px)`;
  }
  return hubHorizontalDirection === Infra.Common.Types.HorizontalHubPosition.Left
    ? {
        left: `${NOTIFICATION_WIDTH}`,
        right: undefined,
        bottom,
        top,
      }
    : {
        right: `${NOTIFICATION_WIDTH}`,
        left: undefined,
        bottom,
        top,
      };
};
