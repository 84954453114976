interface PatientDetails {
  lastPopupDate: string;
  numberOfAutoPopups: number;
}

export interface PatientsDetails {
  [vimPatientId: string]: PatientDetails;
}

const todayDate = () => new Date().toISOString().slice(0, 10);

export const MAX_POPUP_NUMBER = 1;

const patientsDetails: PatientsDetails = {};

export const storePatientDetails = (vimPatientId: string | undefined): void => {
  if (!vimPatientId) {
    return;
  }

  const today = todayDate();
  // first time this patient got here or first time today
  if (!patientsDetails[vimPatientId] || patientsDetails[vimPatientId].lastPopupDate < today) {
    patientsDetails[vimPatientId] = {
      lastPopupDate: today,
      numberOfAutoPopups: 1,
    };
  } else {
    patientsDetails[vimPatientId].numberOfAutoPopups++;
  }
};

export const doesProviderReachedPatientLimit = (vimPatientId: string | undefined): boolean => {
  if (!vimPatientId) {
    return false;
  }

  if (!patientsDetails[vimPatientId]) {
    return false;
  }

  if (patientsDetails[vimPatientId].lastPopupDate < todayDate()) {
    return false;
  }

  if (patientsDetails[vimPatientId].numberOfAutoPopups <= MAX_POPUP_NUMBER) {
    return false;
  }

  return true;
};

export const setPatientsDetailsState = (state: PatientsDetails) =>
  Object.assign(patientsDetails, state);

export const getPatientsDetails = () => patientsDetails;

export const getPatientDetails = (vimPatientId?: string): PatientDetails | undefined =>
  vimPatientId ? patientsDetails[vimPatientId] : undefined;
