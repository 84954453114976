import { SVGIconProps } from '@getvim/vim-icons';

export interface Application {
  id: string;
  tooltip: string;
  iframeSource: string;
  iconSource: React.FC<SVGIconProps>;
  notifications?: number;
  ignoreNotifications?: boolean;
  ignoreNotificationsValue?: boolean;
  disabled?: boolean;
  loading?: boolean;
  loaded?: boolean;
  size?: Size;
  reason?: ModifyReason;
  responseTime: number;
}

export type ApplicationInput = Omit<Application, 'iconSource'>;

export interface ModifyApplicationPayload {
  id: Application['id'];
  modifications: Partial<ApplicationInput>;
}

export enum ModifyReason {
  // when we get response from server [with or w/o data]
  Response = 'response',

  /**
   * patient matched with one of the app data sources
   * @example
   * patient insurer: 'UHC'
   * data sources: ['mycare', 'UHC']
   */
  PatientEligible = 'patientEligible',
}

interface Size {
  height: string;
  width: string;
}
