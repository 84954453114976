import { useAnalytics } from '@getvim/vim-app-infra';
import { Common, Infra, Standard } from '@getvim/vim-connect';
import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import { uniq } from 'lodash-es';
import React, { createContext, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { VimConnectUiAnalyticsEventTypes } from '../app/analytics/analytics';
import { useSubscription } from './useEvents';
import { useOrganization } from './useOrganization';
import { useSystemData } from './useSystemData';

const PatientContext = createContext<
  [
    Standard.Events.TransformedPatientInContextPayload | undefined,
    (app: Standard.Events.TransformedPatientInContextPayload | undefined) => void,
  ]
>(undefined as any);

interface CurrentPatientSession {
  patientSession: string;
  vimPatientId: string;
}

export const PatientProvider: React.FC = ({ children }) => {
  const { analyticsClient } = useAnalytics();
  const { initialData } = useSystemData();
  const { organization } = useOrganization();
  const [patient, setPatient] = useState<Standard.Events.TransformedPatientInContextPayload>();
  const [patientsSessions, setPatientsSessions] = useState<CurrentPatientSession>({
    patientSession: '',
    vimPatientId: '',
  });

  const getAllOrganizationSources = () => {
    const { config } = organization ?? {};
    const { applications } = config ?? {};

    const result = Object.entries<any>(applications ?? {}).reduce(
      (prev, [, { sources, source }]) => {
        const safeSources = [source]
          .concat(
            Array.isArray(sources)
              ? sources
              : ((sources as string)?.split(',') as Infra.Apps.ProductSource[]),
          )
          .filter((source) => source);
        safeSources?.forEach((source) => prev.add(source));
        return prev;
      },
      new Set(),
    );

    return Array.from(result);
  };

  const getSourcesToBeQueried = (patient: Standard.Events.TransformedPatientInContextPayload) => {
    const { config } = organization ?? {};
    const { applications } = config ?? {};

    const result = Object.entries<any>(applications || {}).reduce(
      (prev, [, { sources, source }]) => {
        const activeSource = Common.getActiveSource(patient, (sources || []).concat([source]));

        if (activeSource) prev.add(activeSource);
        return prev;
      },
      new Set(),
    );

    return Array.from(result);
  };

  /**
   * @returns array of insurers where we have member token
   * @example ['UHC', 'FLORIDA_BLUE']
   */
  const getPatientFoundInSource = (memberTokens?: Standard.Events.MemberTokens) => {
    if (!memberTokens) return;

    return Object.entries<any>(memberTokens)
      .filter(
        ([, memberToken]) =>
          memberToken?.newMemberTokenVersion || memberToken?.oldMemberTokenVersion,
      )
      .map(([insurer]) => insurer);
  };

  useSubscription(
    WidgetIncomingEvent.PatientInContext,
    ([incomingPatient]) => {
      setPatient(incomingPatient);
      const { vimPatientId, memberTokens, potentialInsurers, contentSupplierInternalPersonIds } =
        (incomingPatient as Standard.Events.TransformedPatientInContextPayload) || {};

      const patientSession =
        vimPatientId && patientsSessions.vimPatientId === vimPatientId
          ? patientsSessions.patientSession
          : uuid();
      setPatientsSessions({ vimPatientId: vimPatientId ?? '', patientSession });
      analyticsClient.setSession({ patientSession });

      const contentSources = uniq(initialData?.products?.flatMap((product) => product.contentSources));
      const providerSources = uniq(initialData?.products?.flatMap((product) => product.providerSources ?? []));
      const properties = potentialInsurers
        ? {
            enabled_data_sources_for_user: contentSources,
            potential_data_sources_for_patient: uniq(
              providerSources.concat(potentialInsurers as []),
            ),
            patient_found_in_source: Object.keys(contentSupplierInternalPersonIds ?? {}),
          }
        : {
            enabled_data_sources_for_user: getAllOrganizationSources(),
            potential_data_sources_for_patient: getSourcesToBeQueried(incomingPatient),
            patient_found_in_source: getPatientFoundInSource(memberTokens),
          };

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubPatientDetected,
        properties,
      });
    },
    [initialData],
  );

  useSubscription(WidgetIncomingEvent.PatientOutContext, () => {
    setPatient(undefined);
  });

  useSubscription(WidgetIncomingEvent.Logout, () => {
    setPatient(undefined);
  });

  return (
    <PatientContext.Provider value={[patient, setPatient]}>{children}</PatientContext.Provider>
  );
};

export const usePatient = () => {
  return useContext(PatientContext);
};
