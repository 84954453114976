import React, { FC } from 'react';
import { Button } from '@getvim/atomic-ui';
import './index.less';
import classNames from 'classnames';

interface CloseButtonProps {
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClick, style }) => {
  return (
    <Button
      className="vim-hub-close-button"
      buttonType="link"
      onClick={onClick}
      style={{ ...style }}
    >
      <i className="icon-x" />
    </Button>
  );
};
