import { Infra } from '@getvim/vim-connect';
import { USER_MANAGEMENT_APPLICATION_ID } from '../components/app/types/management-application';

const applicationWithoutPatientHeader = [
  USER_MANAGEMENT_APPLICATION_ID,
  Infra.Apps.Product.PriviaInsights,
  Infra.Apps.Product.OptumDataCoreDashboard,
];

export const isShowPatientHeader = (appId: string): boolean => {
  return !applicationWithoutPatientHeader.includes(appId);
};
