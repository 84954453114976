import React from 'react';
import VimLogoIcon from '@getvim/atomic-ui/assets/images/logos/vimLogoSm.png';
import './PoweredByVim.less';

const PoweredByVim: React.FC = () => (
  <div className="powered-by-vim">
    <div>Powered by</div>
    <img src={VimLogoIcon} alt="powered-by-vim" className="vim-logo" />
  </div>
);

export default PoweredByVim;
