import gql from 'graphql-tag';
import { Infra } from '../../../..';
import { gqlClient } from '../../gql-client';
import { HorizontalHubPosition, HubPosition, VerticalHubPosition } from '../../types';

const getHubPositionQuery = gql`
  query getHubPosition {
    getHubPosition {
      horizontal
      vertical
    }
  }
`;

const setHubPositionMutation = gql`
  mutation setHubPosition($input: SetHubPositionInput!) {
    setHubPosition(input: $input) {
      success
    }
  }
`;

interface HubPositionQueryResult {
  getHubPosition: {
    vertical: VerticalHubPosition;
    horizontal: HorizontalHubPosition;
  };
}

export const getHubPosition = async (): Promise<HubPosition | undefined> => {
  try {
    const deviceId = gqlClient.getDeviceId();

    const { data } = await gqlClient.queryAuthenticated<HubPositionQueryResult>({
      query: getHubPositionQuery,
      fetchPolicy: 'no-cache',
      context: {
        uri: '/runtime/api/graphql',
        headers: { ...(deviceId ? { ['x-vim-device-id']: deviceId } : undefined) },
      },
    });

    const { horizontal, vertical } = data?.getHubPosition;
    return { horizontal, vertical };
  } catch (error) {
    return Infra.Common.Consts.DEFAULT_HUB_POSITION;
  }
};

export const setHubPosition = async (hubPosition: HubPosition): Promise<void> => {
  const deviceId = gqlClient.getDeviceId();

  await gqlClient.mutateAuthenticated<HubPositionQueryResult>({
    mutation: setHubPositionMutation,
    variables: { input: { hubPosition, sentAt: new Date() } },
    context: {
      uri: '/runtime/api/graphql',
      headers: { ...(deviceId ? { ['x-vim-device-id']: deviceId } : undefined) },
    },
  });

  return;
};
