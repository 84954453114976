import dayjs from 'dayjs';

export const getPatientDateOfBirth = (dateOfBirth: string) => dayjs(dateOfBirth);

/**
 * Get age break down to years, month age
 * @example
 * getPatientDateOfBirthString({ years: 93, months: 5, days: 6 }) => 93 yo
 */
export const getPatientAgeString = ({
  years,
  months,
  days,
}: {
  years: number;
  months: number;
  days: number;
}) => {
  if (!years && !months && !days) return 'Invalid date';

  if (years > 0) return `${years} yo`;
  if (months > 0) return `${months} months`;
  return `${days} days`;
};

/**
 * Get age break down to years, months and days
 * @example
 * In 2022-04-24
 * getAgeInYearsMonthDays("1928-11-18") => { years: 93, months: 4, days: 6 }
 */
export const getAgeInYearsMonthDays = (dateOfBirth: string) => {
  try {
    const now = dayjs();
    const years = now.diff(dateOfBirth, 'years');
    const months = now.diff(dateOfBirth, 'months');
    const days = now.diff(dateOfBirth, 'days') || 1;

    return {
      years,
      months,
      days,
    };
  } catch (error) {
    return { years: 0, months: 0, days: 0 };
  }
};
