import { CommonConnector } from '@getvim/extension-connector';

const USER_MANAGEMENT_STORAGE_KEY = 'user-management';

export interface ManagementNotification {
  lastShowDate: string;
  numberOfShows: number;
}

interface UserManagementStorageValue {
  notifications: {
    [notificationId: string]: ManagementNotification;
  };
}

class UserManagementStorage {
  getNotifications = async (): Promise<UserManagementStorageValue['notifications']> => {
    const { [USER_MANAGEMENT_STORAGE_KEY]: userManagementStorage } =
      await CommonConnector.storageGet({
        key: USER_MANAGEMENT_STORAGE_KEY,
      });
    const { notifications } = userManagementStorage || {};

    return notifications;
  };

  setNotifications = async (notifications: UserManagementStorageValue['notifications']) => {
    return CommonConnector.storageSet({
      data: {
        [USER_MANAGEMENT_STORAGE_KEY]: {
          notifications,
        },
      },
    });
  };
}

export const userManagementStorage = new UserManagementStorage();
