import React from 'react';
import { toast, Slide, ToastOptions } from 'react-toastify';
import { useTranslation } from '@getvim/translate';
import { ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import Text from '../../atoms/text';

import './index.less';
import { messages } from './translations/messages';

export interface ErrorToastProps {
  title: string;
  message: string;
  html?: boolean;
  dismissButton?: boolean;
}

function ErrorToast({ title, message, html, dismissButton = true }: ErrorToastProps) {
  const intl = useTranslation({});
  return (
    <div className="error-toast">
      <Text
        size={SizeEnum['18px']}
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.bold}
        text={title}
        html={html}
      />
      <Text
        className="margin-top-10"
        colorName={ColorNameEnum.ultraDark}
        size={SizeEnum['14px']}
        text={message}
        html={html}
      />
      {dismissButton && (
        <Text
          className="margin-top"
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.semibold}
          size={SizeEnum['14px']}
          text={intl.formatMessage(messages.dismiss)}
        />
      )}
    </div>
  );
}

export type ErrorToastOptions = ErrorToastProps & ToastOptions;

export function createErrorToast({
  title,
  message,
  html,
  dismissButton,
  ...toastifyOptions
}: ErrorToastOptions): () => void {
  const toasterId = toastifyOptions?.toastId ?? `${title}_${message}`;
  toast.dismiss(toasterId);

  toast.error(
    <ErrorToast title={title} message={message} html={html} dismissButton={dismissButton} />,
    {
      className: toastifyOptions?.className
        ? `atomic-error-toast ${toastifyOptions.className}`
        : 'atomic-error-toast',
      transition: Slide,
      closeButton: false,
      toastId: toasterId,
      ...toastifyOptions,
    },
  );

  return () => toast.dismiss(toasterId);
}
