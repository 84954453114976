import _React, { useEffect, useState } from 'react';
import { Standard } from '@getvim/vim-connect';
import { useVimCommunication } from '@getvim/vim-app-infra';
import { VimConnectSubApp } from '@getvim/vim-connect-communication';

interface Options {
  injectOnMount?: boolean;
}

type WidgetConfig =
  | Standard.Entities.UIElements.InlineWidget
  | Standard.Entities.UIElements.OverlayWidget;

type UseSubApplicationReturn = [VimConnectSubApp, boolean, boolean];

export const useSubApplication = (
  widgetConfig: WidgetConfig,
  options?: Options,
): UseSubApplicationReturn => {
  const { injectOnMount } = options ?? {};
  const { vimCommunication } = useVimCommunication();

  const [isReady, setIsReady] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [subApplicationInstance] = useState<VimConnectSubApp>(
    new VimConnectSubApp(vimCommunication!, widgetConfig),
  );

  const inject = async () => {
    try {
      await subApplicationInstance.injectWidget();
      setIsReady(true);
    } catch (error) {
      console.error('error occurred during injection', { error });
      setIsFailed(true);
    }
  };

  useEffect(() => {
    if (vimCommunication && injectOnMount) inject();
  }, [vimCommunication]);

  return [subApplicationInstance, isReady, isFailed];
};
