import { InitialData, WidgetIncomingEvent } from '@getvim/vim-connect-app';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useSubscription } from './useEvents';
import { Infra } from '@getvim/vim-connect';
import {
  DEFAULT_USER_MANAGEMENT,
  UserManagement,
  UserManagementInput,
} from '../app/types/management-application';
import { useUser } from './useMe';
import { useOrganization } from './useOrganization';
import { setPatientsDetailsState } from '../../patients-details/patients-details';

type SystemDataContextValue = {
  initialData: Omit<InitialData, 'hubPosition' | 'user' | 'organization'> | undefined;
  hubPosition: Infra.Common.Types.HubPosition;
  setHubPosition: (val: Infra.Common.Types.HubPosition) => void;
  userManagement: UserManagement;
  updateUserManagement: (userManagementInput: UserManagementInput) => void;
};

const SystemDataContext = createContext<SystemDataContextValue>({
  initialData: undefined,
  hubPosition: Infra.Common.Consts.DEFAULT_HUB_POSITION,
  setHubPosition: () => {},
  userManagement: DEFAULT_USER_MANAGEMENT,
  updateUserManagement: () => {},
});

export const SystemDataProvider: React.FC = ({ children }) => {
  const [userManagement, setUserManagement] = useState<UserManagement>(DEFAULT_USER_MANAGEMENT);
  const [initialData, setInitialData] = useState<InitialData | undefined>(undefined);
  const [hubPosition, setHubPosition] = useState<Infra.Common.Types.HubPosition>(
    Infra.Common.Consts.DEFAULT_HUB_POSITION,
  );

  const { updateMe } = useUser();
  const { updateOrganization } = useOrganization();

  useSubscription(
    WidgetIncomingEvent.Logout,
    () => {
      setHubPosition(Infra.Common.Consts.DEFAULT_HUB_POSITION);
    },
    [setHubPosition],
  );

  useSubscription(
    WidgetIncomingEvent.InitData,
    ([data]) => {
      const {
        hubPosition: initialHubPosition,
        user: me,
        organization,
        patientsDetails,
        ...rest
      } = data;
      setInitialData(rest);
      setPatientsDetailsState(patientsDetails || {});
      updateMe(me!);
      updateOrganization(organization!);
      setHubPosition(initialHubPosition ?? Infra.Common.Consts.DEFAULT_HUB_POSITION);
    },
    [],
  );

  const updateUserManagement = useCallback(
    (userManagementInput: UserManagementInput) => {
      setUserManagement({ ...userManagement, ...userManagementInput });
    },
    [userManagement],
  );

  return (
    <SystemDataContext.Provider
      value={{ initialData, hubPosition, setHubPosition, userManagement, updateUserManagement }}
    >
      {children}
    </SystemDataContext.Provider>
  );
};

export const useSystemData = () => {
  return useContext(SystemDataContext);
};
