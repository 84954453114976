import React from 'react';
import './ApplicationIcon.less';

interface NotificationsIndicatorProps {
  value: number;
  ignoreValue: boolean;
}

const NotificationsIndicator: React.FC<NotificationsIndicatorProps> = ({ value, ignoreValue }) => (
  <div className={`notification ${ignoreValue ? 'empty' : ''} ${value < 100 ? '' : 'wide'}`}>
    {ignoreValue ? '' : value < 100 ? value : '99+'}
  </div>
);

export default NotificationsIndicator;
