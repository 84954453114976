import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

const InvalidContext = createContext<[boolean, (val: boolean) => void]>([] as any);

export const InvalidProvider = ({ children }: PropsWithChildren<any>) => {
  const [invalid, setInvalid] = useState(false);

  return (
    <InvalidContext.Provider value={[invalid, setInvalid]}>{children}</InvalidContext.Provider>
  );
};

export const useHubInvalid = () => {
  return useContext(InvalidContext);
};
