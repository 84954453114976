import gql from 'graphql-tag';
import { FreeTextInput, QueryFreeTextResult } from '../../../graphql';
import { gqlClient } from '../gql-client';

const getFreeTextQuery = gql`
  query QueryFreeText($input: FreeTextInput!) {
    queryFreeText(input: $input) {
      suggestions {
        cpt {
          filters {
            value {
              taxonomyCode
              taxonomyDisplayName
            }
          }
        }
        icd {
          filters {
            value {
              taxonomyCode
              taxonomyDisplayName
            }
          }
        }
        nucc {
          filters {
            value {
              taxonomyCode
              taxonomyDisplayName
            }
          }
        }
      }
    }
  }
`;

export const getFreeTextGQLQuery = async (input: FreeTextInput): Promise<QueryFreeTextResult> => {
  const { data } = await gqlClient.query({
    query: getFreeTextQuery,
    variables: { input },
    fetchPolicy: 'no-cache',
    context: { uri: '/prior-auth/api/graphql' },
  });
  return data?.queryFreeText;
};
