import { logger } from '@getvim/vim-connect-logger';
import { Standard } from '../..';
import { ActionNames } from '../actions';
import { getVimPatientId } from '../../runtime-contract/outgoing/payloads-transformers/transformers/utils';

const transformerActionLogger = logger.scope('GET_PATIENT action transformer');

export const GET_PATIENT = async (
  actionResponse: Standard.Actions.ActionsStandard[ActionNames.GET_PATIENT]['response'],
): Promise<Standard.Actions.TransformedActionsStandard[ActionNames.GET_PATIENT]['response']> => {
  const vimPatientId = await getVimPatientId(actionResponse, transformerActionLogger);

  return { ...actionResponse, vimPatientId };
};
