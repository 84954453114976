import floridaBlueLogo from '../../../assets/florida-blue.svg';
import unitedHealthCareLogo from '../../../assets/united-healthcare.svg';
import anthemLogo from '../../../assets/anthem.svg';
import premeraLogo from '../../../assets/premera.svg';
import cignaLogo from '../../../assets/cigna.svg';
import humanaLogo from '../../../assets/Humana.svg';
import { Insurer } from '../types/patient';

export const insurerToLogo: { [key in Insurer]?: any } = {
  [Insurer.ANTHEM]: anthemLogo,
  [Insurer.FLORIDA_BLUE]: floridaBlueLogo,
  [Insurer.PREMERA]: premeraLogo,
  [Insurer.UHC]: unitedHealthCareLogo,
  [Insurer.CIGNA]: cignaLogo,
  [Insurer.HUMANA]: humanaLogo,
};
