import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSubscription } from './useEvents';

const ActiveContext = createContext<[boolean, (val: boolean) => void]>([] as any);

export const ActiveProvider = ({ children }: PropsWithChildren<any>) => {
  const [active, setActive] = useState(false);

  useSubscription(WidgetIncomingEvent.Logout, () => {
    setActive(false);
  });

  return <ActiveContext.Provider value={[active, setActive]}>{children}</ActiveContext.Provider>;
};

export const useActive = () => {
  return useContext(ActiveContext);
};
