import React, { useCallback, useEffect, useState } from 'react';
import { Entities, Infra, Standard } from '@getvim/vim-connect';
import { Button } from '@getvim/atomic-ui';
import { useAnalytics } from '@getvim/vim-app-infra';
import classNames from 'classnames';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { Application } from '../types/application';
import ApplicationIcon from '../application-icon/ApplicationIcon';
import TooltipIcon from '../application-icon/tooltipIcon';
import {
  analyticsClient as analyticsClientOld,
  VimConnectUiAnalyticsEventTypes,
} from '../analytics/analytics';
import { APP_BUTTON_EDGE_LENGTH_PIXELS, MENU_WIDTH_PIXELS } from '../utils/positioningUtils';
import './ApplicationMenu.less';
import { ElementInteraction } from '../types/analytics';
import {
  isAllAppsLoaded,
  sortApplications,
  getAppsActivationStatus,
  getAppsNotificationsCount,
} from '../../../common';
import { CloseButton } from '../common/close-button';

interface ApplicationMenuProps {
  organization?: Infra.Common.Types.MeOrganization;
  patient?: Standard.Events.TransformedPatientInContextPayload;
  expanded: boolean;
  isHubValid: boolean;
  shrink: boolean;
  applications: Record<Application['id'], Application>;
  selectedApp?: Application;
  hubPosition: Infra.Common.Types.HubPosition;
  onSelectApp: (appId: Application['id'], expandingType: Entities.UIElements.ExpandingType) => void;
  onClose: () => void;
  moveWidget: (direction: Infra.Common.Types.HubDirection) => void;
  expandOnMouseEnter: (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ApplicationMenu: React.FC<ApplicationMenuProps> = ({
  organization,
  patient,
  expanded,
  isHubValid,
  shrink,
  applications,
  selectedApp,
  hubPosition,
  onSelectApp,
  onClose,
  moveWidget,
  expandOnMouseEnter,
}) => {
  const { analyticsClient } = useAnalytics();
  const [notifications, setNotifications] = useState(0);
  const [ignoreValue, setIgnoreValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allAppsLoaded, setAllAppsLoaded] = useState(false);
  const [active, setActive] = useState(false);
  const handleSelectApp = (application: Application) => {
    onSelectApp(application.id, Entities.UIElements.ExpandingType.VIM_HUB_MANUAL);
  };

  const [sholdHideHubCloseButton] = useFeatureFlag({
    flagName: 'shouldHideHubCloseButtonInAppsMenu',
    defaultValue: false,
  });

  const getApplicationsIcons = useCallback(
    () =>
      Object.values(applications)
        .sort(sortApplications)
        .map((app) => (
          <ApplicationIcon
            key={app.id}
            selected={selectedApp?.id === app.id}
            onClick={() => {
              handleSelectApp(app);
            }}
            application={app}
            hubPosition={hubPosition}
            isWidgetOpen={!!selectedApp}
            customSize={selectedApp ? applications[selectedApp.id]?.size : undefined}
          />
        )),
    [applications, selectedApp, hubPosition],
  );

  useEffect(() => {
    const applicationsArray = Object.values(applications);
    const tempNotifications = applicationsArray.reduce(
      (sum, app) => (app.ignoreNotifications ? sum : sum + (app.notifications ?? 0)),
      0,
    );
    const tempValue = applicationsArray.some((app) => app.ignoreNotificationsValue);
    setNotifications(tempNotifications);
    setIgnoreValue(tempValue);
    setIsLoading(applicationsArray.some(({ loading }) => loading));
    setActive(applicationsArray.some(({ loading, disabled }) => !loading && !disabled));

    setAllAppsLoaded(isAllAppsLoaded(applications));
  }, [applications]);

  useEffect(() => {
    if (allAppsLoaded) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubLoaded,
        properties: {
          hub_activation_status: active,
          hub_notifications_count: getAppsNotificationsCount(applications),
          apps_activation_status: getAppsActivationStatus(organization!, applications, patient!),
        },
      });
    }
  }, [allAppsLoaded, analyticsClient]);

  useEffect(() => {
    if (active) {
      analyticsClientOld.setNewActiveSessionId();
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimChartBecomeActive, {
        patient_insurer: patient?.insurance?.insurer,
      });
    }
  }, [active]);

  const handleInteraction = (interaction: ElementInteraction) => {
    analyticsClient.track({
      event: VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction,
      properties: { interaction },
    });

    analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction, {
      interaction,
    });
  };

  const isHubPositionUpperLimit =
    hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.High;
  const isHubPositionLowerLimit =
    hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.Low;

  const horizontalOppositeDirection =
    hubPosition.horizontal === Infra.Common.Types.HorizontalHubPosition.Left
      ? { horizontal: Infra.Common.Types.HorizontalDirection.Right }
      : { horizontal: Infra.Common.Types.HorizontalDirection.Left };

  if (shrink) {
    return (
      <div
        className={classNames('menu-top')}
        onDragStart={() => handleInteraction(ElementInteraction.Drag)}
        onClickCapture={() => handleInteraction(ElementInteraction.Click)}
      >
        <TooltipIcon
          organization={organization}
          active={active}
          loading={isLoading}
          hubValid={isHubValid}
          notifications={expanded ? 0 : notifications}
          expandOnMouseEnter={expandOnMouseEnter}
          ignoreValue={ignoreValue}
        />
      </div>
    );
  }

  return (
    <div
      className={`menu white-bg ${expanded ? 'expanded' : 'collapsed'}`}
      style={{
        width: `${MENU_WIDTH_PIXELS}px`,
      }}
    >
      {!selectedApp && (
        <Button
          className={classNames('icon-chevron-up', 'chevron-button', 'up', {
            disabled: isHubPositionUpperLimit,
          })}
          buttonType="link"
          onClick={() => {
            moveWidget({ vertical: Infra.Common.Types.VerticalDirection.Up });
          }}
        />
      )}
      {sholdHideHubCloseButton ? (
        <>
          <div
            className="menu-top"
            onDragStart={() => handleInteraction(ElementInteraction.Drag)}
            onClickCapture={() => handleInteraction(ElementInteraction.Click)}
          >
            <TooltipIcon
              organization={organization}
              active={active}
              loading={isLoading}
              hubValid={isHubValid}
              notifications={expanded ? 0 : notifications}
              expandOnMouseEnter={expandOnMouseEnter}
              ignoreValue={ignoreValue}
            />
            {!selectedApp && (
              <Button
                className="icon-chevron-left chevron-button side-arrow"
                buttonType="link"
                onClick={() => {
                  moveWidget(horizontalOppositeDirection);
                }}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {selectedApp ? (
            <div
              role="none"
              className="icon-container"
              onClick={onClose}
              style={{
                height: `${APP_BUTTON_EDGE_LENGTH_PIXELS}px`,
              }}
            >
              <CloseButton />
            </div>
          ) : (
            <div
              className="menu-top"
              onDragStart={() => handleInteraction(ElementInteraction.Drag)}
              onClickCapture={() => handleInteraction(ElementInteraction.Click)}
            >
              <TooltipIcon
                organization={organization}
                active={active}
                loading={isLoading}
                hubValid={isHubValid}
                notifications={expanded ? 0 : notifications}
                expandOnMouseEnter={expandOnMouseEnter}
                ignoreValue={ignoreValue}
              />
              <Button
                className="icon-chevron-left chevron-button side-arrow"
                buttonType="link"
                onClick={() => {
                  moveWidget(horizontalOppositeDirection);
                }}
              />
            </div>
          )}
        </>
      )}

      {expanded && <div className="apps-icons">{getApplicationsIcons()}</div>}
      {!selectedApp && (
        <Button
          className={classNames('icon-chevron-down', 'chevron-button', 'down', {
            disabled: isHubPositionLowerLimit,
          })}
          buttonType="link"
          onClick={() => {
            moveWidget({ vertical: Infra.Common.Types.VerticalDirection.Down });
          }}
        />
      )}
    </div>
  );
};

export default ApplicationMenu;
