import React, { FC } from 'react';
import './close-button.less';

interface CloseButtonProps {
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClick, style }) => {
  return <i style={{ ...style }} className="icon-x" onClick={onClick} />;
};
