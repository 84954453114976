import { FC } from 'react';
import DefaultLoader from './default';
import OptumLoader from './optum';
import OptumDataCoreLoader from './optumDataCore';

const loadersMap: Record<string, FC> = {
  optum: OptumLoader,
  optumDataCore: OptumDataCoreLoader,
};

export const getWriteBackLoader = (id?: string): FC =>
  ((id && loadersMap[id]) as FC) ?? DefaultLoader;
