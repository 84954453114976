export type ClickAction = 'open' | 'close';

export enum Feature {
  CARE_GAPS = 'CARE_GAPS',
  CODING_GAPS = 'CODING_GAPS',
  HEALTH_HISTORY = 'HEALTH_HISTORY',
  PRIOR_AUTH = 'PRIOR_AUTH',
  Eligibility = 'Eligibility',
  ORDER_ASSIST = 'ORDER_ASSIST',
}

export enum InformationType {
  Eligibility = 'Eligibility',
  QualityGaps = 'Care Gaps',
  DiagnosisGaps = 'Diagnosis Gaps',
  PriorAuthCheck = 'Prior Auth',
  PatientHealthHistory = 'Health History',
  OrderAssist = 'Order Assist',
}

export type AnalyticsMemberProperties = {
  information_types: InformationType[];
  care_gaps_count: number;
  diagnosis_gaps_count: number;
  health_history_count: number;
};

export enum Product {
  Eligibility = 'eligibility',
  QualityGaps = 'quality-gaps',
  DiagnosisGaps = 'diagnosis-gaps',
  PriorAuthCheck = 'prior-auth',
  PatientHealthHistory = 'health-history',
  OrderAssist = 'order-assist',
}

export enum ElementInteraction {
  Hover = 'Hover',
  Expand = 'Expand',
  Collapse = 'Collapse',
  Click = 'Click',
  Drag = 'Drag',
}

export enum WidgetOpenAction {
  AutoLaunch = 'AutoLaunch',
  VimHubAppButton = 'VimHubAppButton',
  InlineButton = 'InlineButton',
}

export enum WidgetCloseReason {
  AppSwitch = 'AppSwitch',
  PatientOutOfContext = 'PatientOutOfContext',
  VimHubCloseClicked = 'VimHubCloseClicked',
}

export interface Sessions {
  preAuthSession: string | null;
  userSession: string | null;
  patientSession: string | null;
  vimHubSession: string | null;
  vimAppSession: string | null;
}

export enum BlockReason {
  ByOrgId = 'by_org_id',
  LimitUserPatient = 'limit_user_patient',
}
