import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ThemeContext,
  themes,
  ThemeVariablesWrapper,
  VimThemesKeyValue,
} from '@getvim/components-hooks-use-theme';
import { Common, Entities, Infra, RuntimeContract, Standard } from '@getvim/vim-connect';
import { v4 as uuid } from 'uuid';
import {
  ActionButton,
  EventType,
  registerVimConnectUI,
  sendEventToApp,
  sendSyncEventToApp,
  VimConnectHubPayload,
} from '@getvim/utils-vim-connect-communication';
import classNames from 'classnames';
import { useAnalytics, useVimCommunication } from '@getvim/vim-app-infra';
import {
  Notifications,
  VimConnectSubApp,
  WidgetVimCommunication,
} from '@getvim/vim-connect-communication';
import { WidgetIncomingEvent, WidgetOutgoingEvent } from '@getvim/vim-connect-app';
import { isEmpty, uniq } from 'lodash-es';
import { featureFlagsClient } from '../../clients/feature-flags/feature-flags.client';
import AppsContainer from './app-container/AppsContainer';
import ApplicationMenu from './app-menu/ApplicationMenu';
import { getApplicationIcon, modifyApplicationIcon } from './icons/ApplicationIconsMapping';
import {
  HEIGHT_MARGIN_PIXELS,
  horizontalMovementToPosition,
  MENU_WRAPPER_TOP_MARGIN,
  shouldAddExtraPaddingToToast,
  verticalMovementToPosition,
} from './utils/positioningUtils';
import {
  Application,
  ApplicationInput,
  ModifyApplicationPayload,
  ModifyReason,
} from './types/application';
import { Notification } from './types/notification';
import { HandleEventsPayload } from './types/events';
import { productToFeature } from './analytics/mapping';
import {
  ElementInteraction,
  Product,
  Sessions,
  WidgetCloseReason,
  WidgetOpenAction,
} from './types/analytics';
import { InitialData } from './types/initial-data';
import { InitialData as NotificationsInitialData } from '../notifications/types/initial-data';
import {
  analyticsClient as analyticsClientOld,
  VimConnectUiAnalyticsEventTypes,
} from './analytics/analytics';
import { memberProperties } from './analytics/member-properties';
import { MOCK_DATA } from './mocks/vimConnectUI';
import { getAppOpenedTime, getAppSources, getIsHubActive } from '../../common';
import { isOnSelectAppEnable } from './is-on-select-app-enable';
import {
  getPatientsDetails,
  setPatientsDetailsState,
} from '../../patients-details/patients-details';
import './index.less';
import '@getvim/atomic-ui/assets/styles/main.less';
import { extractSenderWidgetId } from './utils/extractSenderWidgetId';
import { useSubApplication } from '../../hooks';
import { notificationsWidgetConfig } from '../notifications/config';
import {
  VimHubNotificationBlockedReason,
  VimHubNotificationHidedReason,
  VimHubNotificationsEventTypes,
} from '../notifications/analytics/analytics';

interface CurrentPaitentSession {
  patientSession: string;
  vimPatientId: string;
}

const VIM_CONNECT_HUB_APP_OPENED = 'vim-connect-hub-app-opened';

const VimConnectUI = (): JSX.Element => {
  const { vimCommunication } = useVimCommunication<WidgetVimCommunication>();
  const { analyticsClient } = useAnalytics();
  const [notificationsWidget] = useSubApplication(notificationsWidgetConfig, {
    injectOnMount: true,
  });

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [applications, setApplications] = useState<Record<Application['id'], Application>>({});
  const appTransitionCount = useRef(0);
  const [isHubDisplayed, setIsHubDisplayed] = useState(false);
  const [isHubActive, setIsHubActive] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [isAppMouseEnter, setIsAppMouseEnter] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [appOpenedTime, setAppOpenedTime] = useState(Date.now());
  const [hubValid, setHubValid] = useState(true);
  const [selectedApp, setSelectedApp] = useState<Application>();
  const [patient, setPatient] = useState<Standard.Events.TransformedPatientInContextPayload>();
  const [organization, setOrganization] = useState<Infra.Common.Types.MeOrganization>();
  const [hubPosition, setHubPosition] = useState<Infra.Common.Types.HubPosition>(
    Infra.Common.Consts.DEFAULT_HUB_POSITION,
  );
  const [products, setProducts] = useState<Infra.Common.Types.MeProducts[]>();
  const [shouldDisplayErrorPopup, setShouldDisplayErrorPopup] = useState(false);
  const [shouldBeAtBottom, setShouldBeAtBottom] = useState(false);
  const [
    shouldCloseHubWhenAppIsOpenAndBecomeDisable,
    setShouldCloseHubWhenAppIsOpenAndBecomeDisable,
  ] = useState(false);
  const [disableAutoPopupFF, setDisableAutoPopupFF] = useState(false);
  const [isAppDataReady, setIsAppDataReady] = useState(false);
  const [patientsSessions, setPatientsSessions] = useState<CurrentPaitentSession>({
    patientSession: '',
    vimPatientId: '',
  });

  useEffect(() => {
    const loadFFs = async () => {
      if (isAppDataReady) {
        const [
          shouldDisplayErrorPopup,
          shouldBeAtBottomFF,
          shouldCloseHubWhenAppIsOpenAndBecomeDisableFF,
        ] = await Promise.all([
          featureFlagsClient.getFlag({
            flagName: 'vim-connect-ui.shouldDisplayErrorAsTooltip',
            defaultValue: false,
          }),
          featureFlagsClient.getFlag({
            flagName: 'hub.shouldBeAtBottom',
            defaultValue: false,
          }),
          featureFlagsClient.getFlag({
            flagName: 'vim-connect-ui.shouldCloseHubWhenAppIsOpenAndBecomeDisable',
            defaultValue: false,
          }),
        ]);

        setDisableAutoPopupFF(!organization!.isPopupsEnabled);
        setShouldDisplayErrorPopup(shouldDisplayErrorPopup);
        setShouldBeAtBottom(shouldBeAtBottomFF);
        setShouldCloseHubWhenAppIsOpenAndBecomeDisable(
          shouldCloseHubWhenAppIsOpenAndBecomeDisableFF,
        );
      }
    };

    loadFFs();
  }, [isAppDataReady]);

  // For development only
  useEffect(() => {
    if (import.meta.env.VITE_MOCKED === 'true') {
      setExpanded(MOCK_DATA.MOCK_EXPANDED);
      setApplications(MOCK_DATA.MOCK_APPLICATIONS);
      setSelectedApp(MOCK_DATA.MOCK_SELECTED_APP);
      setPatient(MOCK_DATA.MOCK_PATIENT);
      setOrganization(MOCK_DATA.MOCK_ORGANIZATION);
    }
  }, []);

  useEffect(() => {
    memberProperties.updateMemberProperties(applications);
  }, [applications]);

  useEffect(() => {
    /**
     * workaround:
     * we use cleanup method to skip first render
     */
    return () => {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction,
        properties: {
          interaction: expanded ? ElementInteraction.Collapse : ElementInteraction.Expand,
        },
      });
    };
  }, [expanded, analyticsClient]);

  useEffect(() => {
    setIsHubActive(getIsHubActive(applications));
  }, [applications]);

  useEffect(() => {
    setShrink(!isHubActive && !expanded);
  }, [isHubActive, expanded, setShrink]);

  const addApplication = useCallback((applicationData: ApplicationInput) => {
    setApplications((prevApplications) => {
      const newApplications = { ...prevApplications };
      if (newApplications[applicationData.id]) {
        console.error('Application already exist!', applicationData.id);
        return newApplications;
      }

      const application: Application = {
        ...applicationData,
        iconSource: getApplicationIcon(applicationData.id, applicationData),
      };
      newApplications[applicationData.id] = application;
      return newApplications;
    });
  }, []);

  const modifyApplication = (id: Application['id'], applicationData: Partial<ApplicationInput>) => {
    setApplications((prevApplications) => {
      const newApplications = { ...prevApplications };
      if (!newApplications[id]) {
        console.error('Application not found!', id);
        return prevApplications;
      }

      if (applicationData.disabled === true) {
        if (notifications.some(({ widgetId }) => widgetId === id)) hideNotification();

        if (shouldCloseHubWhenAppIsOpenAndBecomeDisable) {
          if (selectedApp?.id === id) handleClose();
        }
      }

      if (applicationData.disabled === false && newApplications[id].disabled) {
        analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubBecameActiveWithApp, {
          widgetId: id,
          items_loaded: applicationData.notifications || 0,
          patient_insurer: patient?.insurance?.insurer,
        });
      }

      if (
        applicationData.reason === ModifyReason.Response ||
        applicationData.reason === ModifyReason.PatientEligible
      ) {
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimAppLoaded,
          properties: {
            app_name: id,
            app_sources: getAppSources(id, organization, products),
            app_activation_status: !applicationData.disabled,
            app_notifications_count: applicationData.notifications || 0,
            response_time: applicationData.responseTime || 0,
          },
        });
      }

      newApplications[id] = {
        ...newApplications[id],
        ...applicationData,
        ...modifyApplicationIcon(id, applicationData),
      };

      return newApplications;
    });
  };

  const removeApplication = (id: ApplicationInput['id']) => {
    setApplications((prevApplications) => {
      const newApplications = { ...prevApplications };
      delete newApplications[id];
      return newApplications;
    });
  };

  const handleSendDataToWidget = useCallback(
    ({ widgetId, payload }: { widgetId: string; payload: any }) => {
      const widgetIframe = document.querySelector(
        `iframe[vim-connect-ui-widget-id="${widgetId}"]`,
      ) as HTMLIFrameElement;
      if (!widgetIframe?.contentWindow) {
        return console.error(`iframe widget of ${widgetId} was not found!`);
      }
      return widgetIframe?.contentWindow?.postMessage(
        { type: 'vim-connect-send-data-to-widget', payload },
        '*',
      );
    },
    [],
  );

  /**
   * @returns array of insurers where we have member token
   * @example ['UHC', 'FLORIDA_BLUE']
   */
  const getPatientFoundInSource = (memberTokens?: Standard.Events.MemberTokens) => {
    if (!memberTokens) return;

    return Object.entries<any>(memberTokens)
      .filter(
        ([, memberToken]) =>
          memberToken?.newMemberTokenVersion || memberToken?.oldMemberTokenVersion,
      )
      .map(([insurer]) => insurer);
  };

  const handlePatientInContext = async (
    data: Standard.Events.TransformedPatientInContextPayload,
  ) => {
    const incomingPatient: Standard.Events.TransformedPatientInContextPayload = data;
    setPatient(incomingPatient);

    const { vimPatientId, memberTokens, potentialInsurers, contentSupplierInternalPersonIds } =
      incomingPatient || {};

    analyticsClientOld.setVimPatientId(vimPatientId);
    const patientSession =
      vimPatientId && patientsSessions.vimPatientId === vimPatientId
        ? patientsSessions.patientSession
        : uuid();
    setPatientsSessions({ vimPatientId: vimPatientId ?? '', patientSession });
    analyticsClient.setSession({ patientSession });

    const contentSources = uniq(products?.flatMap((product) => product.contentSources));
    const providerSources = uniq(products?.flatMap((product) => product.providerSources ?? []));
    const properties = potentialInsurers
      ? {
          enabled_data_sources_for_user: contentSources,
          potential_data_sources_for_patient: uniq(providerSources.concat(potentialInsurers as [])),
          patient_found_in_source: Object.keys(contentSupplierInternalPersonIds ?? {}),
        }
      : {
          enabled_data_sources_for_user: getAllOrganizationSources(),
          potential_data_sources_for_patient: getSourcesToBeQueried(incomingPatient),
          patient_found_in_source: getPatientFoundInSource(memberTokens),
        };

    analyticsClient.track({
      event: VimConnectUiAnalyticsEventTypes.VimHubPatientDetected,
      properties,
    });
  };

  const handlePatientOutOfContext = () => {
    if (selectedApp) {
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubClosed, {
        reason: WidgetCloseReason.PatientOutOfContext,
        widgetId: selectedApp.id,
      });
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubAppClosed, {
        reason: WidgetCloseReason.PatientOutOfContext,
        widgetId: selectedApp.id,
      });
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimChartClosed, {
        type: Entities.UIElements.ExpandingType.AUTOMATIC,
      });
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimAppClosed,
        properties: {
          app_name: selectedApp.id,
          app_close_reason: WidgetCloseReason.PatientOutOfContext,
          app_opened_for_time: getAppOpenedTime(appOpenedTime),
          app_hovered: isAppMouseEnter,
        },
      });
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubClosed,
        properties: {
          hub_last_focused_app: selectedApp.id,
          hub_close_method: WidgetCloseReason.PatientOutOfContext,
          app_opened_for_time: getAppOpenedTime(appOpenedTime),
          app_hovered: isAppMouseEnter,
        },
      });
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubAppTransition,
        properties: {
          origin_app: selectedApp.id,
          active_app: null,
          app_transition_count: appTransitionCount.current,
          app_notifications_count: 0,
        },
      });

      sendMessageToWidget(Object.keys(applications), {
        type: VIM_CONNECT_HUB_APP_OPENED,
        payload: { selectedApplicationId: null },
      });
    }

    const sessions: Partial<Sessions> = {
      vimAppSession: null,
      vimHubSession: null,
      patientSession: null,
    };
    analyticsClient.setSession(sessions);

    appTransitionCount.current = 0;
    setIsAppMouseEnter(false);
    setPatient(undefined);
    setExpanded(false);
    setSelectedApp(undefined);
    resetAnalytics();
    hideSubApplications();
  };

  const handleInjectApplication = (data: ApplicationInput) => {
    const applicationData: ApplicationInput = data;
    addApplication(applicationData);
  };

  const handleModifyApplication = (data: ModifyApplicationPayload) => {
    const { id, modifications } = data;
    modifyApplication(id, modifications);
  };

  const handleSelectApplication = (selectAppPayload: RuntimeContract.Incoming.SelectAppPayload) => {
    const { expandingType, id } = selectAppPayload;
    const { disabled } = applications[id];

    if (id === selectedApp?.id || disabled) return;

    onSelectApp(id, expandingType);
  };

  const handleRemoveApplication = (data: Application['id']) => {
    const id: Application['id'] = data;
    removeApplication(id);
  };

  const handleVCHubSendToRuntime = (data: any) => {
    sendEventToApp(EventType.VCHubWidgetSendToApp, data);
  };

  const hideNotification = () => {
    setNotifications([]);
    notificationsWidget.modifyWidget({ display: false });
  };

  const handleVCHubSendToNotifications = useCallback(
    (vimConnectHubPayload: VimConnectHubPayload) => {
      const {
        event,
        data: { widgetId: senderWidgetId, notificationId, actionButtons, modifyWidgetParams },
      } = vimConnectHubPayload;

      switch (event) {
        case Notifications.Types.NotificationEvent.Show:
          const { disabled } = applications[senderWidgetId!];

          if (expanded || disabled) {
            const reason = expanded
              ? VimHubNotificationBlockedReason.HubWasOpened
              : VimHubNotificationBlockedReason.AppWasDisabled;

            analyticsClient.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationBlocked,
              properties: { appName: senderWidgetId, notificationId, reason },
            });

            return;
          }

          break;
        case Notifications.Types.NotificationEvent.Hide:
          if (notifications.some(({ widgetId }) => widgetId === senderWidgetId)) {
            hideNotification();
            analyticsClient.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationHide,
              properties: {
                appName: senderWidgetId,
                reason: VimHubNotificationHidedReason.HideByApp,
                notificationId,
              },
            });
          }
          return;
      }

      const { tooltip: widgetName } = applications[senderWidgetId!];
      vimConnectHubPayload.data.widgetName = widgetName;
      vimConnectHubPayload.data.modifyWidgetParams = calculateNotificationsModifyWidget(
        modifyWidgetParams,
        actionButtons,
      );

      sendDataToSubApp(notificationsWidget, vimConnectHubPayload);
    },
    [analyticsClient, applications, expanded, hideNotification, notifications, notificationsWidget],
  );

  const calculateNotificationsModifyWidget = (
    modifyWidgetParams?: Partial<RuntimeContract.Incoming.ModifyWidgetParams>,
    actionButtons?: ActionButton[],
  ) => {
    const calculateActionButtons: Partial<RuntimeContract.Incoming.ModifyWidgetParams> = {
      size: { width: '345', height: 'auto' },
    };

    // modifyWidgetParams had better priority
    return { ...calculateActionButtons, ...modifyWidgetParams };
  };

  const handleRecoverError = () => {
    setHubValid(true);
    sendEventToApp(WidgetOutgoingEvent.HideToast, {});
  };

  const handleDisplayError = (data: any) => {
    displayErrorToast(data);
  };

  const handleInitData = (data: InitialData) => {
    const { hubPosition, organization, patientsDetails, products } = data;

    if (hubPosition) setHubPosition(hubPosition);
    if (products) setProducts(products);
    setOrganization(organization);
    setPatientsDetailsState(patientsDetails);
    notificationInitData({ organization });
    setIsAppDataReady(true);
  };

  const notificationInitData = async (notificationsInitialData: NotificationsInitialData) => {
    const shouldUseThemeInNotification = await featureFlagsClient.getFlag({
      flagName: 'vim-connect-ui.shouldUseThemeInNotification',
      defaultValue: false,
    });

    if (shouldUseThemeInNotification) {
      const data = {
        data: {
          event: Notifications.Types.VimConnectUIToNotificationEvent.InitData,
          ...notificationsInitialData,
        },
        event: Notifications.Types.VimConnectUIToNotificationEvent.InitData,
      };

      sendDataToSubApp(notificationsWidget, data);
    }
  };

  const handleLogout = () => {
    setIsAppDataReady(false);
    setPatient(undefined);
    setApplications({});
    setIsAppMouseEnter(false);
    setExpanded(false);
    setIsHubActive(false);
    setHubValid(true);
    setOrganization(undefined);
    setHubPosition(Infra.Common.Consts.DEFAULT_HUB_POSITION);
    setSelectedApp(undefined);
    setIsHubDisplayed(false);
    hideSubApplications();
  };

  const displayErrorToast = useCallback(
    (data?: any) => {
      const errorMessage = data;
      const isShouldAddExtraPaddingToToast = shouldAddExtraPaddingToToast();

      if (shouldDisplayErrorPopup) {
        sendEventToApp(WidgetOutgoingEvent.DisplayToast, {
          vimConnectUiInfo: {
            ...(errorMessage ? { text: errorMessage } : {}),
            position: hubPosition!,
            customSize: selectedApp?.id ? applications?.[selectedApp?.id]?.size : undefined,
            shouldAddExtraPaddingToToast: isShouldAddExtraPaddingToToast,
          },
        });

        setHubValid(false);
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.PopupErrorDisplayed,
          properties: {
            errorMessage,
          },
        });
      }
    },
    [analyticsClient, applications, selectedApp?.id, shouldDisplayErrorPopup, hubPosition],
  );

  const onSelectApp = useCallback(
    (
      appId: Application['id'],
      expandingType: Entities.UIElements.ExpandingType = Entities.UIElements.ExpandingType
        .VIM_APP_MANUAL,
    ) => {
      const newSelectedApp = applications[appId];
      if (
        !isOnSelectAppEnable(
          selectedApp?.id,
          newSelectedApp,
          expandingType,
          analyticsClient,
          patient?.vimPatientId,
          organization,
          disableAutoPopupFF,
          products,
        )
      ) {
        return;
      }

      sendEventToApp(WidgetOutgoingEvent.StoreState, getPatientsDetails());

      setSelectedApp(newSelectedApp);
      setAppOpenedTime(Date.now());
      setIsAppMouseEnter(false);
      setExpanded(true);

      if (selectedApp) {
        analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubAppClosed, {
          reason: WidgetCloseReason.AppSwitch,
          widgetId: selectedApp.id,
        });

        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimAppClosed,
          properties: {
            app_name: selectedApp.id,
            app_close_reason: WidgetCloseReason.AppSwitch,
            app_opened_for_time: getAppOpenedTime(appOpenedTime),
            app_hovered: isAppMouseEnter,
          },
        });

        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimHubAppTransition,
          properties: {
            origin_app: selectedApp?.id || null /* we must pass null */,
            active_app: newSelectedApp.id,
            app_transition_count: appTransitionCount.current,
            app_notifications_count: newSelectedApp.notifications || 0,
          },
        });

        const sessions: Partial<Sessions> = { vimAppSession: newSelectedApp ? uuid() : null };
        if (!newSelectedApp) sessions.vimHubSession = null;
        else if (newSelectedApp && !selectedApp) sessions.vimHubSession = uuid();
        analyticsClient.setSession(sessions);

        appTransitionCount.current++;
      } else {
        const sessions: Partial<Sessions> = { vimAppSession: newSelectedApp ? uuid() : null };
        if (!newSelectedApp) sessions.vimHubSession = null;
        else if (newSelectedApp && !selectedApp) sessions.vimHubSession = uuid();
        analyticsClient.setSession(sessions);

        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimHubOpened,
          properties: { app_name: newSelectedApp.id, app_open_method: expandingType },
        });

        hideSubApplications();
      }

      analyticsClientOld.setNewOpenedSessionId();
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimChartCategoryClick, {
        feature: productToFeature[newSelectedApp.id as Product],
        action: 'open',
        type: expandingType,
      });
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubAppOpened, {
        widgetId: newSelectedApp.id,
        action: WidgetOpenAction.VimHubAppButton,
      });
      if (expandingType === Entities.UIElements.ExpandingType.AUTOMATIC) {
        analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimChartAutomaticOpenClick, {
          widgetId: newSelectedApp.id,
        });
      }
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimAppOpened,
        properties: {
          app_name: newSelectedApp.id,
          app_sources: getAppSources(newSelectedApp.id, organization, products),
          app_notifications_count: newSelectedApp.notifications || 0,
          app_open_method: expandingType,
        },
      });

      const { id: newSelectedApplicationId } = newSelectedApp;

      sendMessageToWidget(Object.keys(applications), {
        type: VIM_CONNECT_HUB_APP_OPENED,
        payload: { selectedApplicationId: newSelectedApplicationId },
      });
    },
    [
      selectedApp,
      analyticsClient,
      isAppMouseEnter,
      appOpenedTime,
      organization,
      disableAutoPopupFF,
      applications,
      patient,
    ],
  );

  // ***************************
  // * start new communication *
  // ***************************
  useEffect(
    () =>
      // [communication] notification -> vim connect ui
      vimCommunication?.listenToEvent(EventType.NotificationsSendToVCHub, ({ data }) => {
        const { data: payload, event } = data;
        const { widgetId, notificationId, method } = payload;
        const analyticsProperties = { widgetId, notificationId };

        const { NotificationToVimConnectUIEvent } = Notifications.Types;

        switch (event) {
          case NotificationToVimConnectUIEvent.OnOpen:
            analyticsClient?.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationDisplay,
              properties: analyticsProperties,
            });

            setNotifications([...notifications, { widgetId, notificationId }]);

            break;

          case NotificationToVimConnectUIEvent.OnClose:
            if (!isEmpty(notifications)) {
              analyticsClient?.track({
                event: VimHubNotificationsEventTypes.VimHubNotificationClosed,
                properties: { ...analyticsProperties, method },
              });
            }

            setNotifications([]);

            break;

          case NotificationToVimConnectUIEvent.OnClick:
            analyticsClient?.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationClicked,
              properties: analyticsProperties,
            });

            handleSelectApplication({
              id: widgetId,
              expandingType: Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL,
            });

            setNotifications([]);

            break;

          case NotificationToVimConnectUIEvent.OnMouseEnter:
            analyticsClient?.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationHovered,
              properties: analyticsProperties,
            });

            break;

          case NotificationToVimConnectUIEvent.OnBlocked:
            analyticsClient?.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationBlocked,
              properties: {
                ...analyticsProperties,
                reason: VimHubNotificationBlockedReason.ReachedLimitation,
              },
            });

            break;

          case NotificationToVimConnectUIEvent.OnActionButtonClick:
            analyticsClient?.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationCtaClicked,
              properties: payload,
            });

            vimCommunication?.sendEvent(
              widgetId,
              NotificationToVimConnectUIEvent.OnActionButtonClick,
              payload,
            );

            break;
        }
      }),
    [vimCommunication, analyticsClient, notifications],
  );

  // *************************
  // * end new communication *
  // *************************

  // *******************
  // old communication *
  // *******************
  useEffect(() => {
    const handleEvents = (payload: HandleEventsPayload, browserEvent: MessageEvent) => {
      const { event, data } = payload;

      switch (event) {
        // ***************************************
        // events from runtime to vim connect ui *
        // ***************************************
        case WidgetIncomingEvent.InjectApplication: {
          handleInjectApplication(data);
          break;
        }

        case WidgetIncomingEvent.ModifyApplication: {
          handleModifyApplication(data);
          break;
        }

        case WidgetIncomingEvent.SelectApplication: {
          handleSelectApplication(data);
          break;
        }

        case WidgetIncomingEvent.RemoveApplication: {
          handleRemoveApplication(data);
          break;
        }

        case WidgetIncomingEvent.PatientInContext: {
          handlePatientInContext(data);
          break;
        }

        case WidgetIncomingEvent.DisplayError: {
          handleDisplayError(data);
          break;
        }

        case WidgetIncomingEvent.RecoverError: {
          handleRecoverError();
          break;
        }

        case WidgetIncomingEvent.PatientOutContext: {
          handlePatientOutOfContext();
          break;
        }

        case WidgetIncomingEvent.InitData: {
          handleInitData(data);
          break;
        }

        case WidgetIncomingEvent.Logout: {
          handleLogout();
          break;
        }

        case WidgetIncomingEvent.SendDataToWidget: {
          handleSendDataToWidget(data);
          break;
        }

        // *********************************
        // * events from widget to runtime *
        // *********************************
        case EventType.VCHubSendToRuntime: {
          handleVCHubSendToRuntime(data);
          break;
        }

        case EventType.VCHubSendToNotifications: {
          handleVCHubSendToNotifications(data);
          break;
        }

        case EventType.VCNewModifyApplication: {
          const senderWidgetId = extractSenderWidgetId(browserEvent);
          if (senderWidgetId) {
            handleModifyApplication({ id: senderWidgetId, modifications: data.data });
          }
          break;
        }

        default: {
          break;
        }
      }
    };

    registerVimConnectUI(handleEvents);
  }, [
    addApplication,
    displayErrorToast,
    handleSendDataToWidget,
    onSelectApp,
    selectedApp,
    applications,
    notifications,
    handleVCHubSendToNotifications,
  ]);

  useEffect(() => {
    if (isHubDisplayed) {
      analyticsClient.track({ event: VimConnectUiAnalyticsEventTypes.VimHubDisplayed });
    }
  }, [isHubDisplayed, analyticsClient]);

  useEffect(() => {
    sendModifyEvent();
  }, [
    shrink,
    isHubActive,
    addApplication,
    applications,
    displayErrorToast,
    expanded,
    isAppDataReady,
    hubPosition,
    onSelectApp,
    selectedApp,
  ]);

  const sendModifyEvent = async () => {
    if (isAppDataReady) {
      try {
        setIsHubDisplayed(true);
        const modifyVimConnectUIPayload = {
          display: true,
          vimConnectUiInfo: {
            isShrink: shrink,
            isOpen: !!selectedApp,
            isExpanded: expanded,
            numOfApplications: Object.keys(applications).length,
            position: hubPosition,
            customSize: selectedApp?.id ? applications?.[selectedApp?.id]?.size : undefined,
          },
        };

        const hubPositionAttributes: RuntimeContract.Incoming.WidgetPositionAttributes =
          await sendSyncEventToApp(
            WidgetOutgoingEvent.ModifyVimConnectUI,
            modifyVimConnectUIPayload,
          );

        const { alignment } = hubPositionAttributes;
        const newAlignment = horizontalMovementToPosition(hubPosition!.horizontal!, alignment!);
        await notificationsWidget.modifyWidget({ alignment: { ...newAlignment } });
      } catch (error) {
        console.warn('error occurred when modyifing widget', { error });
      }
    }
  };

  const sendMessageToWidget = (
    targetWidgetId: string | string[],
    message: { type: string; payload: any },
  ) => {
    for (const currWidgetId of [targetWidgetId].flat()) {
      const widgetIframe = document.querySelector(
        `iframe[vim-connect-ui-widget-id="${currWidgetId}"]`,
      ) as HTMLIFrameElement;
      if (!widgetIframe?.contentWindow) {
        console.error(`iframe widget of ${currWidgetId} was not found!`);
        return;
      }

      widgetIframe?.contentWindow?.postMessage(message, '*');
    }
  };

  const getSourcesToBeQueried = (patient: Standard.Events.TransformedPatientInContextPayload) => {
    const { config } = organization || {};
    const { applications } = config || {};

    const result = Object.entries<any>(applications || {}).reduce(
      (prev, [, { sources, source }]) => {
        const activeSource = Common.getActiveSource(patient, (sources || []).concat([source]));

        if (activeSource) prev.add(activeSource);
        return prev;
      },
      new Set(),
    );

    return Array.from(result);
  };

  const getAllOrganizationSources = () => {
    const { config } = organization || {};
    const { applications } = config || {};

    const result = Object.entries<any>(applications || {}).reduce(
      (prev, [, { sources, source }]) => {
        const safeSources = [source]
          .concat(
            Array.isArray(sources)
              ? sources
              : ((sources as string)?.split(',') as Infra.Apps.ProductSource[]),
          )
          .filter((source) => source);
        safeSources?.forEach((source) => prev.add(source));
        return prev;
      },
      new Set(),
    );

    return Array.from(result);
  };

  const sendDataToSubApp = (subApp: VimConnectSubApp, data: any) => {
    const {
      data: { modifyWidgetParams },
    } = data;

    // overwrite sub app widget config (for ex. size)
    if (modifyWidgetParams) subApp.modifyWidget(modifyWidgetParams);

    subApp.sendData(data);
  };

  const handleClose = useCallback(() => {
    if (selectedApp) {
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubClosed, {
        reason: WidgetCloseReason.VimHubCloseClicked,
        widgetId: selectedApp.id,
      });
      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubAppClosed, {
        reason: WidgetCloseReason.VimHubCloseClicked,
        widgetId: selectedApp.id,
      });

      sendMessageToWidget(Object.keys(applications), {
        type: VIM_CONNECT_HUB_APP_OPENED,
        payload: { selectedApplicationId: null },
      });

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimAppClosed,
        properties: {
          app_name: selectedApp.id,
          app_close_reason: WidgetCloseReason.VimHubCloseClicked,
          app_opened_for_time: getAppOpenedTime(appOpenedTime),
          app_hovered: isAppMouseEnter,
        },
      });

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubClosed,
        properties: {
          hub_last_focused_app: selectedApp.id,
          hub_close_method: WidgetCloseReason.VimHubCloseClicked,
          app_opened_for_time: getAppOpenedTime(appOpenedTime),
          app_hovered: isAppMouseEnter,
        },
      });

      setIsAppMouseEnter(false);

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubAppTransition,
        properties: {
          origin_app: selectedApp.id,
          active_app: null,
          app_transition_count: appTransitionCount.current,
          app_notifications_count: 0,
        },
      });
    }

    const sessions: Partial<Sessions> = { vimAppSession: null, vimHubSession: null };
    analyticsClient.setSession(sessions);

    appTransitionCount.current = 0;
    setSelectedApp(undefined);
    setExpanded(false);
    analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimChartClosed, {
      type: Entities.UIElements.ExpandingType.VIM_HUB_MANUAL,
    });
  }, [selectedApp, analyticsClient, appOpenedTime, isAppMouseEnter, applications]);

  const getVerticalPosition = (
    verticalDirection: Infra.Common.Types.VerticalDirection | undefined,
  ) => {
    return verticalDirection !== undefined
      ? verticalMovementToPosition(hubPosition!.vertical!, verticalDirection!)
      : hubPosition!.vertical;
  };

  const getHorizontalPosition = (
    horizontalDirection: Infra.Common.Types.HorizontalDirection | undefined,
  ) => {
    const horizontalPosition =
      horizontalDirection === Infra.Common.Types.HorizontalDirection.Left
        ? Infra.Common.Types.HorizontalHubPosition.Left
        : Infra.Common.Types.HorizontalHubPosition.Right;

    return horizontalDirection !== undefined ? horizontalPosition : hubPosition!.horizontal;
  };

  const moveWidget = (direction: Infra.Common.Types.HubDirection) => {
    const { vertical: verticalDirection, horizontal: horizontalDirection } = direction;

    const newPosition: Infra.Common.Types.HubPosition = {
      vertical: getVerticalPosition(verticalDirection),
      horizontal: getHorizontalPosition(horizontalDirection),
    };

    const { vertical: newVerticalPosition, horizontal: newHorizontalPosition } = newPosition;
    const { vertical: verticalPosition, horizontal: horizontalPosition } = hubPosition!;

    if (verticalPosition !== newVerticalPosition || horizontalPosition !== newHorizontalPosition) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubPositionChange,
        properties: {
          hub_vertical_direction: verticalDirection
            ? Infra.Common.Types.VerticalDirection[verticalDirection]
            : undefined,
          hub_horizontal_direction: horizontalDirection
            ? Infra.Common.Types.HorizontalDirection[horizontalDirection]
            : undefined,
          hub_new_vertical_position: Infra.Common.Types.VerticalHubPosition[newVerticalPosition!],
          hub_new_horizontal_position:
            Infra.Common.Types.HorizontalHubPosition[newHorizontalPosition!],
          hub_old_vertical_position: Infra.Common.Types.VerticalHubPosition[verticalPosition!],
          hub_old_horizontal_position:
            Infra.Common.Types.HorizontalHubPosition[horizontalPosition!],
          hub_activation_status: getIsHubActive(applications),
        },
      });

      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubPositionChange, {
        direction: verticalDirection ? Infra.Common.Types.VerticalDirection[verticalDirection] : '',
        newPosition: Infra.Common.Types.VerticalHubPosition[newVerticalPosition!],
        oldPosition: Infra.Common.Types.VerticalHubPosition[verticalPosition!],
      });

      setExpanded(false);
      setHubPosition({ vertical: newVerticalPosition, horizontal: newHorizontalPosition });
    }
  };

  const expandOnMouseEnter = useCallback(() => {
    if (hubValid) {
      setExpanded(true);

      analyticsClientOld.track(VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction, {
        interaction: ElementInteraction.Hover,
      });

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction,
        properties: { interaction: ElementInteraction.Hover },
      });
    } else {
      displayErrorToast();
    }
  }, [setExpanded, analyticsClient, hubValid, displayErrorToast]);

  const collapseOnMouseLeave = useCallback(() => {
    if (selectedApp) return;

    setExpanded(false);
  }, [selectedApp]);

  const resetAnalytics = () => {
    analyticsClientOld.resetVimPatientId();
    analyticsClientOld.resetActiveSessionId();
    analyticsClientOld.resetOpenedSessionId();
    memberProperties.resetMemberProperties();
  };

  const hideSubApplications = () => {
    hideToast();
    hideNotification();
  };

  const hideToast = () => {
    sendEventToApp(WidgetOutgoingEvent.HideToast, {});
  };

  const theme = themes[organization?.theme as keyof VimThemesKeyValue] || themes.vimConnect;
  ThemeContext(theme);

  const getHubPositionVertical = () => {
    if (hubPosition) {
      return hubPosition.horizontal === Infra.Common.Types.HorizontalHubPosition.Left
        ? 'left'
        : 'right';
    }
  };

  const getHubBottomClass = () => {
    if (hubPosition) {
      return (hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.Low ||
        hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.Low_Middle) &&
        shrink
        ? 'bottom'
        : null;
    }
  };

  const getHubLowerClass = () => {
    if (hubPosition) {
      return shouldBeAtBottom &&
        (hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.Low ||
          hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.Low_Middle) &&
        !selectedApp
        ? 'lower'
        : null;
    }
  };

  if (!isAppDataReady) return <div />;

  return (
    <ThemeVariablesWrapper
      theme={theme}
      className={classNames(
        `vim-connect-ui-app ${selectedApp ? 'open' : ''}`,
        getHubPositionVertical(),
      )}
    >
      <div
        className={classNames(
          `main-container`,
          getHubPositionVertical(),
          getHubBottomClass(),
          getHubLowerClass(),
          {
            open: selectedApp,
            shrink,
          },
        ).trim()}
      >
        <div
          className="menu-wrapper"
          style={{
            paddingTop: selectedApp ? MENU_WRAPPER_TOP_MARGIN : HEIGHT_MARGIN_PIXELS,
            ...(selectedApp ? {} : { marginBottom: HEIGHT_MARGIN_PIXELS }),
            alignSelf: expanded ? 'auto' : 'center',
          }}
          onMouseLeave={collapseOnMouseLeave}
        >
          <ApplicationMenu
            organization={organization}
            patient={patient}
            expanded={expanded}
            isHubValid={hubValid}
            shrink={shrink}
            applications={applications}
            selectedApp={selectedApp}
            hubPosition={hubPosition}
            onSelectApp={onSelectApp}
            onClose={handleClose}
            moveWidget={moveWidget}
            expandOnMouseEnter={expandOnMouseEnter}
          />
        </div>
        {applications && (
          <AppsContainer
            organizationId={organization?.id}
            onClose={handleClose}
            setIsAppMouseEnter={setIsAppMouseEnter}
            isAppMouseEnter={isAppMouseEnter}
            applications={applications}
            selectedApp={selectedApp}
            patient={patient}
          />
        )}
      </div>
    </ThemeVariablesWrapper>
  );
};

export default VimConnectUI;
