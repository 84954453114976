import { FC } from 'react';
import { ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import UpdateRoleFormSuccessIcon from './assets/img/update-role-form-success.svg';

import './index.less';

interface UpdateRoleFormSuccessProps {}

export const UpdateRoleFormSuccess: FC<UpdateRoleFormSuccessProps> = () => {
  return (
    <main className="update-role-form-success-container">
      <img
        src={UpdateRoleFormSuccessIcon}
        alt="success icon"
        className="update-role-form-success-img"
      />
      <Text
        text="Thank you"
        size={SizeEnum['15px']}
        colorName={ColorNameEnum.extraDark}
        weight={WeightEnum.semibold}
      />
      <Text
        text="information was registered"
        size={SizeEnum['15px']}
        colorName={ColorNameEnum.extraDark}
      />
    </main>
  );
};
