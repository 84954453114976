import React, { FC } from 'react';
import { Infra } from '@getvim/vim-connect';
import { phoneFormatter, DetailsCardItem } from '@getvim/atomic-ui';

import './index.less';

interface ManagementContentProps {
  user: Infra.Common.Types.MeEhrUser | undefined;
}

export const ManagementContent: FC<ManagementContentProps> = ({ user }) => {
  const phoneNumber = phoneFormatter.valuePhoneNumber(user?.phoneNumber ?? '');
  const na = 'N/A';

  return (
    <div className="management-content">
      <DetailsCardItem label="EHR username" value={user?.linkedEhrUser || na} />
      <DetailsCardItem label="Email" value={user?.email || na} />

      <div className="management-two-fields">
        <DetailsCardItem label="NPI" value={user?.npi || na} />
        <DetailsCardItem label="TIN" value={user?.tin || na} />
      </div>

      <DetailsCardItem label="Address" value={user?.address?.fullAddress || na} />
      <DetailsCardItem label="Phone number" value={phoneNumber || na} />
    </div>
  );
};
