import { Infra } from '@getvim/vim-connect';
import { Application } from '../components/app/types/application';

const APPS_TO_GO_LAST = new Set<string>([Infra.Apps.Product.PriviaInsights]);

export const sortApplications = (appA: Application, appB: Application) => {
  if (APPS_TO_GO_LAST.has(appA.id)) {
    return 1;
  }
  if (APPS_TO_GO_LAST.has(appB.id)) {
    return -1;
  }
  if (appA.id < appB.id) return -1;
  if (appA.id > appB.id) return 1;
  return 0;
};
