import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './index.less';
import { useTheme } from '@getvim/components-hooks-use-theme';

const ThemeLogo: FunctionComponent = () => {
  const theme = useTheme();

  return <div className={classNames('logo', theme?.className)} aria-label={theme?.fullName} />;
};

export default ThemeLogo;
