import { Tooltip } from '@getvim/atomic-ui';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { Standard } from '@getvim/vim-connect';
import React from 'react';
import { insurerToLogo } from '../../../components/app/common';
import mockInsurerLogo from '../../../assets/mockInsurer.svg';
import { getAgeInYearsMonthDays, getPatientAgeString, getPatientDateOfBirth } from './utils/dates';

import './index.less';

interface PatientHeaderProps {
  patient: Standard.Events.TransformedPatientInContextPayload;
  isShowLogo: boolean;
  organizationId?: number;
}

const PatientHeader: React.FC<PatientHeaderProps> = ({
  patient,
  isShowLogo,
  organizationId,
}: PatientHeaderProps) => {
  const [shouldShowMockInsurerLogo] = useFeatureFlag({
    flagName: 'should_show_mock_insurer_logo',
    defaultValue: false,
    team: Team.Onboarding,
    namespace: 'vim_connect',
    flagContext: { organizationId },
  });

  const {
    demographics: { dateOfBirth, firstName, lastName },
    insurance,
  } = patient;

  const insurerLogo = shouldShowMockInsurerLogo
    ? mockInsurerLogo
    : insurance?.insurer && insurerToLogo[insurance.insurer];
  const insurerName = insurance?.ehrInsurance;

  const [shouldDisplayEhrInsurerNameFF] = useFeatureFlag({
    flagName: 'shouldDisplayEhrInsurerName',
    defaultValue: true,
  });

  return !isShowLogo ? (
    <div className="patient-header-new">
      <div className="patient-header__main-details">
        <div className="patient-header__name">{`${firstName} ${lastName}`}</div>
        {dateOfBirth && (
          <div className="patient-header__main-details">
            <span style={{ margin: '0 10px', display: 'inline-block' }}>/</span>
            <div> {getPatientDateOfBirth(dateOfBirth).format('MMM DD, YYYY')} </div>
            <span style={{ margin: '0 10px', display: 'inline-block' }}>/</span>
            <div> {getPatientAgeString(getAgeInYearsMonthDays(dateOfBirth))} </div>
          </div>
        )}
      </div>
      <div className="patient-header__details margin-top-10">
        {insurerLogo && (
          <>
            <img src={insurerLogo} alt={insurance?.insurer} className="patient-header__logo" />
            <div className="patient-header__slash">&nbsp;/&nbsp;</div>
          </>
        )}
        {shouldDisplayEhrInsurerNameFF && !!insurerName && !shouldShowMockInsurerLogo && (
          <>
            <Tooltip
              tooltipContent={insurerName}
              referenceClassName="patient-header__insurer-name-tooltip"
              className="insurer-tooltip text-center"
              width="large"
            >
              <div className="patient-header__insurer-name">{insurerName}</div>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="patient-header">
      <div className="patient-header__name">{`${firstName} ${lastName}`}</div>
      <div className="patient-header__details">
        {[
          getPatientDateOfBirth(dateOfBirth).format('MMM DD, YYYY'),
          getPatientAgeString(getAgeInYearsMonthDays(dateOfBirth)),
        ].join('  /  ')}
        {insurerLogo && (
          <>
            <div>&nbsp;/&nbsp;</div>
            <img src={insurerLogo} alt={insurance?.insurer} className="patient-header__logo" />
          </>
        )}
        {shouldDisplayEhrInsurerNameFF && !!insurerName && (
          <>
            <div>&nbsp;/&nbsp;</div>
            <Tooltip
              tooltipContent={insurerName}
              referenceClassName="patient-header__insurer-name-tooltip"
              className="insurer-tooltip text-center"
              width="large"
            >
              <div className="patient-header__insurer-name">{insurerName}</div>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default PatientHeader;
