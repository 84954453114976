import { Entities, Infra } from '@getvim/vim-connect';
import { getAppSources } from '../../common/get-app-sources';
import {
  doesProviderReachedPatientLimit,
  getPatientDetails,
  MAX_POPUP_NUMBER,
  storePatientDetails,
} from '../../patients-details/patients-details';
import { VimConnectUiAnalyticsEventTypes } from './analytics/analytics';
import { BlockReason } from './types/analytics';
import { Application } from './types/application';
import { UserManagement } from './types/management-application';

const autoPopupApps = new Set(['order-assist', 'prior-auth', 'optum-referral-utility']);

export const isOnSelectAppEnable = (
  selectedAppId: Application['id'] | undefined,
  newSelectedApp: Application | UserManagement,
  expandingType: Entities.UIElements.ExpandingType,
  analyticsClient: any,
  vimPatientId: string | undefined,
  organization: Infra.Common.Types.MeOrganization | undefined,
  disableAutoPopupFF: boolean,
  products: Infra.Common.Types.MeProducts[] | undefined,
): boolean => {
  if (selectedAppId === newSelectedApp.id) return false;

  if (expandingType === Entities.UIElements.ExpandingType.AUTOMATIC) {
    storePatientDetails(vimPatientId);
    if (!autoPopupApps.has(newSelectedApp.id)) {
      if (disableAutoPopupFF) {
        const patientDetails = getPatientDetails(vimPatientId);
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimAppPopupBlocked,
          properties: {
            app_name: newSelectedApp.id,
            app_notifications_count: newSelectedApp.notifications,
            app_sources: getAppSources(newSelectedApp.id, organization, products),
            block_reason: BlockReason.ByOrgId,
            patient_block_count: patientDetails?.numberOfAutoPopups,
          },
        });
        return false;
      }
      if (doesProviderReachedPatientLimit(vimPatientId)) {
        const patientDetails = getPatientDetails(vimPatientId);
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimAppPopupBlocked,
          properties: {
            app_name: newSelectedApp.id,
            app_notifications_count: newSelectedApp.notifications,
            app_sources: getAppSources(newSelectedApp.id, organization, products),
            block_reason: BlockReason.LimitUserPatient,
            patient_block_count: (patientDetails?.numberOfAutoPopups ?? 0) - MAX_POPUP_NUMBER,
          },
        });
        return false;
      }
    }
  }

  return true;
};
