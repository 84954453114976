import React, {
  FC,
  useCallback,
  useState,
  ReactElement,
  FunctionComponent,
  useEffect,
  useRef,
} from 'react';
import classNames from 'classnames';
import {
  ColorNameEnum,
  AlignEnum,
  SizeEnum,
  WeightEnum,
  TextTransformEnum,
} from '../../types/enums';
import Text from '../../atoms/text';
import ClickableDiv from '../../atoms/clickableDiv';
import Tooltip from '../../atoms/tooltip';
import './index.less';
import { AnalyticsHover } from '../..';

export interface DetailsCardItemProps {
  id?: string;
  label?: string | JSX.Element;
  labelTextTransform?: TextTransformEnum;
  labelWeight?: WeightEnum;
  value?: string | React.ReactNode;
  valueTextTransform?: TextTransformEnum;
  horizontal?: boolean;
  shouldBreakLines?: boolean;
  description?: string;
  TooltipWrapper?: FunctionComponent<{ className: string }>;
  tooltipText?: string;
  onTooltipHover?: () => void;
}

export const DetailsCardItem: FC<DetailsCardItemProps> = ({
  id,
  label,
  labelTextTransform,
  labelWeight = WeightEnum.bold,
  value,
  valueTextTransform,
  horizontal,
  shouldBreakLines,
  description,
  TooltipWrapper,
  tooltipText,
  onTooltipHover,
}) => {
  const renderTooltip = () => (
    <AnalyticsHover onHoverAction={onTooltipHover}>
      <Tooltip
        referenceClassName="details-card-item-description-icon"
        placement="top"
        tooltipContent={description}
        bgColor="light"
      >
        <Text className="icon-help" size={SizeEnum['14px']} />
      </Tooltip>
    </AnalyticsHover>
  );

  return (
    <div {...(id && { id })} className={`details-card-item ${horizontal ? 'horizontal' : ''}`}>
      {tooltipText ? (
        <div className="details-card-item-label-container">
          <Text
            className="item-label"
            colorName={ColorNameEnum.ultraDark}
            weight={labelWeight}
            align={AlignEnum.left}
            text={label}
            inline={horizontal}
            textTransform={labelTextTransform}
          />
          <Tooltip
            referenceClassName="details-card-item-description-icon"
            placement="top"
            tooltipContent={tooltipText}
            bgColor="light"
          >
            <Text className="icon-help" size={SizeEnum['14px']} />
          </Tooltip>
        </div>
      ) : (
        <Text
          className="item-label"
          colorName={ColorNameEnum.ultraDark}
          weight={labelWeight}
          align={AlignEnum.left}
          text={label}
          inline={horizontal}
          textTransform={labelTextTransform}
        />
      )}

      {description &&
        (TooltipWrapper ? (
          <TooltipWrapper className="details-card-item-description-wrapper">
            {renderTooltip()}
          </TooltipWrapper>
        ) : (
          renderTooltip()
        ))}

      <Text
        className="item-value"
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.normal}
        align={AlignEnum.left}
        text={value}
        inline={horizontal}
        textTransform={valueTextTransform}
        shouldBreakLines={shouldBreakLines}
      />
    </div>
  );
};

const cardStatusOptions = {
  dismissed: 'dismissed',
  added: 'added',
  default: '',
};

const cardFontSizeOptions = {
  default: '14px',
  small: '12px',
};

type DetailsCardProps = {
  iconClass?: string;
  title?: string | JSX.Element;
  description?: string;
  TooltipWrapper?: FunctionComponent<{ className: string }>;
  className?: string;
  subHeader?: ReactElement<any, any>;
  /**
   * @param expanded value after toggle
   */
  onToggle?(expanded: boolean): void;
  collapsible?: boolean;
  fontSize?: string;
  cardStatus?: keyof typeof cardStatusOptions;
  permanentVisibleFooter?: any;
  smallContentPadding?: boolean;
  expandOnDemand?: boolean;
  stickyFooter?: boolean;
};

const DetailsCard: FC<DetailsCardProps> = ({
  children,
  title,
  description,
  TooltipWrapper,
  className,
  iconClass,
  subHeader,
  onToggle,
  expandOnDemand = false,
  collapsible = true,
  fontSize = 'default',
  cardStatus = 'default',
  permanentVisibleFooter,
  smallContentPadding,
  stickyFooter,
}) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setExpanded(expandOnDemand);
  }, [expandOnDemand]);

  useEffect(() => {
    if (expanded && expandOnDemand) {
      ref.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [expanded, expandOnDemand]);

  const isValidChildrenArray =
    Array.isArray(children) && children.some((node) => React.isValidElement(node));
  const validChildren = !!children && isValidChildrenArray;
  const collapseEnabled = collapsible && validChildren;

  const onClick = useCallback(
    (event) => {
      const newExpanded = !expanded;
      onToggle?.(newExpanded);
      setExpanded(newExpanded);
      event.stopPropagation();
    },
    [expanded, onToggle],
  );

  const renderTooltip = () => (
    <Tooltip
      referenceClassName="details-card-description-icon"
      className="details-card-description-tooltip"
      placement="top"
      tooltipContent={description}
      bgColor="light"
    >
      <Text className="icon-help" size={SizeEnum['14px']} />
    </Tooltip>
  );

  return (
    <ClickableDiv
      className={classNames(
        'details-card',
        className,
        { collapsible: collapseEnabled },
        cardStatusOptions[cardStatus!],
        { 'small-content-padding': smallContentPadding },
        { 'empty-card': !validChildren },
        { expanded },
        { 'sticky-footer': stickyFooter },
      )}
      ref={ref}
    >
      {title && (
        <ClickableDiv
          className="details-card-title"
          onClick={collapseEnabled ? onClick : undefined}
        >
          {iconClass && (
            <Text
              size={SizeEnum['18px']}
              className={classNames('details-card-title-main-icon', iconClass)}
            />
          )}
          <Text
            className="details-card-title-text"
            weight={WeightEnum.semibold}
            size={SizeEnum['14px']}
            align={AlignEnum.left}
            text={title}
          />
          {description &&
            (TooltipWrapper ? (
              <TooltipWrapper className="details-card-description-icon">
                {renderTooltip()}
              </TooltipWrapper>
            ) : (
              renderTooltip()
            ))}
        </ClickableDiv>
      )}
      {subHeader && <div className="subheader">{subHeader}</div>}
      <div>
        {validChildren && (
          <div
            className={
              !collapseEnabled || expanded ? 'card-content-visible' : 'card-content-hidden'
            }
            style={{ fontSize: cardFontSizeOptions[fontSize!] }}
          >
            <ClickableDiv
              className="icon-chevron-2 card-arrow"
              onClick={collapseEnabled ? onClick : undefined}
            />
            {children}
          </div>
        )}
        {permanentVisibleFooter && (
          <div className="permanent-visible-content">{permanentVisibleFooter}</div>
        )}
      </div>
    </ClickableDiv>
  );
};

export default DetailsCard;
