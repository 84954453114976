import React, { FC } from 'react';
import { Infra } from '@getvim/vim-connect';
import { AlignEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import { isEmpty } from 'lodash-es';

import './index.less';

interface ManagementHeaderProps {
  user: Infra.Common.Types.MeEhrUser | undefined;
}

export const ManagementHeader: FC<ManagementHeaderProps> = ({ user }) => {
  const name = `${user?.firstName} ${user?.lastName}`;
  const roles = user?.roles || user?.titles;
  const userRoles = isEmpty(roles) ? '-' : roles!.join(', ');

  return (
    <div className="management-header">
      <Text text={name} align={AlignEnum.left} weight={WeightEnum.bold} />
      <Text text={userRoles} align={AlignEnum.left} />
    </div>
  );
};
