import React from 'react';
import classNames from 'classnames';
import {
  Tooltip as TooltipContent,
  TooltipReference,
  useTooltipState,
  TooltipArrow,
} from 'reakit/Tooltip';
import './index.less';
import { v4 as uuid } from 'uuid';

type TooltipProps = {
  bgColor?: keyof typeof tooltipBgColor;
  width?: keyof typeof tooltipWidth;
  showShadow?: boolean;
  tooltipContent?: any;
  className?: string;
  referenceClassName?: string;
  placement?:
    | 'auto'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'auto-start'
    | 'auto-end'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end';
  conditionalTooltip?: boolean;
  contentMaxLength?: number;
  pillStyle?: boolean;
  visible?: boolean;
  hideTooltip?: boolean;
  parseNewLines?: boolean;
  hideArrow?: boolean;
  portal?: boolean;
};

const tooltipBgColor = {
  buttonColor: 'tooltip-bg-button-color',
  mainColor: 'tooltip-bg-main-color',
  secondaryColor: 'tooltip-bg-secondary-color',
  white: 'tooltip-bg-white-color',
  dark: 'tooltip-bg-dark-color',
  light: 'tooltip-bg-light-color',
};

const tooltipWidth = {
  auto: 'tooltip-width-auto',
  small: 'tooltip-width-small',
  large: 'tooltip-width-large',
};

const Tooltip: React.FC<TooltipProps> = ({
  bgColor = 'mainColor',
  width = 'auto',
  showShadow = false,
  tooltipContent,
  className,
  referenceClassName,
  placement,
  conditionalTooltip = false,
  contentMaxLength = 40,
  children,
  pillStyle = false,
  hideTooltip = false,
  parseNewLines = false,
  hideArrow = false,
  portal = true,
  ...other
}) => {
  const tooltip = useTooltipState({ placement, ...other });
  const tooltipContentRows = typeof tooltipContent === 'string' ? tooltipContent?.split('\n') : [];
  return (
    <>
      {(conditionalTooltip && tooltipContent?.length < contentMaxLength) || hideTooltip ? (
        <>{children}</>
      ) : (
        <>
          <TooltipReference
            className={referenceClassName}
            style={{ display: 'inline-block' }}
            {...tooltip}
          >
            {children}
          </TooltipReference>
          <TooltipContent
            className={classNames(
              'atomic-tooltip',
              className,
              placement,
              { 'pill-style': pillStyle },
              { 'show-shadow': showShadow },
              tooltipBgColor[bgColor!],
              tooltipWidth[width!],
            )}
            unstable_portal={portal}
            {...tooltip}
          >
            <TooltipArrow
              className={classNames('atomic-tooltip-arrow', { hidden: hideArrow })}
              {...tooltip}
            />
            <div className="atomic-tooltip-content">
              {parseNewLines
                ? tooltipContentRows.map((rowContent, i) => (
                    <React.Fragment key={`tooltip-row-${uuid()}`}>
                      {rowContent}
                      {i === tooltipContentRows.length - 1 ? undefined : <br />}
                    </React.Fragment>
                  ))
                : tooltipContent}
            </div>
          </TooltipContent>
        </>
      )}
    </>
  );
};

export default Tooltip;
