import { CollapsingVimFooter, ThemeLogo } from '@getvim/atomic-ui';
import type { Standard } from '@getvim/vim-connect';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import classNames from 'classnames';
import React from 'react';
import {
  WIDGET_CONTENT_WIDTH,
  WRAPPER_HEIGHT_VH,
} from '../../../components/app/utils/positioningUtils';
import { CloseButton } from '../../atoms/closeButton';
import PatientHeader from '../patientHeader';

import './index.less';
import { isShowPatientHeader } from '../../../common/is-show-patient-header';

interface AppContainerProps {
  className: string;
  onClose: () => void;
  onMouseEnter?: () => void;
  patient?: Standard.Events.TransformedPatientInContextPayload;
  customSize?: {
    height: string;
    width: string;
  };
  isShowLogo: boolean;
  selectedAppId: string | undefined;
  organizationId?: number;
}

export const AppContainer: React.FC<AppContainerProps> = ({
  onClose,
  onMouseEnter,
  children,
  patient,
  customSize,
  className,
  isShowLogo,
  selectedAppId,
  organizationId,
}) => {
  const shouldShowPatientHeader = isShowPatientHeader(selectedAppId ?? '');
  const [showNewFooterBottomPadding] = useFeatureFlag({
    flagName: 'shouldShowNewFooterBottomPadding',
    defaultValue: true,
  });

  return (
    <div
      className={`app-container ${className ?? ''}`}
      style={{
        height: customSize?.height ?? `${WRAPPER_HEIGHT_VH}vh`,
        width: customSize?.width ?? `${WIDGET_CONTENT_WIDTH}px`,
      }}
      onMouseEnter={onMouseEnter}
    >
      {isShowLogo ? (
        <div className="app-container-header-wrapper">
          <div className="app-container-header">
            <ThemeLogo />
            <CloseButton style={{ margin: '-20px 0 0 0' }} onClick={onClose} />
          </div>
          {patient && shouldShowPatientHeader && (
            <PatientHeader isShowLogo patient={patient} organizationId={organizationId} />
          )}
        </div>
      ) : patient && shouldShowPatientHeader ? (
        <div className="app-container-header-wrapper-new">
          <PatientHeader isShowLogo={false} patient={patient} organizationId={organizationId} />
          <CloseButton onClick={onClose} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '5px' }}>
          <CloseButton onClick={onClose} />
        </div>
      )}
      <div
        className={classNames('app-content-wrapper', {
          'show-footer-padding': showNewFooterBottomPadding,
        })}
      >
        {children}
      </div>
      <CollapsingVimFooter />
    </div>
  );
};
