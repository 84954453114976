import { Toast } from '@getvim/atomic-ui';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { featureFlagsClient } from '../../../../clients/feature-flags/feature-flags.client';
import { useOrganization } from '../../useOrganization';
import { useSystemData } from '../../useSystemData';
import { useErrorToastListener } from './errors/useErrorToastListener';
import { useNotificationsToastListener } from './notifications/useNotificationsToastListener';

import './index.less';

const { ToastContainer: AtomicToastContainer } = Toast;

/**
 * Wrap listener hook in a react component so we can load it conditionally
 */
const ErrorToastListener: React.FC = () => {
  useErrorToastListener();

  return <></>;
};

export const ToastContainer: React.FC = () => {
  const { organization } = useOrganization();
  const [shouldDisplayErrorPopup, setShouldDisplayErrorPopup] = useState(false);
  const [shouldShow2Notifications] = useFeatureFlag({
    flagName: 'shouldShow2Notifications',
    defaultValue: false,
    flagContext: {
      organizationId_string: organization?.id?.toString(),
    },
  });

  useNotificationsToastListener(shouldShow2Notifications);

  useEffect(() => {
    (async () => {
      const shouldDisplayErrorAsTooltipFF = await featureFlagsClient.getFlag({
        flagName: 'vim-connect-ui.shouldDisplayErrorAsTooltip',
        defaultValue: false,
      });

      setShouldDisplayErrorPopup(shouldDisplayErrorAsTooltipFF);
    })();
  }, []);
  const { hubPosition } = useSystemData();

  return (
    <div className="vim-connect-ui-toast-container">
      <AtomicToastContainer
        className={classNames(
          hubPosition.vertical?.toLowerCase(),
          hubPosition.horizontal?.toLowerCase(),
        )}
        limit={shouldShow2Notifications ? 2 : undefined}
      />
      {shouldDisplayErrorPopup ? <ErrorToastListener /> : null}
    </div>
  );
};
