import { ModuleNames } from '@getvim/vim-connect-communication';

export const notificationsWidgetConfig = {
  id: ModuleNames.VimConnectUINotificationsWidget,
  url: `${window.location.origin}/notifications`,
  display: false,
  ignoreMouseEvents: false,
  size: { width: '345', height: '145' },
  alignment: { top: '50', right: '50' },
  type: 'OVERLAY' as const,
};
