import { logger } from '@getvim/vim-connect-logger';
import { isEmpty } from 'lodash-es';

const ErrorLogger = logger.scope('Vim Unhandled Error');
const messagesToFilter = [
  'Failed to fetch',
  'Extension context invalidated',
  'You have temporarily exceeded your Sumo Logic quota',
];

const logUnhandledErrors = (namespace: string) => {
  // handle regular errors
  window.addEventListener(
    'error',
    (error) => {
      if (!error) return;

      const { message, error: errorObject, filename } = error;
      if ([message, error, filename].some(isEmpty)) {
        // SAM-37228 lower to info log level
        ErrorLogger.info(`Unhandled error without expected fields in ${namespace}`, {
          error,
        });
        return;
      }
      if (
        typeof message === 'string' &&
        messagesToFilter.some((filteredError) => message?.includes(filteredError))
      )
        return;

      ErrorLogger.error(`An error in ${namespace}`, { message, error: errorObject, filename });
      return false;
    },
    true, // useCapture: true
  );

  // handle promises errors that were not handled
  window.addEventListener('unhandledrejection', (error) => {
    if (!error || isEmpty(error)) return;

    const { reason } = error;
    if (!reason) {
      ErrorLogger.warning(`An unhandled rejection without expected fields in ${namespace}`, {
        error,
      });
      return;
    }
    const message = reason.message || reason;
    if (
      typeof message === 'string' &&
      messagesToFilter.some((filteredError) => message?.includes(filteredError))
    )
      return;

    ErrorLogger.error(`An unhandled rejection in ${namespace}`, { error: reason });
  });
};

export default logUnhandledErrors;
