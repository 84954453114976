import React, { FC, useEffect, useState } from 'react';
import { useAnalytics } from '@getvim/vim-app-infra';
import { isEmpty } from 'lodash-es';
import { useSelectedApplicationId } from '../../useApplications';
import { useSystemData } from '../../useSystemData';
import { UserManagement } from '../../../../atomic/organisms';
import { useNotifications } from '../toasts/notifications/useNotifications';
import { internalApi } from '../../../../internal-api';
import { useUser } from '../../useMe';
import {
  setNotificationShown,
  shouldShowNotification,
} from './notifications/shouldShowNotification';
import { UserManagementAnalyticsEventTypes } from './analytics/analytics';
import { shouldShowManagementApp } from '../../../../common';

export const UserManagementWrapper: FC = () => {
  const [selectedAppId] = useSelectedApplicationId();
  const { userManagement, updateUserManagement } = useSystemData();
  const { me, updateMe } = useUser();
  const showNotification = useNotifications();

  const { analyticsClient } = useAnalytics();
  const [didSendAnalytic, setDidSendAnalytic] = useState(false);

  const [showUserManagementApp, setShowUserManagementApp] = useState(false);

  useEffect(() => {
    const getFF = async () => {
      const showUserManagementAppFF = await shouldShowManagementApp(me?.organization.id);
      setShowUserManagementApp(showUserManagementAppFF);
    };
    getFF();
  }, [me]);

  useEffect(() => {
    if (analyticsClient && me && showUserManagementApp && !didSendAnalytic) {
      setDidSendAnalytic(true);
      analyticsClient.track({
        event: UserManagementAnalyticsEventTypes.UserManagementAppEnabled,
        properties: {
          me,
          canEditRole: isEmpty(me.titles) && isEmpty(me.roles),
        },
      });
    }
  }, [analyticsClient, me, showUserManagementApp, didSendAnalytic]);

  useEffect(() => {
    const showNotificationIfNeeded = async () => {
      if (!me || !showUserManagementApp) return;
      const notificationId = `user-fill-information-notification-${me.linkedEhrUser || me.email}-${
        me.organization.id
      }`;
      if (await shouldShowNotification(me, notificationId)) {
        showNotification({
          applicationId: userManagement.id,
          text: 'Complete your user information',
          applicationName: userManagement.tooltip,
          notificationId,
          options: { timeoutInMs: 5000 },
        });
        updateUserManagement({ notifications: 1 });
        setNotificationShown(notificationId);
      }
    };

    showNotificationIfNeeded();
  }, [me, showUserManagementApp]);

  if (!showUserManagementApp) return null;

  if (selectedAppId !== userManagement.id) return null;

  const onUpdateUserRoles = async (roles: string[]) => {
    const { data } = await internalApi.runtimeApi.updateMe({ titles: roles, roles });
    const { updateMe: result } = data;
    const { success } = result;

    if (success) {
      analyticsClient.track({
        event: UserManagementAnalyticsEventTypes.UserManagementTitlesSelected,
        properties: {
          me,
          selectedTitles: roles,
        },
      });
      setTimeout(() => {
        updateUserManagement({ notifications: 0 });
        updateMe({ titles: roles });
      }, 2500);
    }

    return success;
  };

  return <UserManagement user={me} onUpdateUserRoles={onUpdateUserRoles} />;
};
