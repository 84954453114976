import { productToOrgConfigProduct } from '../components/app/analytics/mapping';
import { Application } from '../components/app/types/application';
import { Infra } from '@getvim/vim-connect';
import { Product } from '../components/app/types/analytics';
export const getAppSources = (
  id: Application['id'],
  organization: Infra.Common.Types.MeOrganization | undefined,
  products: Infra.Common.Types.MeProducts[] | undefined,
) => {
  const mappedAppId = productToOrgConfigProduct.get(id as Product);

  if (products) {
    return products.find((product) => product.name === mappedAppId)?.contentSources || [];
  }
  const { config } = organization || {};
  const { applications } = config || {};
  const { sources } = applications?.[mappedAppId!] || {};

  return sources;
};
