import { logger } from '@getvim/vim-connect-logger';
import gql from 'graphql-tag';
import { gqlClient, NoAccessTokenError } from '../../gql-client';

const GET_VIM_PATIENT_ID_QUERY = gql`
  query GetPatient($input: GetPatientInput!) {
    getPatient(input: $input) {
      vimPatientId
    }
  }
`;

export interface GetVimPatientIdInput {
  patientId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

const vimPatientIdLogger = logger.scope('getVimPatientId');

export const getVimPatientId = async (input: GetVimPatientIdInput): Promise<string | undefined> => {
  const { patientId: id, firstName, lastName, dateOfBirth } = input;
  if (!id) {
    vimPatientIdLogger.warning('Cannot query vim patient id without patient id', { input });
    return;
  }

  try {
    const response = await gqlClient.queryAuthenticated({
      query: GET_VIM_PATIENT_ID_QUERY,
      variables: { input: { id, firstName, lastName, dateOfBirth } },
      context: { uri: '/runtime/api/graphql' },
    });

    const vimPatientId = response?.data?.getPatient?.vimPatientId;
    vimPatientIdLogger.info('fetched vimPatientId', { input, vimPatientId });
    return vimPatientId;
  } catch (error) {
    if (error instanceof NoAccessTokenError) {
      vimPatientIdLogger.info('getVimPatientId called when user not logged in', { input, error });
    } else {
      vimPatientIdLogger.error('Failed to fetch to vimPatientId', { input, error });
    }
    return undefined;
  }
};
