import { Infra } from '@getvim/vim-connect';
import { Feature, Product, InformationType } from '../types/analytics';

export const productToFeature: Record<Product, Feature> = {
  [Product.Eligibility]: Feature.Eligibility,
  [Product.QualityGaps]: Feature.CARE_GAPS,
  [Product.DiagnosisGaps]: Feature.CODING_GAPS,
  [Product.PriorAuthCheck]: Feature.PRIOR_AUTH,
  [Product.PatientHealthHistory]: Feature.HEALTH_HISTORY,
  [Product.OrderAssist]: Feature.ORDER_ASSIST,
};

export const productToInformationType = {
  [Product.Eligibility]: InformationType.Eligibility,
  [Product.QualityGaps]: InformationType.QualityGaps,
  [Product.DiagnosisGaps]: InformationType.DiagnosisGaps,
  [Product.PriorAuthCheck]: InformationType.PriorAuthCheck,
  [Product.PatientHealthHistory]: InformationType.PatientHealthHistory,
  [Product.OrderAssist]: InformationType.OrderAssist,
};

export const productToOrgConfigProduct = new Map<Product, Infra.Apps.Product>([
  [Product.DiagnosisGaps, Infra.Apps.Product.DiagnosisGaps],
  [Product.Eligibility, Infra.Apps.Product.Eligibility],
  [Product.PatientHealthHistory, Infra.Apps.Product.PatientHealthHistory],
  [Product.OrderAssist, Infra.Apps.Product.OrderAssist],
  [Product.PriorAuthCheck, Infra.Apps.Product.PriorAuthCheck],
  [Product.QualityGaps, Infra.Apps.Product.QualityGaps],
]);
