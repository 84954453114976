import { DisplayPayload } from './displayPayload';
import { ModifySelfPayload } from '../../types';

export interface PayloadData {
  event: WidgetIncomingEventName;
  data?: any;
  error?: any;
}

export enum WidgetIncomingEventName {
  Display = 'Display',
  Hide = 'Hide',
}

export enum WidgetOutgoingEventName {
  ModifySelf = 'ModifySelf',
}

export interface OutgoingEvents {
  [WidgetOutgoingEventName.ModifySelf]: ModifySelfPayload;
}

export interface IncomingEvents {
  [WidgetIncomingEventName.Display]: DisplayPayload;
  [WidgetIncomingEventName.Hide]: undefined;
}
