import React, { useState, useEffect, PropsWithChildren } from 'react';
import { logger } from '@getvim/vim-connect-logger';
import { useSystemData } from './useSystemData';

const transparencyLogger = logger.scope('transparency-logger');

export const useWidgetTransparency = () => {
  const { initialData } = useSystemData();
  const [pointerEventsState, setPointerEvent] = useState<'none' | 'initial'>('none');

  useEffect(() => {
    function onmousemove(clientX, clientY) {
      const element = document.elementFromPoint(clientX, clientY);
      const pointerEventsState =
        !element ||
        ['BODY', 'HTML'].includes(element.tagName) ||
        ['theme-css-variables-wrapper', 'root'].includes(element.id) ||
        element.classList.contains('main-container')
          ? 'none'
          : 'initial';

      setPointerEvent(pointerEventsState);
    }

    window.addEventListener('message', (e) => {
      if (e.data.type === 'host-mousemove') {
        const { clientX, clientY } = e.data.payload;
        onmousemove(clientX, clientY);
      }
    });

    document.body.onmousemove = (e) => {
      const { clientX, clientY } = e;
      onmousemove(clientX, clientY);
    };
  }, []);

  useEffect(() => {
    if (initialData !== undefined) {
      window.$vimConnectParentTransparentPort?.postMessage({
        type: 'change-pointer-events',
        payload: {
          state: pointerEventsState,
        },
      });
    }
  }, [pointerEventsState]);
};

export class WidgetTransparencyProvider extends React.Component<PropsWithChildren<unknown>> {
  componentDidCatch(error) {
    window.$vimConnectParentTransparentPort?.postMessage({
      type: 'change-pointer-events',
      payload: {
        state: 'none',
      },
    });
    transparencyLogger.error(
      'Fullscreen app had a render error, sent pointer events none to barebone',
      error,
    );
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
