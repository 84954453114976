import { Toast } from '@getvim/atomic-ui';
import { EventType } from '@getvim/utils-vim-connect-communication';
import { useAnalytics } from '@getvim/vim-app-infra';
import { Entities } from '@getvim/vim-connect';
import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import { Notifications } from '@getvim/vim-connect-communication';
import { useEffect, useState } from 'react';
import { ApplicationInput } from '../../../../app/types/application';
import {
  VimHubNotificationBlockedReason,
  VimHubNotificationHidedReason,
  VimHubNotificationsEventTypes,
} from '../../../../notifications/analytics/analytics';
import { useApplications, useSelectedApplicationId } from '../../../useApplications';
import { useSubscription } from '../../../useEvents';
import './index.less';
import { useNotifications } from './useNotifications';
import { DEFAULT_TOAST_ID } from './showNotification';

function clearToasts() {
  Toast.clearAll();
}
export const useNotificationsToastListener = (shouldShow2Notifications) => {
  const { analyticsClient } = useAnalytics();
  const showNotification = useNotifications();
  const applications = useApplications();
  const [selectedAppId, selectApp] = useSelectedApplicationId();
  const [currentToastWidgetId, setCurrentToastWidgetId] = useState<string | undefined>(undefined);

  useSubscription(
    EventType.VCHubSendToNotifications,
    ([data]) => {
      const vimConnectHubPayload = data as {
        event: Notifications.Types.NotificationEvent;
        data: Notifications.Types.NotificationShowEventPayload;
      };
      const {
        event,
        data: { widgetId, notificationId },
      } = vimConnectHubPayload;

      const { disabled, tooltip } = applications[widgetId!];
      const applicationAlreadySelected = selectedAppId !== undefined;
      const toastId = shouldShow2Notifications ? widgetId : DEFAULT_TOAST_ID;

      switch (event) {
        case Notifications.Types.NotificationEvent.Show:
          if (applicationAlreadySelected || disabled) {
            const reason = applicationAlreadySelected
              ? VimHubNotificationBlockedReason.HubWasOpened
              : VimHubNotificationBlockedReason.AppWasDisabled;

            analyticsClient.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationBlocked,
              properties: { appName: widgetId, notificationId, reason },
            });
            return;
          }
          setCurrentToastWidgetId(vimConnectHubPayload.data.widgetId);

          showNotification({
            ...vimConnectHubPayload.data,
            applicationId: widgetId,
            toastId,
            applicationName: tooltip,
          });
          break;
        case Notifications.Types.NotificationEvent.Hide: {
          const isCurrentWidgetToastShown = shouldShow2Notifications
            ? Toast.isDisplayed(widgetId)
            : widgetId === currentToastWidgetId;
          if (isCurrentWidgetToastShown) {
            Toast.clearById(toastId);
            analyticsClient.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationHide,
              properties: {
                appName: widgetId,
                reason: VimHubNotificationHidedReason.HideByApp,
                notificationId,
              },
            });
          }
        }
      }
    },
    [applications, selectedAppId, shouldShow2Notifications],
  );
  useSubscription(EventType.OpenApp, ([data]: any) => {
    selectApp(data?.data?.widgetId, Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL);
    Toast.clearAll();
  });
  useSubscription(WidgetIncomingEvent.PatientOutContext, clearToasts);
  useSubscription(WidgetIncomingEvent.Logout, clearToasts);
  useEffect(clearToasts, [selectedAppId]);
  useSubscription(
    WidgetIncomingEvent.ModifyApplication,
    ([{ id, modifications }]) => {
      const isCurrentWidgetToastShown = shouldShow2Notifications
        ? Toast.isDisplayed(id)
        : id === currentToastWidgetId;
      const applicationData = modifications as Partial<ApplicationInput>;
      if (isCurrentWidgetToastShown && applicationData.disabled) {
        Toast.clearById(shouldShow2Notifications ? id : DEFAULT_TOAST_ID);
      }
    },
    [currentToastWidgetId, shouldShow2Notifications],
  );
};
