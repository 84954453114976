import React from 'react';
import { ThemeAppLogo } from '@getvim/atomic-ui';
import { Infra } from '@getvim/vim-connect';
import classNames from 'classnames';
import NotificationsIndicator from './NotificationsIndicator';
import './ApplicationIcon.less';
import { APP_BUTTON_EDGE_LENGTH_PIXELS } from '../utils/positioningUtils';

interface TooltipIconProps {
  hubValid: boolean;
  active: boolean;
  loading: boolean;
  notifications: number;
  expandOnMouseEnter: (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  organization?: Infra.Common.Types.MeOrganization;
  ignoreValue?: boolean;
}

const TooltipIcon: React.FC<TooltipIconProps> = ({
  active,
  hubValid,
  loading,
  organization,
  expandOnMouseEnter,
  notifications = 0,
  ignoreValue = false,
}) => {
  return (
    <div
      className={classNames('icon-container normal-cursor')}
      onMouseEnter={expandOnMouseEnter}
      style={{ height: `${APP_BUTTON_EDGE_LENGTH_PIXELS}px` }}
    >
      {notifications > 0 ? (
        <NotificationsIndicator value={notifications} ignoreValue={ignoreValue} />
      ) : null}
      {organization && (
        <ThemeAppLogo loading={loading} inactive={!loading && !active} invalid={!hubValid} />
      )}
    </div>
  );
};

export default TooltipIcon;
