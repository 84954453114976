import React, { useEffect, useState, MouseEvent } from 'react';
import { ModuleNames, useVimCommunication } from '@getvim/vim-app-infra';
import { Notifications } from '@getvim/vim-connect-communication';
import { Toast } from '@getvim/atomic-ui';
import { CommonConnector } from '@getvim/extension-connector';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import './index.less';
import { NotificationProps, ActionMethod } from './notification/types';
import { getApplicationIcon } from '../app/icons/ApplicationIconsMapping';
import {
  doesProviderReachedNotificationLimit,
  storeNotificationDetails,
} from './notifications-details';
import { ActionButton, EventType } from '@getvim/utils-vim-connect-communication';
import { InitialData } from './types';
import { Infra } from '@getvim/vim-connect';
import { logger } from '@getvim/vim-connect-logger';

const { Zoom, ToastContainer, createToast } = Toast;

const notificationsWidgetLogger = logger.scope('Notifications Widget');

const DEFAULT_NOTIFICATION_TIMEOUT = 8000;
const DEFAULT_TOAST_ID = 'vim-connect-ui-notifications-toast';

const VimConnectUINotifications: React.FC = () => {
  const [organization, setOrganization] = useState<Infra.Common.Types.MeOrganization>();
  const { vimCommunication } = useVimCommunication();

  const show = async (data: NotificationProps) => {
    try {
      const {
        notificationId,
        widgetName,
        widgetId,
        text,
        options,
        actionButtons,
        title,
        dismissText,
      } = data;

      const { timeoutInMs, vimConnectStyle, optumStyle } = options || {};

      if (await doesProviderReachedNotificationLimit(notificationId)) {
        sendSyncEventToVimConnectUIHubWidget({
          event: Notifications.Types.NotificationToVimConnectUIEvent.OnBlocked,
          data: { notificationId, widgetId },
        });

        return;
      }

      await storeNotificationDetails(notificationId);

      const ApplicationIcon = getApplicationIcon(widgetId, { disabled: false });
      const eventData: Partial<NotificationProps> = { widgetId, notificationId };
      let closeIconClicked: boolean | undefined = undefined;

      createToast({
        actionButtons: handleActionButtons(actionButtons, eventData),
        title: title ?? widgetName,
        message: text,
        autoClose: timeoutInMs ?? DEFAULT_NOTIFICATION_TIMEOUT,
        vimConnectStyle: vimConnectStyle ?? true,
        optumStyle: optumStyle ?? false,
        toastIcon: <ApplicationIcon className="icon" />,
        html: true,
        id: DEFAULT_TOAST_ID,
        closeButton: ({ closeToast }) => (
          <i
            className="material-icons icon-x x-icon"
            onClick={(e) => {
              e.stopPropagation();
              closeIconClicked = true;
              closeToast();
            }}
          />
        ),
        draggable: false,
        transition: Zoom,
        dismissText,
        onOpen: () => {
          sendSyncEventToVimConnectUIHubWidget({
            event: Notifications.Types.NotificationToVimConnectUIEvent.OnOpen,
            data: eventData,
          });
          CommonConnector.modifyWidget({
            widget: { id: ModuleNames.VimConnectUINotificationsWidget, display: true },
          });
        },
        onClose: () => {
          sendSyncEventToVimConnectUIHubWidget({
            event: Notifications.Types.NotificationToVimConnectUIEvent.OnClose,
            data: {
              ...eventData,
              method: closeIconClicked ? ActionMethod.MANUAL : ActionMethod.AUTOMATIC,
            },
          });
          closeIconClicked = undefined;

          CommonConnector.modifyWidget({
            widget: { id: ModuleNames.VimConnectUINotificationsWidget, display: false },
          });
        },
        onMouseEnter: () => {
          sendSyncEventToVimConnectUIHubWidget({
            event: Notifications.Types.NotificationToVimConnectUIEvent.OnMouseEnter,
            data: eventData,
          });
        },
        onClick: () => {
          sendSyncEventToVimConnectUIHubWidget({
            event: Notifications.Types.NotificationToVimConnectUIEvent.OnClick,
            data: { ...eventData },
          });
          closeIconClicked = undefined;
        },
      });
    } catch (error) {
      notificationsWidgetLogger.warning('error occurred when trying to show notifications widget', {
        error,
      });
    }
  };

  const handlers = {
    [Notifications.Types.NotificationEvent.Show]: show,
  };

  const handleActionButtons = (
    actionButtons: ActionButton[],
    eventData: Partial<NotificationProps>,
  ): any =>
    actionButtons?.map((actionButton) => {
      const { action, ...restActionButton } = actionButton;
      return {
        ...restActionButton,
        onClick: (e: MouseEvent) => {
          e.stopPropagation();

          sendSyncEventToVimConnectUIHubWidget({
            event: Notifications.Types.NotificationToVimConnectUIEvent.OnActionButtonClick,
            data: { ...eventData, action },
          });
        },
      };
    });

  const sendSyncEventToVimConnectUIHubWidget = (payload: any) => {
    vimCommunication?.sendEvent(
      ModuleNames.VimConnectUIHubWidget,
      EventType.NotificationsSendToVCHub,
      payload,
    );
  };

  useEffect(
    () =>
      vimCommunication?.listenToEvent('data', async ({ data }) => {
        const { event } = data;

        switch (event) {
          case Notifications.Types.NotificationEvent.Show:
            const { data: eventData } = data;

            const handler = handlers[event];
            if (!handler) return;
            handler(eventData);

            break;

          case Notifications.Types.VimConnectUIToNotificationEvent.InitData:
            const { data: initData } = data;
            const { organization }: InitialData = initData;
            setOrganization(organization);

            break;

          default:
            break;
        }
      }),
    [vimCommunication],
  );

  return (
    <ThemeVariablesWrapper theme={organization ? themes[organization.theme] : themes.vimConnect}>
      <div className={ModuleNames.VimConnectUINotificationsWidget}>
        <ToastContainer />
      </div>
    </ThemeVariablesWrapper>
  );
};

export default VimConnectUINotifications;
