import { isEmpty } from 'lodash-es';
import { Application } from '../components/app/types/application';

export const getAppsNotificationsCount = (applications: Record<string, Application>) => {
  if (isEmpty(applications)) return 0;

  return Object.entries(applications).reduce(
    (prevCount, [, { notifications = 0 }]) => prevCount + notifications,
    0,
  );
};
