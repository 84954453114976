import { createErrorToast, Toast } from '@getvim/atomic-ui';
import { useAnalytics } from '@getvim/vim-app-infra';
import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import { useRef } from 'react';
import { VimConnectUiAnalyticsEventTypes } from '../../../../app/analytics/analytics';
import { HubInternalEvents, useInternalHubEvents, useSubscription } from '../../../useEvents';
import { useHubInvalid } from '../../../useInvalid';
import './index.less';

const ERROR_TITLE = 'Something is not working';
const TOAST_ID = 'error-popup';
const TOAST_TIMEOUT = 60000;

export const useErrorToastListener = () => {
  const { analyticsClient } = useAnalytics();

  const internalEvents = useInternalHubEvents();
  const [_isInvalid, setHubInvalid] = useHubInvalid();
  const currentToast = useRef({ autoClose: TOAST_TIMEOUT, message: '' });
  const dismisser = useRef(() => {});
  const dependencies = [internalEvents];

  useSubscription(
    WidgetIncomingEvent.RecoverError,
    () => {
      setHubInvalid(false);
      dismisser.current();
    },
    [dependencies],
  );

  useSubscription(
    WidgetIncomingEvent.DisplayError,
    ([newText]) => {
      setHubInvalid(true);
      if (newText && newText !== currentToast.current.message) {
        currentToast.current = { message: newText, autoClose: TOAST_TIMEOUT };
      }

      dismisser.current = createErrorToast({
        title: ERROR_TITLE,
        message: currentToast.current.message,
        html: true,
        autoClose: currentToast.current.autoClose,
        closeButton: true,
        draggable: false,
        dismissButton: false,
        className: 'vim-connect-hub-alert-window',
        hideProgressBar: true,
        toastId: TOAST_ID,
        transition: Toast.Zoom,
        onClose: () => {},
      });

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.PopupErrorDisplayed,
        properties: {
          errorMessage: newText,
        },
      });
    },
    dependencies,
  );

  useSubscription(
    WidgetIncomingEvent.Logout,
    () => {
      setHubInvalid(false);
    },
    dependencies,
  );

  useSubscription(
    HubInternalEvents.HubMouseEnterInInvalidState,
    () => {
      dismisser.current = createErrorToast({
        title: ERROR_TITLE,
        message: currentToast.current.message,
        html: true,
        autoClose: currentToast.current.autoClose,
        closeButton: true,
        draggable: false,
        dismissButton: false,
        className: 'vim-connect-hub-alert-window',
        hideProgressBar: true,
        toastId: TOAST_ID,
        transition: Toast.Zoom,
        onClose: () => {},
      });

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.PopupErrorDisplayed,
        properties: {
          errorMessage: currentToast.current.message,
        },
      });
    },
    dependencies,
  );
};
