export const VIM_CONNECT_HUB_APP_OPENED = 'vim-connect-hub-app-opened';

export const sendMessageToWidget = (
  targetWidgetId: string | string[],
  message: { type: string; payload: any },
) => {
  for (const currWidgetId of [targetWidgetId].flat()) {
    const widgetIframe = document.querySelector(
      `iframe[vim-connect-ui-widget-id="${currWidgetId}"]`,
    ) as HTMLIFrameElement;
    if (!widgetIframe?.contentWindow) {
      console.error(`iframe widget of ${currWidgetId} was not found!`);
      return;
    }

    widgetIframe?.contentWindow?.postMessage(message, '*');
  }
};
