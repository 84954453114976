import NodeCache from 'node-cache';
import { Logger } from '@getvim/vim-connect-logger';
import { getVimPatientId as getVimPatientIdRequest } from '../../../../../infra/common/api/patient/get-vim-patient-id';
import { TransformedPatientInContextPayload } from '../../../../../standard/events';
import { timeoutAfter } from '../../../../../common';
import { EventOptions } from '../../../../../standard/events/event-options';

const TIMEOUT_AFTER = 3000; // 3 seconds
const CACHE_TTL = 15 * 60; // 15 minutes cache
const vimPatientIdCache = new NodeCache({ stdTTL: CACHE_TTL });

export const getVimPatientId = async (
  patient: TransformedPatientInContextPayload,
  logger: Logger,
  options?: EventOptions,
): Promise<string | undefined> => {
  try {
    const { shouldUseTimeoutOnTransformers } = options ?? {};
    const { demographics, patientId } = patient;
    const { firstName, lastName, dateOfBirth } = demographics ?? {};

    if (!firstName || !lastName || !dateOfBirth) {
      logger.info('Received a patient with missing demographics fields in setVimPatientId', {
        demographics,
      });
      return;
    }

    let vimPatientId: string | undefined = vimPatientIdCache.get(patientId);
    logger.info('Current cached VimPatientId', {
      noPHI: true,
      patientId,
      cachedVimPatientId: vimPatientId,
    });

    if (!vimPatientId) {
      vimPatientId = shouldUseTimeoutOnTransformers
        ? await timeoutAfter(
            () => getVimPatientIdRequest({ patientId, firstName, lastName, dateOfBirth }),
            TIMEOUT_AFTER,
            true,
          )
        : await getVimPatientIdRequest({ patientId, firstName, lastName, dateOfBirth });
      vimPatientIdCache.set(patientId, vimPatientId);
    }

    return vimPatientId;
  } catch (error) {
    logger.error('Failed to set vimPatientId in patient payload transformer', {
      error,
      patient,
    });
  }
};
