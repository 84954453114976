import React, { FC } from 'react';
import { AlignEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import { Infra } from '@getvim/vim-connect';
import { isEmpty } from 'lodash-es';
import { ManagementAction, ManagementHeader } from '../../atoms';
import { ManagementContent } from '../../molecules';

import './index.less';

interface UserManagementProps {
  user: Infra.Common.Types.MeEhrUser | undefined;
  onUpdateUserRoles: (roles: string[]) => Promise<boolean>;
}

export const UserManagement: FC<UserManagementProps> = ({ user, onUpdateUserRoles }) => {
  return (
    <div className="management-container">
      <Text
        className="management-title"
        text="User Management"
        align={AlignEnum.left}
        weight={WeightEnum.bold}
        size={SizeEnum['18px']}
      />
      <ManagementHeader user={user} />
      {isEmpty(user?.titles) && (
        <ManagementAction user={user} onUpdateUserRoles={onUpdateUserRoles} />
      )}
      <ManagementContent user={user} />
    </div>
  );
};
