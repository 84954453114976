import {
  GetReferralIdMappingInput,
  ReferralIdMapping,
  SaveReferralIdMappingInput,
} from '@getvim/platform-types';
import gql from 'graphql-tag';
import { gqlClient } from '../../gql-client';

const GET_REFERRAL_ID_MAPPING_QUERY = gql`
  query GetReferralIdMapping($input: GetReferralIdMappingInput!) {
    getReferralIdMapping(input: $input) {
      createdDate
      ehrId
      externalId
      source
    }
  }
`;

const SAVE_REFERRAL_ID_MAPPING_MUTATION = gql`
  mutation SaveReferralIdMapping($input: SaveReferralIdMappingInput!) {
    saveReferralIdMapping(input: $input) {
      createdDate
      ehrId
      externalId
      source
    }
  }
`;

export const getReferralIdMapping = async (
  input: GetReferralIdMappingInput,
): Promise<ReferralIdMapping | undefined> => {
  const response = await gqlClient.queryAuthenticated<
    { getReferralIdMapping: ReferralIdMapping },
    { input: GetReferralIdMappingInput }
  >({
    query: GET_REFERRAL_ID_MAPPING_QUERY,
    variables: { input },
    context: { uri: '/runtime/api/graphql' },
    fetchPolicy: 'network-only',
  });

  const {
    data: { getReferralIdMapping: result },
  } = response;

  return result;
};

export const saveReferralIdMapping = async (
  input: SaveReferralIdMappingInput,
): Promise<ReferralIdMapping> => {
  const response = (await gqlClient.mutateAuthenticated<ReferralIdMapping>({
    mutation: SAVE_REFERRAL_ID_MAPPING_MUTATION,
    variables: { input },
    context: { uri: '/runtime/api/graphql' },
  })) as any;
  return response?.data?.saveReferralIdMapping;
};
