import React, { FC, useCallback, useState } from 'react';
import { Infra } from '@getvim/vim-connect';
import { isEmpty } from 'lodash-es';
import { ROLES } from '@getvim/platform-consts';
import { MissingInformationContainer, UpdateRoleForm } from '../../molecules';
import { UpdateRoleFormSuccess } from '../../molecules/updateRoleFormSuccess';

interface ManagementActionProps {
  user: Infra.Common.Types.MeEhrUser | undefined;
  onUpdateUserRoles: (roles: string[]) => Promise<boolean>;
}

export const ManagementAction: FC<ManagementActionProps> = ({ onUpdateUserRoles }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);

  const onSubmit = useCallback(async () => {
    if (isEmpty(roles)) return;
    const success = await onUpdateUserRoles(roles);
    setIsSuccess(success);
  }, [roles]);

  const onChange = useCallback((values) => setRoles(values), []);

  return (
    <MissingInformationContainer title={isSuccess ? '' : 'Please complete your information'}>
      {isSuccess ? (
        <UpdateRoleFormSuccess />
      ) : (
        <UpdateRoleForm
          options={ROLES}
          onChange={onChange}
          defaultValues={[]}
          onSubmit={onSubmit}
        />
      )}
    </MissingInformationContainer>
  );
};
