import * as React from 'react';

interface SvgRedDotProps {
  className?: string;
}

export default function SvgRedDot({ className }: SvgRedDotProps) {
  return (
    <svg
      className={className}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill="#E02062" />
      <path
        d="M7.36 7.5L7.56 3H6.02L6.24 7.5H7.36ZM6 8.97C6 9.4 6.36 9.77 6.8 9.77C7.24 9.77 7.6 9.4 7.6 8.97C7.6 8.53 7.24 8.16 6.8 8.16C6.36 8.16 6 8.53 6 8.97Z"
        fill="white"
      />
    </svg>
  );
}
