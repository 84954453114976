import React, { useEffect, useState } from 'react';
import {
  ThemeContext,
  themes,
  ThemeVariablesWrapper,
  VimThemesKeyValue,
} from '@getvim/components-hooks-use-theme';
import { registerWidget, sendEventToApp } from '@getvim/utils-vim-connect-communication';

import './index.less';
import { Infra } from '@getvim/vim-connect';
import { ModifySelfPayload } from '../types';
import { PayloadData, WidgetIncomingEventName, WidgetOutgoingEventName } from './types/events';
import { DisplayPayload } from './types/displayPayload';
import { InitDataPayload } from './types/initDataPayload';

const VimConnectUITooltip: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  const [modifyPayload, setModifyPayload] = useState<ModifySelfPayload>();
  const [organization, setOrganization] = useState<Infra.Common.Types.MeOrganization>();
  const [hubPosition, setHubPosition] = useState<Infra.Common.Types.HubPosition>({
    vertical: Infra.Common.Types.VerticalHubPosition.High,
    horizontal: Infra.Common.Types.HorizontalHubPosition.Right,
  });

  useEffect(() => {
    registerWidget<PayloadData>((payload) => {
      const eventToAction = {
        [WidgetIncomingEventName.Display]: (data: DisplayPayload) => {
          const { text: newText, alignment: newAlignment, hubPosition: newHubPosition } = data;
          setText(newText);
          setModifyPayload({
            alignment: newAlignment,
            ignoreMouseEvents: false,
          });
          setVisible(true);
          setHubPosition(newHubPosition);
        },
        [WidgetIncomingEventName.Hide]: () => {
          setModifyPayload({ ignoreMouseEvents: true });
          setVisible(false);
        },
        [WidgetIncomingEventName.InitData]: (data: InitDataPayload) => {
          const { organization } = data;
          setOrganization(organization);
        },
      };

      const { event, data } = payload;
      eventToAction[event]?.(data);
    });
  }, []);

  useEffect(() => {
    sendEventToApp(WidgetOutgoingEventName.ModifySelf, modifyPayload);
  }, [modifyPayload]);

  const theme = themes[organization?.theme as keyof VimThemesKeyValue] || themes.vimConnect;
  ThemeContext(theme);

  const getHubPosition = () =>
    hubPosition.horizontal === Infra.Common.Types.HorizontalHubPosition.Left ? 'left' : 'right';

  return (
    <ThemeVariablesWrapper theme={theme}>
      <div className={`tooltip-container${visible ? '' : ' hidden'} ${getHubPosition()}`}>
        <div className="tooltip">{text}</div>
      </div>
    </ThemeVariablesWrapper>
  );
};

export default VimConnectUITooltip;
