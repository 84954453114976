import React from 'react';
import './index.less';

interface NotificationsIndicatorProps {
  value: number;
  ignoreValue: boolean;
}

/* eslint-disable no-nested-ternary */
export const NotificationsIndicator: React.FC<NotificationsIndicatorProps> = ({
  value,
  ignoreValue,
}) => {
  const notifications = ignoreValue ? '' : value < 100 ? value : '99+';
  const className = `notification ${ignoreValue ? 'empty' : ''} ${
    ignoreValue || value < 100 ? '' : 'wide'
  }`;

  return <div className={className}>{notifications}</div>;
};
