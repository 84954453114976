import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { WidgetsMessageChannel } from '@getvim/vim-connect';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { featureFlagsClient } from './clients/feature-flags/feature-flags.client';

WidgetsMessageChannel.setupParentPorts().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
        <App />
      </FeatureFlagProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
