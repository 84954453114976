import { ApplicationInput } from '../types/application';
import { IconsMapping, SVGIconProps, UserShieldIcon } from '@getvim/vim-icons';

const changeableIconApplications = ['eligibility'];

export const getApplicationIcon = (
  applicationId: string,
  applicationData: Partial<ApplicationInput>,
): React.FC<SVGIconProps> => {
  if (changeableIconApplications.includes(applicationId)) {
    const disabledText = applicationData?.disabled ? 'disabled' : 'active';
    return IconsMapping[`${applicationId}-${disabledText}`] ?? UserShieldIcon;
  }
  return IconsMapping[applicationId] ?? UserShieldIcon;
};

export const modifyApplicationIcon = (
  applicationId: string,
  applicationData: Partial<ApplicationInput>,
): { iconSource: React.FC<SVGIconProps> } | {} => {
  // When modifying application, only part of the ApplicationInput is distributed, so we want to change the icon only when disabled property has changed
  if (
    changeableIconApplications.includes(applicationId) &&
    applicationData.hasOwnProperty('disabled')
  ) {
    return {
      iconSource: getApplicationIcon(applicationId, applicationData),
    };
  }
  return {};
};
