import React, { Dispatch, SetStateAction } from 'react';
import { CollapsingVimFooter, ThemeLogo } from '@getvim/atomic-ui';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { Standard } from '@getvim/vim-connect';
import { Application } from '../types/application';
import AppWrapper from './AppWrapper';
import { PatientHeader } from '../../../atomic/molecules';
import PoweredByVim from './powered-by-vim/PoweredByVim';
import './AppsContainer.less';
import { WIDGET_CONTENT_WIDTH, WRAPPER_HEIGHT_VH } from '../utils/positioningUtils';
import { CloseButton } from '../common/close-button';
import { getWindowTabId } from '../../../common';
import { isShowPatientHeader } from '../../../common/is-show-patient-header';

interface AppsContainerProps {
  applications: Record<Application['id'], Application>;
  selectedApp: Application | undefined;
  patient: Standard.Events.TransformedPatientInContextPayload | undefined;
  isAppMouseEnter: boolean;
  setIsAppMouseEnter: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  organizationId: number | undefined;
}

const AppsContainer: React.FC<AppsContainerProps> = ({
  applications,
  selectedApp,
  patient = undefined,
  isAppMouseEnter,
  setIsAppMouseEnter,
  onClose,
  organizationId,
}: AppsContainerProps) => {
  const shouldShowPatientHeader = isShowPatientHeader(selectedApp?.id ?? '');
  const [showCollapsingVimFooter] = useFeatureFlag({
    flagName: 'shouldShowCollpasingVimFooter',
    defaultValue: false,
  });

  const [shouldHideVimLogoInHeaderSectionFF] = useFeatureFlag({
    flagName: 'shouldHideVimLogoInHeaderSection',
    defaultValue: false,
    team: Team.VimOs,
    flagContext: { organizationId },
  });

  const customSize = selectedApp?.id ? applications?.[selectedApp?.id]?.size : undefined;
  // TODO: use vim-connect-communication getTabId methods
  const tabId = getWindowTabId();

  return (
    <div
      className={`wrapper ${selectedApp ? '' : 'hidden'} ${
        showCollapsingVimFooter && 'show-collapsing-footer'
      }`} // TODO: while removing the FF, remove show-collapsing-footer class and update the less file
      style={{
        height: customSize?.height ?? `${WRAPPER_HEIGHT_VH}vh`,
        width: customSize?.width ?? `${WIDGET_CONTENT_WIDTH}px`,
      }}
      onMouseEnter={() => {
        if (isAppMouseEnter) return;
        setIsAppMouseEnter(true);
      }}
    >
      {shouldHideVimLogoInHeaderSectionFF ? (
        shouldShowPatientHeader && patient ? (
          <div className="app-container-header-wrapper-new">
            <PatientHeader isShowLogo={false} patient={patient} />
            <CloseButton style={{ padding: '0px', height: '15px' }} onClick={onClose} />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '5px' }}>
            <CloseButton onClick={onClose} />
          </div>
        )
      ) : (
        <div className="wrapper-header">
          <div className="container-header">
            <ThemeLogo />
            <CloseButton onClick={onClose} />
          </div>
          {patient && <PatientHeader isShowLogo={true} patient={patient} />}
        </div>
      )}

      {Object.keys(applications).map((id) => {
        const isVisible = id === selectedApp?.id;
        return (
          <AppWrapper application={applications[id]} tabId={tabId} isVisible={isVisible} key={id} />
        );
      })}
      {showCollapsingVimFooter ? <CollapsingVimFooter /> : <PoweredByVim />}
    </div>
  );
};

export default AppsContainer;
