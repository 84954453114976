import { retry, RetryOptions } from '@vim/promise';
import { logger as LoggerImport } from '@getvim/vim-connect-logger';

const logger = LoggerImport.scope('Poller/lockedPoller.ts');

export interface LockedPoller {
  start: () => void;
  stop: () => void;
  isRunning: boolean;
  changeInterval: (ms: number) => void;
}

// this poller executes the promise every X seconds OR when the periodicPromise has ended (whichever's slower)
export function createLockedPoller(
  periodicPromise: () => Promise<any>,
  intervalInMs = 0,
  onError?: (any) => void,
  retryOptions?: RetryOptions,
): LockedPoller {
  let isRunning = false;
  let currentIntervalInMs = intervalInMs;

  function execute() {
    if (isRunning) {
      /* c8 ignore start */
      const promises = [
        sleep(currentIntervalInMs),
        retry(
          () =>
            periodicPromise().catch((error) => {
              if (isRunning) {
                throw error;
              } else {
                return undefined;
              }
            }),
          {
            retries: 0,
            minTimeout: currentIntervalInMs,
            ...retryOptions,
          },
        ),
      ];
      Promise.allSettled(promises).then((results) => {
        const error = results.find(
          (result) => result.status === 'rejected',
        ) as PromiseRejectedResult;
        if (error) {
          if (onError) {
            onError(error.reason);
          } else {
            logger.warning('Got error while polling, continuing to poll', error.reason);
          }
        }
        if (isRunning) {
          execute();
        }
      });
      /* c8 ignore stop */
    }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const start = () => {
    isRunning = true;
    execute();
  };

  const stop = () => {
    isRunning = false;
  };

  const changeInterval = (newInterval: number) => {
    currentIntervalInMs = newInterval;
  };

  return { start, stop, isRunning, changeInterval };
}
