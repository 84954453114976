import {
  EventType,
  registerVimConnectUI,
  sendDataToApp,
} from '@getvim/utils-vim-connect-communication';
import { IncomingEvents, WidgetIncomingEvent, WidgetOutgoingEvent } from '@getvim/vim-connect-app';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { HandleEventsPayload } from '../app/types/events';

type IncomingRuntimeEventsState = {
  [event in WidgetIncomingEvent]: Subject<[IncomingEvents[event], MessageEvent]>;
};

type IncomingWidgetEventsState = {
  [event in EventType]: Subject<[any, MessageEvent]>;
};

export enum HubInternalEvents {
  HubMouseEnterInInvalidState = 'hub-mouse-enter-in-invalid-state',
}

type InternalHubEventState = {
  [event in HubInternalEvents]: Subject<any>;
};

type CommunicationContextState = IncomingRuntimeEventsState &
  IncomingWidgetEventsState &
  InternalHubEventState;

const EventsContext = createContext<CommunicationContextState>({} as never);

let didInit = false;

const subjectsExecutionTracker = [
  ...Object.values(WidgetIncomingEvent),
  ...Object.values(EventType),
  ...Object.values(HubInternalEvents),
].reduce((prev, curr) => {
  return {
    ...(prev as any),
    [curr as string]: new BehaviorSubject(false), // We use here a Behavior Subject so it will always return the last value immediately for subscribers
  };
}, {}) as CommunicationContextState;

export const EventsProvider = ({ children }: PropsWithChildren<any>) => {
  const [subjects] = useState(
    [
      ...Object.values(WidgetIncomingEvent),
      ...Object.values(EventType),
      ...Object.values(HubInternalEvents),
    ].reduce((prev, curr) => {
      return {
        ...(prev as any),
        [curr as string]: new Subject(),
      };
    }, {}) as CommunicationContextState,
  );
  const [loggedOut, setLogOut] = useState(false);

  useEffect(() => {
    const handleEvents = (payload: HandleEventsPayload, browserEvent: MessageEvent) => {
      const { event, data } = payload;
      subjectsExecutionTracker[event as keyof typeof subjects].next(false);
      subjects[event as keyof typeof subjects].next([data, browserEvent] as any);
      subjectsExecutionTracker[event as keyof typeof subjects].next(true);
    };

    if (!didInit) {
      didInit = true;
      registerVimConnectUI(handleEvents);
      sendDataToApp({
        event: WidgetOutgoingEvent.HubWidgetInitialized,
      });
    }
  }, [subjects]);

  useEffect(() => {
    const subscriptions: Subscription[] = [];
    subscriptions.push(
      subjects[WidgetIncomingEvent.Logout].subscribe(() => {
        setLogOut(true);
      }),
    );

    if (loggedOut) {
      /**
       * In the case that we're logged out and we log in again (we know this by receiving an InitData event)
       * Then we need to resend the HubWidgetInitialized
       */
      subscriptions.push(
        subjects[WidgetIncomingEvent.InitData].subscribe(() => {
          sendDataToApp({
            event: WidgetOutgoingEvent.HubWidgetInitialized,
          });
          setLogOut(false);
        }),
      );
    }

    return () => {
      for (const subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  }, [subjects, loggedOut]);

  return <EventsContext.Provider value={subjects}>{children}</EventsContext.Provider>;
};

export const useInternalHubEvents = () => {
  const eventSubjects = useContext(EventsContext);

  return eventSubjects as InternalHubEventState;
};

export const useSubscription = <T extends keyof CommunicationContextState>(
  event: T,
  subscriptionCB: (
    payload: T extends keyof IncomingEvents
      ? [IncomingEvents[T], MessageEvent]
      : [unknown, MessageEvent],
  ) => void,
  dependencies: unknown[] = [],
) => {
  const events = useContext(EventsContext);

  useEffect(() => {
    let isSubscriptionTriggered = false;

    const subscription = events[event].subscribe((payload) => {
      isSubscriptionTriggered = true;
      return subscriptionCB(payload);
    });

    /**
     * Every time the effect is unmounted we have to unsubscribe so the callback won't be called again,
     * In some scenarios while the Subject.next is executing - the effect unmounts, and then the callback execution is missed...
     * To solve this, we use another subscription map that tracks the execution and then we wait for the subject to publish it finished executing
     */
    return () => {
      if (!isSubscriptionTriggered) {
        return subscription.unsubscribe();
      }

      let unsubscribe = false;
      let trackerSubscription: Subscription | undefined;

      // eslint-disable-next-line prefer-const
      trackerSubscription = subjectsExecutionTracker[event].subscribe(
        (isSubjectFinishedDispatch) => {
          if (isSubjectFinishedDispatch) {
            subscription.unsubscribe();
            if (!trackerSubscription) {
              /**
               * Can't unsubscribe the tracker yet because the subscription is not yet assigned to the variable,
               * So we use this flag to unsubscribe after it's assigned
               */
              unsubscribe = true;
            } else {
              trackerSubscription.unsubscribe();
            }
          }
        },
      );
      if (unsubscribe) {
        trackerSubscription.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, event, subscriptionCB, ...dependencies]);
};
