export interface HubPosition {
  vertical?: VerticalHubPosition;
  horizontal?: HorizontalHubPosition;
}

export interface HubDirection {
  vertical?: VerticalDirection;
  horizontal?: HorizontalDirection;
}

export enum VerticalHubPosition {
  Low = 'Low',
  Low_Middle = 'Low_Middle',
  Middle = 'Middle',
  High_Middle = 'High_Middle',
  High = 'High',
}

export enum VerticalDirection {
  Down = 'Down',
  Up = 'Up',
}

export enum HorizontalHubPosition {
  Right = 'Right',
  Left = 'Left',
}

export enum HorizontalDirection {
  Right = 'Right',
  Left = 'Left',
}
