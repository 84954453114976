import { Insurer } from '@getvim-os/standard';
import { Infra, Standard } from '@getvim/vim-connect';
import { VimThemes } from '@getvim/components-hooks-use-theme';
import { Application } from '../types/application';
import { EligibleIcon, BoxIcon, MicroscopeIcon, StethoscopeIcon } from '@getvim/vim-icons';

const eligibilityApplication = {
  disabled: false,
  loading: false,
  iconSource: EligibleIcon,
  id: 'eligibility',
  iframeSource: 'http://localhost:3012',
  tooltip: 'Eligibility',
  responseTime: 0,
};

const healthHistoryApplication = {
  disabled: false,
  loading: false,
  iconSource: BoxIcon,
  id: 'healthHistory',
  iframeSource: 'http://localhost:3007',
  tooltip: 'Health History',
  responseTime: 0,
};

const diagnosisGapsApplication = {
  disabled: false,
  loading: false,
  iconSource: MicroscopeIcon,
  id: 'diagnosisGaps',
  iframeSource: 'http://localhost:3010',
  tooltip: 'diagnosisGaps',
  responseTime: 0,
};

const orderAssistApplication = {
  disabled: false,
  loading: false,
  iconSource: StethoscopeIcon,
  id: 'orderAssist',
  iframeSource: 'http://localhost:3008',
  tooltip: 'orderAssist',
  responseTime: 0,
};

export const MOCK_DATA: {
  MOCK_PATIENT: Standard.Events.TransformedPatientInContextPayload;
  MOCK_APPLICATIONS: Record<Application['id'], Application>;
  MOCK_SELECTED_APP: Application;
  MOCK_ORGANIZATION: Infra.Common.Types.MeOrganization;
  MOCK_EXPANDED: boolean;
} = {
  MOCK_PATIENT: {
    patientId: '12345',
    demographics: {
      firstName: 'Mocky',
      lastName: 'Mockovski',
      dateOfBirth: '1928-11-18',
    },
    insurance: {
      insurer: Insurer.UHC,
      memberId: '1234567890',
    },
    vimPatientId: '67890',
    memberTokens: {},
  },
  MOCK_APPLICATIONS: {
    eligibility: eligibilityApplication,
    healthHistory: healthHistoryApplication,
    diagnosisGaps: diagnosisGapsApplication,
    orderAssist: orderAssistApplication,
  },
  MOCK_SELECTED_APP: eligibilityApplication,
  MOCK_ORGANIZATION: {
    id: 1,
    name: 'vim connect',
    ehrUrl: '',
    ehrSystem: 'eCW',
    slug: 'vim-connet',
    cipherKey: 'theBestCipherKey',
    theme: VimThemes.VIM_CONNECT,
    isPopupsEnabled: true,
    config: {
      applications: {},
      schemaName: 'eCW',
      vimConnectUI: true,
    },
  },
  MOCK_EXPANDED: true,
};
