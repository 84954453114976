import React, { useEffect, useRef, useState } from 'react';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';

import { registerWidget, sendEventToApp } from '@getvim/utils-vim-connect-communication';
import { createErrorToast, Toast } from '@getvim/atomic-ui';
import { Infra } from '@getvim/vim-connect';
import { IntlProvider } from 'react-intl';
import { ModifySelfPayload } from '../types';
import './index.less';
import { PayloadData, WidgetIncomingEventName, WidgetOutgoingEventName } from './types/events';
import { DisplayPayload } from './types/displayPayload';

const { ToastContainer } = Toast;

const ERROR_TITLE = 'Something is not working';
const TOAST_ID = 'error-popup';
const TOAST_TIMEOUT = 60000;

const VimConnectUIToast: React.FC = () => {
  const currentToast = useRef({ autoClose: TOAST_TIMEOUT, message: '' });
  const dismisser = useRef(() => {});

  const [modifyPayload, setModifyPayload] = useState<ModifySelfPayload>();

  useEffect(() => {
    registerWidget<PayloadData>((payload) => {
      const eventToAction = {
        [WidgetIncomingEventName.Display]: (data: DisplayPayload) => {
          const { text: newText, alignment: newAlignment, autoClose, horizontalHubPosition } = data;
          if (newText && newText !== currentToast.current.message) {
            currentToast.current = { message: newText, autoClose: autoClose ?? TOAST_TIMEOUT };
          }
          setModifyPayload({
            alignment: newAlignment,
            ignoreMouseEvents: false,
          });
          dismisser.current = createErrorToast({
            title: ERROR_TITLE,
            message: currentToast.current.message,
            html: true,
            autoClose: currentToast.current.autoClose,
            closeButton: true,
            position:
              horizontalHubPosition === Infra.Common.Types.HorizontalHubPosition.Left
                ? 'top-left'
                : 'top-right',
            dismissButton: false,
            className: 'toast-alert-window',
            hideProgressBar: true,
            toastId: TOAST_ID,
            onClose: () => {},
          });
        },
        [WidgetIncomingEventName.Hide]: () => {
          setModifyPayload({ ignoreMouseEvents: true });
          dismisser.current();
        },
      };
      const { event, data } = payload;
      eventToAction[event]?.(data);
    });
  }, []);

  useEffect(() => {
    sendEventToApp(WidgetOutgoingEventName.ModifySelf, modifyPayload);
  }, [modifyPayload]);

  return (
    <ThemeVariablesWrapper theme={themes.vim}>
      <IntlProvider locale="en">
        <div className="vim-connect-ui-alert">
          <ToastContainer />
        </div>
      </IntlProvider>
    </ThemeVariablesWrapper>
  );
};

export default VimConnectUIToast;
