import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import React, { useState } from 'react';
import { WriteBackOverlay as WriteBackOverlayUI } from '../../../atomic/Pages/WriteBackOverlay';
import { useSubscription } from '../useEvents';

export const WriteBackOverlay: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showLoadingWidget, setShowLoadingWidget] = useState(false);
  const [loadingWidgetId, setLoadingWidgetId] = useState<string | undefined>(undefined);

  useSubscription(WidgetIncomingEvent.DisplayWriteBackOverlay, ([config]) => {
    setShowLoadingWidget(config?.displayLoadingWidget ?? false);
    setLoadingWidgetId(config?.loadingWidgetId);
    setShowOverlay(true);
  });

  const resetOverlay = () => {
    setShowLoadingWidget(false);
    setLoadingWidgetId(undefined);
    setShowOverlay(false);
  };
  useSubscription(WidgetIncomingEvent.HideWriteBackOverlay, resetOverlay);
  useSubscription(WidgetIncomingEvent.Logout, resetOverlay);

  if (!showOverlay) return null;

  return (
    <WriteBackOverlayUI showLoadingWidget={showLoadingWidget} loadingWidgetId={loadingWidgetId} />
  );
};
