import { EventType, sendEventToApp } from '@getvim/utils-vim-connect-communication';
import { WidgetIncomingEvent } from '@getvim/vim-connect-app';
import classNames from 'classnames';
import React from 'react';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { AppContainer as AppContainerUI } from '../../../atomic/molecules';
import { UserManagementWrapper } from './user-management-wrapper/user-management-wrapper';
import { getWindowTabId } from '../../../common';
import { useApplications, useSelectedApplicationId } from '../useApplications';
import { useSubscription } from '../useEvents';
import { usePatient } from '../usePatient';
import AppWrapper, { iframeWidgetIdTag } from './app-wrapper/AppWrapper';
import { useOrganization } from '../useOrganization';

export const AppsContainer: React.FC = () => {
  /**
   * From Runtime ---(Hub)---> Applications Widgets
   */
  useSubscription(
    WidgetIncomingEvent.SendDataToWidget,
    ([{ widgetId, payload }]) => {
      const widgetIframe = document.querySelector(
        `iframe[${iframeWidgetIdTag}="${widgetId}"]`,
      ) as HTMLIFrameElement;
      if (!widgetIframe?.contentWindow) {
        console.error(`iframe widget of ${widgetId} was not found!`);
      }
      widgetIframe?.contentWindow?.postMessage(
        { type: 'vim-connect-send-data-to-widget', payload },
        '*',
      );
    },
    [],
  );

  /**
   * From Application Widget ---(Hub)---> Runtime
   */
  useSubscription(EventType.VCHubSendToRuntime, ([data]) => {
    sendEventToApp(EventType.VCHubWidgetSendToApp, data);
  });

  const applications = useApplications();
  const [selectedAppId, selectApp, onApplicationUIMouseEnter] = useSelectedApplicationId();
  const { organization } = useOrganization();

  const [patient] = usePatient();

  const [shouldHideVimLogoInHeaderSectionFF] = useFeatureFlag({
    flagName: 'shouldHideVimLogoInHeaderSection',
    defaultValue: false,
    team: Team.VimOs,
    flagContext: { organizationId: organization?.id },
  });

  const customSize = selectedAppId ? applications?.[selectedAppId]?.size : undefined;
  // TODO: use vim-connect-communication getTabId methods
  const tabId = getWindowTabId();

  return (
    <AppContainerUI
      className={classNames({ hidden: selectedAppId === undefined })}
      onClose={() => selectApp(undefined)}
      customSize={customSize}
      patient={patient}
      onMouseEnter={onApplicationUIMouseEnter}
      isShowLogo={!shouldHideVimLogoInHeaderSectionFF}
      selectedAppId={selectedAppId}
      organizationId={organization?.id}
    >
      {Object.keys(applications).map((id) => {
        const isVisible = id === selectedAppId;
        return (
          <AppWrapper application={applications[id]} tabId={tabId} isVisible={isVisible} key={id} />
        );
      })}
      <UserManagementWrapper />
    </AppContainerUI>
  );
};
