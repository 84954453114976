import { HorizontalHubPosition, VerticalHubPosition } from './types/hubPosition';

export const appNameAttribute = 'vim-app-name';

export const LOGIN_APP_NAME = 'login';
export const LOGIN_WIDGET_NAME = 'login-form';
export const APP_SIMULATOR_APP_NAME = 'app-simulator';
export const VIM_CONNECT_UI_APP_NAME = 'vim-connect-ui';
export const VIM_CONNECT_UI_UTILS_APP_NAME = 'vim-connect-ui-utils';
export const VIM_CONNECT_NOTIFICATION_WIDGET_NAME = 'vim-connect-ui-notifications';
export const VIM_CONNECT_UI_TOOLTIP_WIDGET_NAME = 'vim-connect-ui-tooltip';
export const VIM_CONNECT_UI_TOAST_WIDGET_NAME = 'vim-connect-ui-toast';

export const loginEvents = ['onLoggedIn'];

export const DEFAULT_HUB_POSITION = {
  vertical: VerticalHubPosition.High,
  horizontal: HorizontalHubPosition.Right,
};

export type UserNotExistReason = 'organization' | 'user';

export const userNotExistReasonToDescription: Record<UserNotExistReason, string> = {
  organization: 'Organization was not identified',
  user: `User is not part of the organization`,
};
