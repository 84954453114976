import { CommonConnector } from '@getvim/extension-connector';
import { Infra } from '@getvim/vim-connect';

interface NotificationDetails {
  lastShowDate: string;
  numberOfShows: number;
}
interface NotificationsDetails {
  [notificationId: string]: NotificationDetails;
}

const todayDate = () => new Date().toISOString().slice(0, 10);
const MAX_SHOWS_PER_DAY = 1;

export const storeNotificationDetails = async (
  notificationId: string | undefined,
): Promise<void> => {
  if (!notificationId) {
    return;
  }

  const today = todayDate();
  await clearOldNotifications();

  const notificationsDetails = await getNotificationsDetails();

  if (!notificationsDetails[notificationId]) {
    notificationsDetails[notificationId] = {
      lastShowDate: today,
      numberOfShows: 1,
    };
    await CommonConnector.storageSet({
      data: {
        [Infra.Common.Types.StorageKnownKey.NOTIFICATIONS_DETAILS]: { ...notificationsDetails },
      },
    });
  } else {
    notificationsDetails[notificationId].numberOfShows += 1;
    await CommonConnector.storageSet({
      data: {
        [Infra.Common.Types.StorageKnownKey.NOTIFICATIONS_DETAILS]: { ...notificationsDetails },
      },
    });
  }
};

const clearOldNotifications = async () => {
  const notificationsDetails = await getNotificationsDetails();
  const today = todayDate();
  const shouldRemoveOldNotifications = Object.keys(notificationsDetails).some(
    (notificationId) => notificationsDetails[notificationId].lastShowDate < today,
  );

  if (shouldRemoveOldNotifications) {
    await CommonConnector.storageSet({
      data: { [Infra.Common.Types.StorageKnownKey.NOTIFICATIONS_DETAILS]: {} },
    });
  }
};

export const doesProviderReachedNotificationLimit = async (
  notificationId: string | undefined,
): Promise<boolean> => {
  const notificationsDetails = await getNotificationsDetails();
  if (!notificationId) return false;

  const notificationDetails = notificationsDetails[notificationId];

  if (!notificationDetails) return false;
  if (notificationDetails.lastShowDate < todayDate()) return false;
  if (notificationDetails.numberOfShows < MAX_SHOWS_PER_DAY) return false;

  return true;
};

export const getNotificationsDetails = async (): Promise<NotificationsDetails> => {
  const { notificationsDetails } = await CommonConnector.storageGet({
    key: Infra.Common.Types.StorageKnownKey.NOTIFICATIONS_DETAILS,
  });

  return notificationsDetails ?? {};
};
