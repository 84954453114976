import gql from 'graphql-tag';
import { CareGap, GetMemberInput } from '../../../../graphql';
import { gqlClient } from '../../gql-client';
import { logger } from '@getvim/vim-connect-logger';
import { Diagnosis } from '../../../../standard/entities';

const getMemberLogger = logger.scope('Get member queries');

const getMemberTokenQuery = gql`
  query getMember($input: GetMemberInput!) {
    getMember(input: $input) {
      memberToken
    }
  }
`;

const getMemberQuery = gql`
  query getMember($input: GetMemberInput!) {
    getMember(input: $input) {
      firstName
      lastName
      dateOfBirth
      memberId
      plan
      memberToken
      groupNumber
      careGaps {
        title
        notes
        providerNpi
        dateSubmitted
      }
    }
  }
`;

interface GetMemberQueryInput {
  input: Partial<GetMemberInput>;
}

interface GetMemberTokenQueryResult {
  getMember: {
    memberToken?: string;
  };
}

export type GetMemberResult =
  | {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      memberId: string;
      memberToken: string;
      plan: string;
      groupNumber: string;
      gender: string;
      careGaps: CareGap[];
    }
  | undefined;

interface GetMemberQueryResult {
  getMember: GetMemberResult;
}

export type GetMemberEligibilityResult =
  | {
      firstName?: string;
      lastName?: string;
      plan?: string;
      groupNumber?: string;
    }
  | undefined;

interface GetMemberEligibilityQueryResult {
  getMember: GetMemberEligibilityResult;
}

enum DecisionCode {
  Required = 1,
  NotRequired = 2,
  Conditional = 3,
  Blocked = 4,
}

interface SiteOfService {
  name: string;
  decision: string;
  code: DecisionCode;
  conditionsList?: string[];
}

interface Procedure {
  code: string;
  description: string;
  decisionSummary: string;
  sitesOfService: SiteOfService[];
}

export interface GetPriorAuthResult {
  procedures: Procedure[];
  diagnosis?: Diagnosis;
  productType: string;
  state: string;
  disclaimer: string;
}

export interface GetPriorAuthResponse {
  data?: GetPriorAuthResult;
  error?: Error;
}

export const getMemberTokenGQLQuery = async (
  input: Partial<GetMemberInput>,
): Promise<string | undefined> => {
  try {
    const { data } = await gqlClient.query<GetMemberTokenQueryResult, GetMemberQueryInput>({
      query: getMemberTokenQuery,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.getMember?.memberToken;
  } catch (error) {
    getMemberLogger.error('Failed get member query for memberToken', { error, input });
    return;
  }
};

export const getMemberGQLQuery = async (
  input: Partial<GetMemberInput>,
): Promise<GetMemberResult> => {
  try {
    const { data } = await gqlClient.query<GetMemberQueryResult, GetMemberQueryInput>({
      query: getMemberQuery,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.getMember;
  } catch (error) {
    getMemberLogger.error('Failed get member query', { error, input });
    return;
  }
};

export const getMemberEligibilityGQLQuery = async (
  input: Partial<GetMemberInput>,
): Promise<GetMemberEligibilityResult> => {
  try {
    const { data } = await gqlClient.query<GetMemberEligibilityQueryResult, GetMemberQueryInput>({
      query: getMemberQuery,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return data?.getMember;
  } catch (error) {
    getMemberLogger.error('Failed get member query', { error, input });
    return;
  }
};
