import React from 'react';
import { Application } from '../../../app/types/application';
import './AppWrapper.less';

export const iframeWidgetIdTag = 'vim-connect-ui-widget-id';

interface AppWrapperProps {
  application: Application;
  tabId: string;
  isVisible: boolean;
}

const AppWrapper: React.FC<AppWrapperProps> = ({
  application,
  tabId,
  isVisible,
}: AppWrapperProps) => {
  return (
    <div className={`application ${isVisible ? '' : 'hidden'}`}>
      <iframe
        {...{ [iframeWidgetIdTag]: application.id }}
        title={application.id}
        src={generateIframeUrl(application.iframeSource, tabId)}
        width="100%"
        height="100%"
        allow="clipboard-write;"
      />
    </div>
  );
};

export default AppWrapper;

function generateIframeUrl(iframeUrl: string, tabId: string) {
  const url = new URL(iframeUrl);
  url.searchParams.set('tabId', tabId);

  return url.href;
}
