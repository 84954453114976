import { MeEhrUser } from '@getvim/vim-connect/build/infra/common/types';
import { isEmpty } from 'lodash-es';
import { ManagementNotification, userManagementStorage } from '../userManagementStorage';

export const shouldShowNotification = async (user: MeEhrUser, notificationId: string) => {
  const managementNotificationDetails = await getNotificationDetails(notificationId);
  return (
    isEmpty(user.titles) &&
    (!managementNotificationDetails ||
      (managementNotificationDetails.numberOfShows < 2 &&
        getDaysDiff(new Date(), new Date(managementNotificationDetails.lastShowDate)) > 0))
  );
};

const getDaysDiff = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

const getNotificationDetails = async (notificationId: string): Promise<ManagementNotification> => {
  const notifications = (await userManagementStorage.getNotifications()) || {};
  return notifications[notificationId];
};

export const setNotificationShown = async (notificationId: string): Promise<void> => {
  let notificationsDetails = await getNotificationDetails(notificationId);
  if (!notificationsDetails) {
    notificationsDetails = {
      lastShowDate: new Date().toISOString(),
      numberOfShows: 1,
    };
  } else {
    notificationsDetails.numberOfShows += 1;
  }
  const notifications = await userManagementStorage.getNotifications();
  await userManagementStorage.setNotifications({
    ...notifications,
    [notificationId]: notificationsDetails,
  });
};
