import React from 'react';
import classNames from 'classnames';
import {
  toast,
  Slide,
  Zoom,
  ToastOptions as ReactToastifyToastOptions,
  ToastContainer,
} from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import Text from '../../atoms/text';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.less';
import Button from '../../atoms/button';
import { Tooltip } from '../../atoms';

const SELECT_BUTTON_DISABLED_MESSAGE = 'Action disabled by your clinic during the beta period';

export enum ToastActionButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface ToastActionButton {
  buttonType: ToastActionButtonType;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

export interface ToastProps {
  title: string;
  message: string;
  html?: boolean;
  dismissText?: string;
  vimConnectStyle?: boolean;
  optumStyle?: boolean;
  toastIcon?: any;
  id?: any;
  actionButtons?: ToastActionButton[];
  onDismissButtonClicked?: any;
  onMouseEnter?: () => void;
}

const clearById = (id: string | number) => {
  toast.dismiss(id);
};

const Toast = ({
  title,
  message,
  html,
  dismissText,
  toastIcon,
  actionButtons,
  onDismissButtonClicked,
}: ToastProps) => (
  <div className="toast">
    <div className="toast-icon">{toastIcon}</div>
    <Text
      className="toast-title"
      size={SizeEnum['18px']}
      colorName={ColorNameEnum.ultraDark}
      weight={WeightEnum.bold}
      text={title}
      html={html}
    />
    <Text
      className="toast-message"
      colorName={ColorNameEnum.ultraDark}
      size={SizeEnum['14px']}
      text={message}
      html={html}
    />
    {dismissText && (
      <Text
        className="margin-top toast-dismiss"
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.semibold}
        size={SizeEnum['14px']}
        text={dismissText}
        onClick={(e) => {
          e.stopPropagation();
          onDismissButtonClicked();
        }}
      />
    )}
    <div className="action-buttons">
      {actionButtons?.map((actionButton) => (
        <Tooltip
          placement="top"
          tooltipContent={SELECT_BUTTON_DISABLED_MESSAGE}
          className="select-button-tooltip-disabled"
          bgColor="white"
          hideTooltip={!actionButton.disabled}
        >
          <Button {...actionButton} />
        </Tooltip>
      ))}
    </div>
  </div>
);

export type ToastOptions = ToastProps & ReactToastifyToastOptions;
export const ToastTypes = toast.TYPE;

const toastFunctionByType = {
  [toast.TYPE.ERROR]: toast.error,
  [toast.TYPE.DEFAULT]: toast.info,
  [toast.TYPE.INFO]: toast.info,
  [toast.TYPE.SUCCESS]: toast.success,
  [toast.TYPE.WARNING]: toast.warning,
};

export function createToast({
  title,
  message,
  html,
  dismissText,
  vimConnectStyle = false,
  optumStyle = false,
  toastIcon,
  id = { uuid },
  actionButtons,
  onMouseEnter = () => {},
  ...toastifyOptions
}: ToastOptions) {
  if (toast.isActive(id) && !toastifyOptions.updateId) {
    clearById(id);
  }
  toastFunctionByType[toastifyOptions.type || toast.TYPE.DEFAULT]?.(
    <div onMouseEnter={onMouseEnter}>
      <Toast
        title={title}
        message={message}
        html={html}
        dismissText={dismissText}
        toastIcon={toastIcon}
        actionButtons={actionButtons}
        onDismissButtonClicked={toastifyOptions.onClose}
      />
    </div>,
    {
      className: classNames('atomic-toast', toastifyOptions.className, {
        vimConnectStyle,
        optumStyle,
      }),
      transition: Slide,
      closeButton: false,
      toastId: id,
      ...toastifyOptions,
    },
  );
}

const isDisplayed = (toastId) => toast.isActive(toastId);

const clearAll = () => {
  toast.dismiss();
};

export { Zoom, Slide, ToastContainer, clearById, clearAll, isDisplayed };
