import React from 'react';
import { getWriteBackLoader } from '../../molecules/writeBackLoader';
import './index.less';

export type WriteBackOverlayProps = {
  showLoadingWidget: boolean;
  loadingWidgetId?: string;
};

export const WriteBackOverlay: React.FC<WriteBackOverlayProps> = ({
  showLoadingWidget,
  loadingWidgetId,
}) => {
  const Loader = getWriteBackLoader(loadingWidgetId?.toString());
  return (
    <>
      {showLoadingWidget ? <Loader /> : null}
      <div className="overlay" />
    </>
  );
};
