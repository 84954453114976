import React, { useEffect } from 'react';
import {
  ThemeContext,
  themes,
  ThemeVariablesWrapper,
  VimThemesKeyValue,
} from '@getvim/components-hooks-use-theme';
import { TranslateProvider } from '@getvim/translate';
import { sendDataToApp } from '@getvim/utils-vim-connect-communication';
import { useAnalytics } from '@getvim/vim-app-infra';
import { Infra } from '@getvim/vim-connect';
import { WidgetOutgoingEvent } from '@getvim/vim-connect-app';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { verticalMovementToPosition } from '../app/utils/positioningUtils';
import { AppsContainer } from './components/AppsContainer';
import { AppsMenu } from './components/AppsMenu';
import { ToastContainer } from './components/toasts/ToastContainer';
import { ActiveProvider, useActive } from './useActive';
import { ApplicationsProvider, useSelectedApplicationId } from './useApplications';
import { EventsProvider } from './useEvents';
import { SystemDataProvider, useSystemData } from './useSystemData';
import { InvalidProvider } from './useInvalid';
import { PatientProvider } from './usePatient';
import { useWidgetTransparency, WidgetTransparencyProvider } from './useWidgetTransparency';
import { MeProvider } from './useMe';
import { OrganizationProvider, useOrganization } from './useOrganization';
import { VimConnectUiAnalyticsEventTypes } from '../app/analytics/analytics';
import { WriteBackOverlay } from './components/WriteBackOverlay';

import './index.less';

const isDesktop = window.navigator?.userAgent?.includes('Electron') ?? false;

const VimConnectUIFullScreen: React.FC = () => {
  const { analyticsClient } = useAnalytics();
  const { initialData, hubPosition, setHubPosition } = useSystemData();
  const [active] = useActive();

  const [selectedAppId] = useSelectedApplicationId();

  if (!isDesktop) {
    // IMPORTANT hooks should not be called conditionally
    // but this condition doesn't change in given environment so either it will always
    // be called on this context or not, so this condition is not problematic for the hook
    // DO NOT ADD OTHER conditions unless carefully made sure they will behave the same way
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useWidgetTransparency();
  }

  useEffect(() => {
    if (initialData) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubDisplayed,
      });
    }
  }, [initialData]);

  const onMoveAppMenuClick = (direction: Infra.Common.Types.HubDirection) => {
    const newPosition = { ...hubPosition };
    if (direction.vertical) {
      newPosition.vertical = verticalMovementToPosition(hubPosition.vertical!, direction.vertical);
    }
    if (direction.horizontal) {
      newPosition.horizontal =
        direction.horizontal === Infra.Common.Types.HorizontalDirection.Right
          ? Infra.Common.Types.HorizontalHubPosition.Right
          : Infra.Common.Types.HorizontalHubPosition.Left;
    }

    sendDataToApp({ event: WidgetOutgoingEvent.HubWidgetPositionChanged, data: newPosition });
    setHubPosition(newPosition);

    if (
      hubPosition.vertical !== newPosition.vertical ||
      hubPosition.horizontal !== newPosition.horizontal
    ) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubPositionChange,
        properties: {
          hub_vertical_direction: direction.vertical
            ? Infra.Common.Types.VerticalDirection[direction.vertical]
            : undefined,
          hub_horizontal_direction: direction.horizontal
            ? Infra.Common.Types.HorizontalDirection[direction.horizontal]
            : undefined,
          hub_new_vertical_position: Infra.Common.Types.VerticalHubPosition[newPosition.vertical!],
          hub_new_horizontal_position:
            Infra.Common.Types.HorizontalHubPosition[newPosition.horizontal!],
          hub_old_vertical_position: Infra.Common.Types.VerticalHubPosition[hubPosition.vertical!],
          hub_old_horizontal_position:
            Infra.Common.Types.HorizontalHubPosition[hubPosition.horizontal!],
          hub_activation_status: active,
        },
      });
    }
  };

  return (
    <>
      <div
        className={classNames(
          'main-container',
          hubPosition.horizontal?.toLowerCase(),
          hubPosition.vertical?.toLowerCase(),
          { displayed: !isEmpty(initialData), selected: !isEmpty(selectedAppId) },
        ).trim()}
      >
        <AppsMenu moveAppMenu={onMoveAppMenuClick} />
        <AppsContainer />
      </div>
    </>
  );
};

const ThemeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { organization } = useOrganization();
  const theme = themes[organization?.theme as keyof VimThemesKeyValue] || themes.vimConnect;
  ThemeContext(theme);

  return (
    <ThemeVariablesWrapper theme={theme} className="vim-connect-ui-fullscreen-app">
      {children}
    </ThemeVariablesWrapper>
  );
};

const VimConnectUIFullScreenWrapper = () => {
  return (
    <WidgetTransparencyProvider>
      <EventsProvider>
        <WriteBackOverlay />
        <MeProvider>
          <OrganizationProvider>
            <ThemeProvider>
              <TranslateProvider locale="en" rootTranslations={{} as any}>
                <SystemDataProvider>
                  <ActiveProvider>
                    <InvalidProvider>
                      <PatientProvider>
                        <ApplicationsProvider>
                          <VimConnectUIFullScreen />
                          <ToastContainer />
                        </ApplicationsProvider>
                      </PatientProvider>
                    </InvalidProvider>
                  </ActiveProvider>
                </SystemDataProvider>
              </TranslateProvider>
            </ThemeProvider>
          </OrganizationProvider>
        </MeProvider>
      </EventsProvider>
    </WidgetTransparencyProvider>
  );
};

export default VimConnectUIFullScreenWrapper;
