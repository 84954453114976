import { createContext, useContext, useState, useCallback } from 'react';
import { Infra } from '@getvim/vim-connect';

interface OrganizationContextData {
  organization: Infra.Common.Types.MeOrganization | undefined;
  updateOrganization: (updateOrganization: Partial<Infra.Common.Types.MeOrganization>) => void;
}

const OrganizationContext = createContext<OrganizationContextData>({} as OrganizationContextData);

export const OrganizationProvider = ({ children }) => {
  const context = useOrganizationContextProvider();

  const { Provider } = OrganizationContext;
  return <Provider value={context}>{children}</Provider>;
};

export const useOrganization = (): OrganizationContextData => useContext(OrganizationContext);

const useOrganizationContextProvider = (): OrganizationContextData => {
  const [organization, setOrganization] = useState<Infra.Common.Types.MeOrganization | undefined>();

  const updateOrganization = useCallback(
    (updateOrganization: Partial<Infra.Common.Types.MeOrganization>) =>
      setOrganization({
        ...organization,
        ...updateOrganization,
      } as Infra.Common.Types.MeOrganization),
    [organization],
  );

  return { organization, updateOrganization };
};
