import { Infra, Standard } from '@getvim/vim-connect';
import { Common } from '@getvim/vim-connect';
import { isEmpty } from 'lodash-es';
import { productToOrgConfigProduct } from '../components/app/analytics/mapping';
import { Product } from '../components/app/types/analytics';
import { Application } from '../components/app/types/application';

export const getAppsActivationStatus = (
  organization: Infra.Common.Types.MeOrganization,
  applications: Record<string, Application>,
  patient: Standard.Events.TransformedPatientInContextPayload,
) => {
  if (isEmpty(organization)) return;
  if (isEmpty(organization.config)) return;

  const {
    config: { applications: orgApplications },
  } = organization;

  if (isEmpty(applications) || isEmpty(orgApplications)) return;

  return Object.entries(applications).reduce<any>((prev, [appName, { disabled }]) => {
    const { sources } = orgApplications[productToOrgConfigProduct.get(appName as Product)!] || {};
    prev[appName] = { active: !disabled, source: Common.getActiveSource(patient, sources) };
    return prev;
  }, {});
};
