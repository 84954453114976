import { Team } from '@getvim/feature-flags';
import { featureFlagsClient } from '../clients/feature-flags/feature-flags.client';

export const shouldShowManagementApp = async (orgId?: number): Promise<boolean> => {
  if (!orgId) {
    return false;
  }
  return await featureFlagsClient.getFlag({
    flagName: 'should_show_user_management_app',
    defaultValue: false,
    team: Team.Onboarding,
    flagContext: { organizationId_string: orgId.toString() },
  });
};
