import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './index.less';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { SvgRedDot } from '../svgs';

type ThemeAppLogoProps = {
  className?: string;
  loading?: boolean;
  inactive?: boolean;
  invalid?: boolean;
};

const ThemeAppLogo: FunctionComponent<ThemeAppLogoProps> = ({
  className,
  loading,
  inactive,
  invalid,
}) => {
  const theme = useTheme();
  return (
    <div className="theme-app-logo-container">
      <div
        className={classNames(
          'theme-app-logo',
          className,
          { loading },
          inactive || invalid ? 'inactive' : loading ? 'loading' : 'active',
          theme?.className,
        )}
        aria-label={theme?.fullName}
      />
      {invalid && (
        <div className="theme-app-logo-invalid">
          <SvgRedDot className="theme-app-logo-svg" />
        </div>
      )}
    </div>
  );
};

export default ThemeAppLogo;
