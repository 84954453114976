import React from 'react';
import { Toast } from '@getvim/atomic-ui';
import { Notifications } from '@getvim/vim-connect-communication';
import { logger } from '@getvim/vim-connect-logger';
import { getApplicationIcon } from '../../../../app/icons/ApplicationIconsMapping';
import {
  VimHubNotificationBlockedReason,
  VimHubNotificationsEventTypes,
} from '../../../../notifications/analytics/analytics';
import { ActionMethod } from '../../../../notifications/notification/types';
import {
  doesProviderReachedNotificationLimit,
  storeNotificationDetails,
} from '../../../../notifications/notifications-details';
import './index.less';
import { sendMessageToWidget } from '../../../communication';
import { ShowHubNotification } from './types';

const { Zoom, createToast, clearById } = Toast;

const DEFAULT_NOTIFICATION_TIMEOUT = 8000;
export const DEFAULT_TOAST_ID = 'vim-connect-ui-notifications-toast';

const notificationsWidgetLogger = logger.scope('Notifications Widget');

export const show = async (
  data: ShowHubNotification,
  vimCommunication,
  analyticsClient,
  onClick,
) => {
  try {
    const {
      notificationId,
      applicationName,
      applicationId,
      text,
      options,
      actionButtons,
      title,
      dismissText,
      toastId = DEFAULT_TOAST_ID,
    } = data;

    const { timeoutInMs, vimConnectStyle, optumStyle } = options || {};
    const analyticsProperties = { widgetId: applicationId, notificationId };

    if (await doesProviderReachedNotificationLimit(notificationId)) {
      analyticsClient?.track({
        event: VimHubNotificationsEventTypes.VimHubNotificationBlocked,
        properties: {
          ...analyticsProperties,
          reason: VimHubNotificationBlockedReason.ReachedLimitation,
        },
      });

      return;
    }

    await storeNotificationDetails(notificationId);

    const ApplicationIcon = getApplicationIcon(applicationId, { disabled: false });

    let closeIconClicked: boolean | undefined;
    let openClicked: boolean | undefined;

    createToast({
      actionButtons: actionButtons?.map((actionButton) => {
        const { action, ...restActionButton } = actionButton;
        return {
          ...restActionButton,
          onClick: (e: MouseEvent) => {
            e.stopPropagation();

            analyticsClient?.track({
              event: VimHubNotificationsEventTypes.VimHubNotificationCtaClicked,
              properties: {
                ...analyticsProperties,
                action,
              },
            });

            vimCommunication?.sendEvent(
              applicationId,
              Notifications.Types.NotificationToVimConnectUIEvent.OnActionButtonClick,
              { ...analyticsProperties, action },
            );
            sendMessageToWidget(applicationId, {
              payload: { ...analyticsProperties, action, widgetId: applicationId },
              type: Notifications.Types.NotificationToVimConnectUIEvent.OnActionButtonClick,
            });
            clearById(toastId);
          },
        };
      }) as any,
      title: title ?? applicationName,
      message: text,
      autoClose: timeoutInMs ?? DEFAULT_NOTIFICATION_TIMEOUT,
      vimConnectStyle: vimConnectStyle ?? true,
      updateId: notificationId,
      optumStyle: optumStyle ?? false,
      toastIcon: <ApplicationIcon className="icon" />,
      html: true,
      id: toastId,
      closeButton: ({ closeToast }) => (
        <i
          className="material-icons icon-x x-icon"
          onClick={(e) => {
            e.stopPropagation();
            closeIconClicked = true;
            openClicked = undefined;
            closeToast();
          }}
        />
      ),
      draggable: false,
      transition: Zoom,
      dismissText,
      onOpen: () => {
        analyticsClient?.track({
          event: VimHubNotificationsEventTypes.VimHubNotificationDisplay,
          properties: analyticsProperties,
        });
      },
      onClose: () => {
        if (!openClicked) {
          analyticsClient?.track({
            event: VimHubNotificationsEventTypes.VimHubNotificationClosed,
            properties: {
              ...analyticsProperties,
              method: closeIconClicked ? ActionMethod.MANUAL : ActionMethod.AUTOMATIC,
            },
          });
        }
        closeIconClicked = undefined;
        openClicked = undefined;
      },
      onMouseEnter: () => {
        analyticsClient?.track({
          event: VimHubNotificationsEventTypes.VimHubNotificationHovered,
          properties: analyticsProperties,
        });
      },
      onClick: () => {
        analyticsClient?.track({
          event: VimHubNotificationsEventTypes.VimHubNotificationClicked,
          properties: analyticsProperties,
        });
        closeIconClicked = undefined;
        openClicked = true;
        onClick();
      },
    });
  } catch (error) {
    notificationsWidgetLogger.warning('error occurred when trying to show notifications widget', {
      error,
    });
  }
};
