import React, { useCallback } from 'react';
import { sendEventToApp } from '@getvim/utils-vim-connect-communication';
import { Infra } from '@getvim/vim-connect';
import { Application } from '../types/application';
import NotificationsIndicator from './NotificationsIndicator';
import { APP_BUTTON_EDGE_LENGTH_PIXELS, getBoundingClientRect } from '../utils/positioningUtils';
import './ApplicationIcon.less';
import { WidgetOutgoingEvent } from '@getvim/vim-connect-app';

interface ApplicationIconProps {
  application: Application;
  onClick: () => void;
  selected: boolean;
  hubPosition: Infra.Common.Types.HubPosition;
  isWidgetOpen: boolean;
  customSize?: { height: string; width: string };
}

const ApplicationIcon: React.FC<ApplicationIconProps> = ({
  application,
  onClick,
  selected,
  hubPosition,
  isWidgetOpen,
  customSize,
}) => {
  const handleClick = useCallback(() => {
    if (application.disabled) return;
    onClick();
  }, [application, onClick]);

  const getIconFillColor = useCallback(() => {
    if (selected) return 'var(--app-icon-selected-color)';
    if (application.loading) return 'var(--app-icon-color)';
    if (application.disabled) return 'var(--app-icon-disabled-color)';
    return 'var(--app-icon-color)';
  }, [application, selected]);

  const displayTooltip = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const appIconBoundingClientRect = getBoundingClientRect(e.target as HTMLDivElement);
      const hubBoundingClientRect = getBoundingClientRect(
        document.getElementById('root') as HTMLDivElement,
      );
      const { top, bottom, right } = appIconBoundingClientRect;
      const { bottom: hubBottom } = hubBoundingClientRect;

      sendEventToApp(WidgetOutgoingEvent.DisplayTooltip, {
        vimConnectUiInfo: {
          hubPosition,
          boundingClientRect: { top, bottom, right, hubBottom },
          isOpen: isWidgetOpen,
          customSize,
        },
        text: application.tooltip,
      });
    },
    [application, hubPosition, isWidgetOpen, customSize],
  );

  const hideTooltip = useCallback(() => {
    sendEventToApp(WidgetOutgoingEvent.HideTooltip, undefined);
  }, []);

  return (
    <div
      role="none"
      className={`sub-icon-container ${selected ? 'sub-icon-container-selected' : ''} ${
        application.disabled ? 'disabled' : ''
      }`}
      onClick={handleClick}
      onMouseEnter={displayTooltip}
      onMouseLeave={hideTooltip}
      style={{ height: `${APP_BUTTON_EDGE_LENGTH_PIXELS}px` }}
    >
      {application.notifications && application.notifications > 0 ? (
        <NotificationsIndicator
          ignoreValue={!!application.ignoreNotificationsValue}
          value={application.notifications}
        />
      ) : null}
      <application.iconSource
        className={`icon ${!selected && application.loading ? 'loading' : ''}`}
        fill={getIconFillColor()}
      />
    </div>
  );
};

export default ApplicationIcon;
