export enum VimHubNotificationsEventTypes {
  VimHubNotificationDisplay = 'vim_hub_notification_displayed',
  VimHubNotificationHide = 'vim_hub_notification_hide',
  VimHubNotificationHovered = 'vim_hub_notification_hovered',
  VimHubNotificationClicked = 'vim_hub_notification_clicked',
  VimHubNotificationClosed = 'vim_hub_notification_closed',
  VimHubNotificationBlocked = 'vim_hub_notification_blocked',
  VimHubNotificationCtaClicked = 'vim_hub_notification_cta_clicked',
}

export enum VimHubNotificationBlockedReason {
  ReachedLimitation = 'reached_limitation',
  HubWasOpened = 'hub_was_opened',
  AppWasDisabled = 'app_was_disabled',
}

export enum VimHubNotificationHidedReason {
  HideByApp = 'hide_by_app',
}
