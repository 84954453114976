import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';
import { Entities } from '@getvim/vim-connect';
import {
  Feature,
  ClickAction,
  ElementInteraction,
  WidgetOpenAction,
  WidgetCloseReason,
} from '../types/analytics';
import { Insurer } from '../types/patient';

export enum VimConnectUiAnalyticsEventTypes {
  VimChartBecomeActive = 'vim_chart_become_active',
  VimChartAutomaticOpenClick = 'vim_chart_automatic_open_click',
  VimChartCategoryClick = 'vim_chart_category_click',
  VimChartClosed = 'vim_chart_closed',
  VimHubDisplayed = 'vim_hub_displayed',
  VimHubBecameActiveWithApp = 'vim_hub_became_active_with_app',
  VimAppLoaded = 'vim_app_loaded',
  VimHubLoaded = 'vim_hub_loaded',
  VimHubPositionChange = 'vim_hub_position_change',
  VimHubButtonInteraction = 'vim_hub_button_interaction',
  VimHubAppOpened = 'vim_hub_app_opened',
  VimAppOpened = 'vim_app_opened',
  VimHubAppClosed = 'vim_hub_app_closed',
  VimAppClosed = 'vim_app_closed',
  VimHubClosed = 'vim_hub_closed',
  VimHubOpened = 'vim_hub_opened',
  VimHubAppTransition = 'vim_hub_app_transition',
  VimHubPatientDetected = 'vim_hub_patient_detected',
  PopupErrorDisplayed = 'popup_error_displayed',
  VimAppPopupBlocked = 'vim_app_popup_blocked',
}

type VimConnectUiAnalyticsEvents = {
  [VimConnectUiAnalyticsEventTypes.VimChartBecomeActive]: { patient_insurer?: Insurer };
  [VimConnectUiAnalyticsEventTypes.VimChartAutomaticOpenClick]: { widgetId: string };
  [VimConnectUiAnalyticsEventTypes.VimChartCategoryClick]: {
    feature: Feature;
    action: ClickAction;
    type: Entities.UIElements.ExpandingType;
  };
  [VimConnectUiAnalyticsEventTypes.VimChartClosed]: { type: Entities.UIElements.ExpandingType };
  [VimConnectUiAnalyticsEventTypes.VimHubBecameActiveWithApp]: {
    widgetId: string;
    items_loaded: number;
    patient_insurer?: Insurer;
  };
  [VimConnectUiAnalyticsEventTypes.VimHubPositionChange]: {
    direction: string;
    oldPosition: string;
    newPosition: string;
  };
  [VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction]: {
    interaction: ElementInteraction;
  };
  [VimConnectUiAnalyticsEventTypes.VimHubAppOpened]: {
    widgetId: string;
    action: WidgetOpenAction;
  };
  [VimConnectUiAnalyticsEventTypes.PopupErrorDisplayed]: {
    error: string;
  };
  [VimConnectUiAnalyticsEventTypes.VimHubAppClosed]: {
    reason: WidgetCloseReason;
    widgetId: string;
  };
  [VimConnectUiAnalyticsEventTypes.VimHubClosed]: {
    reason: WidgetCloseReason;
    widgetId: string;
  };
};

export const analyticsClient = new AnalyticsClient<VimConnectUiAnalyticsEvents>();
