import gql from 'graphql-tag';
import { gqlClient, NoAccessTokenError } from '../../gql-client';
import { logger } from '@getvim/vim-connect-logger';

const contentSupplierIdentifiersLogger = logger.scope('getContentSupplierIdentifiers query');

export interface ContentSupplierIdentifiersInput {
  insurerSources: string[];
  providerSources: string[];
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  memberId?: string;
  patientId?: string;
  mrn?: string;
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  metadata?: { npi?: string };
}

export interface ContentSupplierIdentifiersResult {
  source: string;
  contentSupplierVimInternalPersonId: string;
  isInsurer: boolean;
  memberToken: string;
}

export const getContentSupplierIdentifiers = async (
  input: ContentSupplierIdentifiersInput,
): Promise<ContentSupplierIdentifiersResult[] | undefined> => {
  try {
    const response = await gqlClient.queryAuthenticated<
      { getContentSupplierIdentifiers: ContentSupplierIdentifiersResult[] },
      { input: ContentSupplierIdentifiersInput }
    >({
      query: gql`
        query getContentSupplierIdentifiers($input: GetContentSupplierIdentifiersInput!) {
          getContentSupplierIdentifiers(input: $input) {
            source
            contentSupplierVimInternalPersonId
            memberToken
            isInsurer
          }
        }
      `,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: { uri: '/runtime/api/graphql' },
    });

    return response.data.getContentSupplierIdentifiers;
  } catch (error) {
    if (error instanceof NoAccessTokenError) {
      contentSupplierIdentifiersLogger.info(
        'getContentSupplierIdentifiers called when user not logged in ',
        {
          input,
          error,
        },
      );
    } else {
      contentSupplierIdentifiersLogger.warning(
        'an error has occurred while trying to execute getContentSupplierIdentifiers query',
        {
          input,
          error,
        },
      );
    }

    return;
  }
};
