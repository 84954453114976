import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';
import { isUndefined } from 'lodash-es';
import { getAccessToken } from '@getvim/vim-app-infra';
import { UPDATE_ME } from './queries';
import { Common } from '@getvim/vim-connect';

export class RuntimeApi {
  private gqlClient: VimConnectGqlClient | undefined;

  private getClient(): VimConnectGqlClient {
    if (isUndefined(this.gqlClient)) {
      this.gqlClient = new VimConnectGqlClient({
        apiUrl: this.getApiUrl(),
        accessTokenCallback: getAccessToken,
      });
    }

    return this.gqlClient;
  }

  private getApiUrl() {
    return '/api/graphql';
  }

  public async updateMe(input: Common.UpdateMe) {
    const gqlClient = this.getClient();

    return gqlClient.query({
      query: UPDATE_ME as any,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }
}
