import React from 'react';
import classNames from 'classnames';
import Text from '../text/index';
import footerLogoLight from '../../../assets/images/logos/vimLogoLight.svg';
import './index.less';

interface CollapsingVimFooterProps {
  className?: string;
}

function CollapsingVimFooter({ className }: CollapsingVimFooterProps) {
  return (
    <>
      <footer className={classNames('collapsing-vim-footer', className)}>
        <div className="collapsing-footer-left" />
        <div className="collapsing-footer-content">
          <a href="https://getvim.com/" target="_blank" rel="noreferrer">
            <img src={footerLogoLight} height="11" width="auto" alt="vim logo" />
          </a>
          <Text className="margin-left-10" inline>
            <a
              href="https://getvim.com/legal/provider-master-terms-of-service/"
              target="_blank"
              rel="noreferrer"
              className="text-underline"
            >
              Terms of Service
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://getvim.com/legal/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              className="text-underline"
            >
              Privacy Policy
            </a>
          </Text>
        </div>
        <div className="collapsing-footer-right" />
      </footer>
    </>
  );
}

export default CollapsingVimFooter;
