import { ServiceNames } from '@getvim/vim-connect-communication';
import { ActionButton } from '@getvim/utils-vim-connect-communication';
import { RuntimeContract } from '@getvim/vim-connect';

export interface Options {
  timeoutInMs?: number;
  vimConnectStyle?: boolean;
  optumStyle?: boolean;
}

export interface NotificationProps {
  text: string;
  widgetName: string;
  dismissText?: string;
  title?: string;
  notificationId: string;
  widgetId: ServiceNames;
  actionButtons: ActionButton[];
  options: Options;
  modifyWidgetParams: Partial<RuntimeContract.Incoming.ModifyWidgetParams>;
  method?: ActionMethod;
}

export enum ActionMethod {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}
