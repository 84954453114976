import { useAnalytics, useVimCommunication } from '@getvim/vim-app-infra';
import { Entities } from '@getvim/vim-connect';
import { useSelectedApplicationId } from '../../../useApplications';
import './index.less';
import { show } from './showNotification';
import { ShowHubNotification } from './types';

export const useNotifications = () => {
  const { vimCommunication } = useVimCommunication();
  const { analyticsClient } = useAnalytics();
  const [_, selectApp] = useSelectedApplicationId();

  const showNotification = async (data: ShowHubNotification) =>
    show(data, vimCommunication, analyticsClient, () => {
      selectApp(data.applicationId, Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL);
    });

  return showNotification;
};
