import '@getvim/atomic-ui/assets/styles/main.less';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ModuleNames, VimCommunicationProvider } from '@getvim/vim-app-infra';
import VimConnectUIFullScreen from './components/fullscreen-transparent';
import VimConnectUI from './components/app';
import VimConnectUITooltip from './components/tooltip';
import VimConnectUIToast from './components/toast';
import VimConnectUINotifications from './components/notifications';

const App: React.FC = () => (
  <Router basename={import.meta.env.BASE_URL}>
    <Switch>
      <Route path="/tooltip">
        <VimConnectUITooltip />
      </Route>
      <Route path="/toast">
        <VimConnectUIToast />
      </Route>
      <Route path="/notifications">
        <VimCommunicationProvider
          widgetId={ModuleNames.VimConnectUINotificationsWidget}
          parentAppId={ModuleNames.VimConnectUIHubWidget}
        >
          <VimConnectUINotifications />
        </VimCommunicationProvider>
      </Route>
      <Route path="/app">
        <VimCommunicationProvider
          widgetId={ModuleNames.VimConnectUIHubWidget}
          communicationModules={[ModuleNames.OrderAssistWidget]}
        >
          <VimConnectUI />
        </VimCommunicationProvider>
      </Route>
      <Route path="/full-transparent">
        <VimCommunicationProvider
          widgetId={ModuleNames.VimConnectUIHubWidget}
          communicationModules={[ModuleNames.OrderAssistWidget]}
        >
          <VimConnectUIFullScreen />
        </VimCommunicationProvider>
      </Route>
    </Switch>
  </Router>
);
export default App;
