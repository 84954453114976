import { useAnalytics } from '@getvim/vim-app-infra';
import React from 'react';
import {
  AppsMenu as AppsMenuUI,
  AppsMenuProps as AppsMenuUIProps,
} from '../../../atomic/organisms';
import { VimConnectUiAnalyticsEventTypes } from '../../app/analytics/analytics';
import { ElementInteraction } from '../../app/types/analytics';
import { useActive } from '../useActive';
import { useApplications, useSelectedApplicationId } from '../useApplications';
import { HubInternalEvents, useInternalHubEvents } from '../useEvents';
import { useSystemData } from '../useSystemData';
import { useHubInvalid } from '../useInvalid';
import { useOrganization } from '../useOrganization';

type AppsMenuProps = Pick<AppsMenuUIProps, 'moveAppMenu'>;

export const AppsMenu: React.FC<AppsMenuProps> = ({ moveAppMenu }) => {
  const events = useInternalHubEvents();
  const { analyticsClient } = useAnalytics();
  const applications = useApplications();
  const { hubPosition } = useSystemData();
  const { organization } = useOrganization();
  const [selectedAppId, selectApp] = useSelectedApplicationId();
  const [active] = useActive();
  const [isInvalid] = useHubInvalid();
  const { userManagement } = useSystemData();

  const onMouseEnter = (mouseEventData) => {
    if (isInvalid) {
      events[HubInternalEvents.HubMouseEnterInInvalidState].next(mouseEventData);
    }
  };

  const onMenuShrinkChange = (isShrinked) => {
    analyticsClient.track({
      event: VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction,
      properties: {
        interaction: isShrinked ? ElementInteraction.Collapse : ElementInteraction.Expand,
      },
    });
  };

  const onHubIconHover = () => {
    analyticsClient.track({
      event: VimConnectUiAnalyticsEventTypes.VimHubButtonInteraction,
      properties: {
        interaction: ElementInteraction.Hover,
      },
    });
  };

  return (
    <div onMouseEnter={onMouseEnter}>
      <AppsMenuUI
        applications={applications}
        userManagement={userManagement}
        selectedAppId={selectedAppId}
        selectedApp={selectedAppId ? applications[selectedAppId] : undefined}
        hubPosition={hubPosition}
        active={active}
        invalid={isInvalid}
        moveAppMenu={moveAppMenu}
        onApplicationClick={(id, expandingType) => selectApp(id, expandingType)}
        onMenuShrinkChange={onMenuShrinkChange}
        onHubIconHover={onHubIconHover}
        onClose={() => selectApp(undefined)}
        organizationId={organization?.id}
      />
    </div>
  );
};
