import { ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import classNames from 'classnames';

import './index.less';

interface MissingInformationContainerProps {
  title: string;
  children: JSX.Element;
}

export const MissingInformationContainer = ({
  title,
  children,
}: MissingInformationContainerProps) => {
  return (
    <div className={classNames('missing-information-container')}>
      <Text
        text={title}
        size={SizeEnum['14px']}
        colorName={ColorNameEnum.extraDark}
        weight={WeightEnum.semibold}
      />
      {children}
    </div>
  );
};
