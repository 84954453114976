import { createContext, useContext, useState, useCallback } from 'react';
import { Infra } from '@getvim/vim-connect';

interface MeContextData {
  me: Infra.Common.Types.MeEhrUser | undefined;
  updateMe: (updatedMe: Partial<Infra.Common.Types.MeEhrUser>) => void;
}

const MeContext = createContext<MeContextData>({} as MeContextData);

export const MeProvider = ({ children }) => {
  const context = useMeContextProvider();

  const { Provider } = MeContext;
  return <Provider value={context}>{children}</Provider>;
};

export const useUser = (): MeContextData => useContext(MeContext);

const useMeContextProvider = (): MeContextData => {
  const [me, setMe] = useState<Infra.Common.Types.MeEhrUser | undefined>();

  const updateMe = useCallback(
    (updatedMe: Partial<Infra.Common.Types.MeEhrUser>) =>
      setMe({ ...me, ...updatedMe } as Infra.Common.Types.MeEhrUser),
    [me],
  );

  return { me, updateMe };
};
