import { AnalyticsMemberProperties, InformationType, Product } from '../types/analytics';
import { Application } from '../types/application';
import { productToInformationType } from './mapping';
import { analyticsClient } from './analytics';

const EMPTY_PROPERTIES: AnalyticsMemberProperties = {
  information_types: [],
  care_gaps_count: 0,
  diagnosis_gaps_count: 0,
  health_history_count: 0,
};

class MemberProperties {
  private properties: AnalyticsMemberProperties = EMPTY_PROPERTIES;

  public updateMemberProperties(applications: Record<Application['id'], Application>) {
    const applicationsArray = Object.values(applications);
    const informationTypes: InformationType[] = applicationsArray.map(
      ({ id }) => productToInformationType[id as Product],
    );
    this.properties = {
      information_types: informationTypes,
      care_gaps_count: applications['quality-gaps']?.notifications ?? 0,
      diagnosis_gaps_count: applications['diagnosis-gaps']?.notifications ?? 0,
      health_history_count: applications['health-history']?.notifications ?? 0,
    };

    analyticsClient.setDynamicWidgetProperties<AnalyticsMemberProperties>(this.properties);
  }

  public resetMemberProperties() {
    this.properties = EMPTY_PROPERTIES;
    analyticsClient.setDynamicWidgetProperties<AnalyticsMemberProperties>(this.properties);
  }
}

export const memberProperties = new MemberProperties();
