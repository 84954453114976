import { SVGIconProps } from '@getvim/vim-icons';
import { getApplicationIcon } from '../icons/ApplicationIconsMapping';

export const USER_MANAGEMENT_APPLICATION_ID = 'user-management';
const USER_MANAGEMENT_TOOLTIP = 'User Management';
const USER_MANAGEMENT_DEFAULT_ICON_COLOR = 'var(--management-icon-color)';

export const DEFAULT_USER_MANAGEMENT: UserManagement = {
  id: USER_MANAGEMENT_APPLICATION_ID,
  tooltip: USER_MANAGEMENT_TOOLTIP,
  iconSource: getApplicationIcon(USER_MANAGEMENT_APPLICATION_ID, {}),
  whiteBackground: true,
  defaultIconColor: USER_MANAGEMENT_DEFAULT_ICON_COLOR,
};

export type UserManagement = {
  id: string;
  tooltip: string;
  iconSource: React.FC<SVGIconProps>;
  notifications?: number;
  whiteBackground: boolean;
  defaultIconColor?: string;
};
export type UserManagementInput = Partial<Omit<UserManagement, 'iconSource' | 'id'>>;
