import { Button, MultiSelectWithOther, ButtonProps } from '@getvim/atomic-ui';
import './index.less';
import classNames from 'classnames';

interface UpdateRoleFormProps {
  options: string[];
  defaultValues: string[];
  onChange: (values: string[]) => void;
  onSubmit: () => void;
  buttonColor?: ButtonProps['bgColor'];
}

export const UpdateRoleForm = ({
  options,
  defaultValues,
  onChange,
  onSubmit,
  buttonColor = 'default',
}: UpdateRoleFormProps) => {
  return (
    <div className={classNames('update-role-form')}>
      <MultiSelectWithOther
        title="Role"
        defaultValues={defaultValues}
        options={options}
        width="100%"
        onChange={onChange}
        emptyOtherText="Start typing to add another role"
        height="36px"
      />
      <Button
        text="Submit"
        buttonType="small"
        bgColor={buttonColor}
        width="small"
        onClick={onSubmit}
        style={{ marginLeft: '-0.25rem', zIndex: 0 }}
      />
    </div>
  );
};
