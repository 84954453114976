import { logger as LoggerImport } from '@getvim/vim-connect-logger';

const logger = LoggerImport.scope('Poller/poller.ts');

export interface Poller {
  start: () => void;
  stop: () => void;
  isRunning: boolean;
}

export function createPoller(periodicPromise: () => Promise<any>, intervalInMs = 0): Poller {
  let isRunning = false;

  function execute() {
    if (isRunning) {
      setTimeout(() => {
        if (isRunning)
          periodicPromise()
            .catch((error) => {
              logger.warning('Got error while polling, continuing to poll', error);
            })
            .then(() => {
              execute();
            });
      }, intervalInMs);
    }
  }

  const start = () => {
    if (isRunning) {
      logger.warning(`Poller is already running`);
    } else {
      isRunning = true;
      execute();
    }
  };

  const stop = () => {
    isRunning = false;
  };

  return { start, stop, isRunning };
}
