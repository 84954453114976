import React from 'react';
import classNames from 'classnames';
import { ThemeAppLogo } from '@getvim/atomic-ui';
import { NotificationsIndicator } from '../../atoms/notificationsIndicator';
import './index.less';

interface ShrunkMenuProps {
  onMouseEnter?: () => void;
  notifications?: number;
  ignoreNotificationsValue?: boolean;
  loading?: boolean;
  inactive?: boolean;
  invalid?: boolean;
  shrink?: boolean;
}

export const HubMenuIcon = ({
  onMouseEnter,
  notifications = 0,
  ignoreNotificationsValue = false,
  loading,
  inactive,
  invalid,
  shrink,
}: ShrunkMenuProps) => {
  return (
    <div className={classNames('hub-menu-icon', { shrink })}>
      <div className={classNames('icon-container', 'normal-cursor')} onMouseEnter={onMouseEnter}>
        {notifications > 0 ? (
          <NotificationsIndicator value={notifications} ignoreValue={ignoreNotificationsValue} />
        ) : null}
        <ThemeAppLogo loading={loading} inactive={inactive} invalid={invalid} />
      </div>
    </div>
  );
};
