import { EventType, sendEventToApp } from '@getvim/utils-vim-connect-communication';
import { useAnalytics } from '@getvim/vim-app-infra';
import { Entities } from '@getvim/vim-connect';
import { WidgetIncomingEvent, WidgetOutgoingEvent } from '@getvim/vim-connect-app';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  getAppOpenedTime,
  getAppsActivationStatus,
  getAppsNotificationsCount,
  getAppSources,
  isAllAppsLoaded,
} from '../../common';
import { getPatientsDetails } from '../../patients-details/patients-details';
import { VimConnectUiAnalyticsEventTypes } from '../app/analytics/analytics';
import { getApplicationIcon, modifyApplicationIcon } from '../app/icons/ApplicationIconsMapping';
import { isOnSelectAppEnable } from '../app/is-on-select-app-enable';
import { Sessions, WidgetCloseReason } from '../app/types/analytics';
import { Application, ApplicationInput, ModifyReason } from '../app/types/application';
import { extractSenderWidgetId } from '../app/utils/extractSenderWidgetId';
import { sendMessageToWidget, VIM_CONNECT_HUB_APP_OPENED } from './communication';
import { useActive } from './useActive';
import { useSubscription } from './useEvents';
import { useSystemData } from './useSystemData';
import { usePatient } from './usePatient';
import { useOrganization } from './useOrganization';

const ApplicationsContext = createContext<Record<Application['id'], Application>>({});

type ChangeSelectedApp<T extends Application['id'] | undefined = Application['id'] | undefined> = {
  (
    ...args: T extends Application['id'] ? [T, Entities.UIElements.ExpandingType] : [undefined]
  ): void;
};

type OnApplicationUIMouseEnter = () => void;

type SelectedApplicationIdContextValue = [
  Application['id'] | undefined,
  ChangeSelectedApp<Application['id'] | undefined>,
  OnApplicationUIMouseEnter,
];

const SelectedApplicationIdContext = createContext<SelectedApplicationIdContextValue>(
  undefined as any,
);

export const ApplicationsProvider: React.FC = ({ children }) => {
  const { analyticsClient } = useAnalytics();
  const [patient] = usePatient();
  const { initialData, userManagement } = useSystemData();
  const { organization } = useOrganization();
  const [applications, setApplications] = useState<Record<Application['id'], Application>>({});
  const [selectedAppId, setSelectedAppId] = useState<Application['id'] | undefined>();
  const [allAppsLoaded, setAllAppsLoaded] = useState(false);
  const [mouseEnteredAppUI, setMouseEnteredAppUI] = useState(false);
  const [appOpenedTime, setAppOpenedTime] = useState(Date.now());
  const [active, setActive] = useActive();
  const appTransitionCount = useRef(0);

  const changeSelectedAppId: ChangeSelectedApp = (...args) => {
    const [newSelectedAppId] = args;
    const previousSelectedAppId = selectedAppId; // rename in the context of the func for better understanding
    const closedApp = previousSelectedAppId !== undefined && !newSelectedAppId;
    const openedAppFromClosedState = !previousSelectedAppId && newSelectedAppId !== undefined;
    const sameApp = newSelectedAppId === previousSelectedAppId;
    if (sameApp) {
      return;
    }

    setMouseEnteredAppUI(false);
    setAppOpenedTime(Date.now());

    if (
      (previousSelectedAppId && applications[previousSelectedAppId]) ||
      previousSelectedAppId === userManagement.id
    ) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimAppClosed,
        properties: {
          app_name: previousSelectedAppId,
          app_close_reason: newSelectedAppId
            ? WidgetCloseReason.AppSwitch
            : WidgetCloseReason.VimHubCloseClicked,
          app_opened_for_time: getAppOpenedTime(appOpenedTime),
          app_hovered: mouseEnteredAppUI,
        },
      });

      const appNotificationsCount =
        (newSelectedAppId && newSelectedAppId !== userManagement.id
          ? applications[newSelectedAppId]?.notifications
          : userManagement.notifications) || 0;

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubAppTransition,
        properties: {
          origin_app: selectedAppId || null /* we must pass null */,
          active_app: newSelectedAppId ?? null,
          app_transition_count: appTransitionCount.current,
          app_notifications_count: appNotificationsCount,
        },
      });
      appTransitionCount.current++;
    }
    const sessions: Partial<Sessions> = { vimAppSession: newSelectedAppId ? uuid() : null };

    if (
      newSelectedAppId &&
      (applications[newSelectedAppId] || newSelectedAppId === userManagement.id)
    ) {
      const expandingType = args[1];
      if (
        !isOnSelectAppEnable(
          previousSelectedAppId,
          newSelectedAppId === userManagement.id ? userManagement : applications[newSelectedAppId],
          expandingType,
          analyticsClient,
          patient?.vimPatientId,
          organization,
          !organization?.isPopupsEnabled,
          initialData?.products,
        )
      ) {
        return;
      }
      sendEventToApp(WidgetOutgoingEvent.StoreState, getPatientsDetails());
      sendMessageToWidget(Object.keys(applications), {
        type: VIM_CONNECT_HUB_APP_OPENED,
        payload: { selectedApplicationId: newSelectedAppId },
      });

      if (openedAppFromClosedState) sessions.vimHubSession = uuid();
      analyticsClient.setSession(sessions);

      if (openedAppFromClosedState) {
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimHubOpened,
          properties: { app_name: newSelectedAppId, app_open_method: expandingType },
        });
      }
      const appNotificationsCount =
        (newSelectedAppId && newSelectedAppId !== userManagement.id
          ? applications[newSelectedAppId]?.notifications
          : userManagement.notifications) || 0;

      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimAppOpened,
        properties: {
          app_name: newSelectedAppId,
          app_sources: getAppSources(newSelectedAppId, organization, initialData?.products),
          app_notifications_count: appNotificationsCount,
          app_open_method: expandingType,
        },
      });
    } else if (closedApp) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubClosed,
        properties: {
          hub_last_focused_app: previousSelectedAppId,
          hub_close_method: WidgetCloseReason.VimHubCloseClicked,
          app_opened_for_time: getAppOpenedTime(appOpenedTime),
          app_hovered: mouseEnteredAppUI,
        },
      });

      sessions.vimHubSession = null;
      analyticsClient.setSession(sessions);

      sendMessageToWidget(Object.keys(applications), {
        type: VIM_CONNECT_HUB_APP_OPENED,
        payload: { selectedApplicationId: null },
      });
    }

    setSelectedAppId(newSelectedAppId);
  };

  const modifyApplication = (id, applicationData: Partial<ApplicationInput>) => {
    setApplications((prevApplications) => {
      const newApplications = { ...prevApplications };
      if (!newApplications[id]) {
        console.error('Application not found!', id);
        return prevApplications;
      }

      if (applicationData?.disabled === true && selectedAppId === id) {
        changeSelectedAppId(undefined);
      }

      const activeBefore = Object.values(newApplications).some(
        (app) => !app.loading && !app.disabled,
      );

      newApplications[id] = {
        ...newApplications[id],
        ...applicationData,
        ...modifyApplicationIcon(id, applicationData),
      };

      const activeAfter = Object.values(newApplications).some(
        (app) => !app.loading && !app.disabled,
      );

      if (activeBefore !== activeAfter) {
        setActive(activeAfter);
      }

      const applicationHasValidData =
        applicationData.reason === ModifyReason.Response ||
        applicationData.reason === ModifyReason.PatientEligible;

      if (applicationHasValidData) {
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimAppLoaded,
          properties: {
            app_name: id,
            app_sources: getAppSources(id, organization, initialData?.products),
            app_activation_status: !applicationData.disabled,
            app_notifications_count: applicationData.notifications || 0,
            response_time: applicationData.responseTime || 0,
          },
        });
      }

      return newApplications;
    });
  };

  const dependencies = [analyticsClient, applications, selectedAppId, setActive];

  useSubscription(
    WidgetIncomingEvent.InjectApplication,
    ([injectedApp]) => {
      /**
       * This type convertion is bad... but to hard to fix the underlying problem..
       * The mapped type for InjectedApplication in IncomingEvents should be fixed
       */
      const applicationInput = injectedApp as ApplicationInput;
      setApplications((prevApplications) => {
        const newApplications = { ...prevApplications };

        if (newApplications[applicationInput.id]) {
          console.error('Application already exist!', applicationInput.id);
          return newApplications;
        }

        const application: Application = {
          ...applicationInput,
          iconSource: getApplicationIcon(applicationInput.id, applicationInput),
        };
        newApplications[applicationInput.id] = application;

        const activeBefore = Object.values(prevApplications).some(
          (app) => !app.loading && !app.disabled,
        );
        const activeAfter = Object.values(newApplications).some(
          (app) => !app.loading && !app.disabled,
        );

        if (activeBefore !== activeAfter) {
          setActive(activeAfter);
        }
        return newApplications;
      });
    },
    dependencies,
  );

  useSubscription(
    WidgetIncomingEvent.RemoveApplication,
    ([id]) => {
      setApplications((prevApplications) => {
        const newApplications = { ...prevApplications };
        delete newApplications[id];
        return newApplications;
      });
    },
    dependencies,
  );

  useSubscription(
    WidgetIncomingEvent.ModifyApplication,
    ([{ id, modifications }]) => {
      /**
       * This type conversion is bad... but to hard to fix the underlying problem..
       * The mapped type for InjectedApplication in IncomingEvents should be fixed
       */
      const applicationData = modifications as Partial<ApplicationInput>;

      modifyApplication(id, applicationData);
    },
    dependencies,
  );

  useSubscription(
    EventType.VCNewModifyApplication,
    ([data, browserEvent]) => {
      const senderWidgetId = extractSenderWidgetId(browserEvent);
      if (senderWidgetId) {
        modifyApplication(senderWidgetId, (data as any).data);
      }
    },
    dependencies,
  );

  useSubscription(
    WidgetIncomingEvent.SelectApplication,
    ([payload]) => {
      const { expandingType = Entities.UIElements.ExpandingType.VIM_APP_MANUAL, id } = payload;
      const { disabled } = applications[id];

      if (id === selectedAppId || disabled) return;

      changeSelectedAppId(id, expandingType);
    },
    dependencies,
  );

  useSubscription(
    WidgetIncomingEvent.PatientOutContext,
    () => {
      if (selectedAppId) {
        sendMessageToWidget(Object.keys(applications), {
          type: VIM_CONNECT_HUB_APP_OPENED,
          payload: { selectedApplicationId: null },
        });
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimAppClosed,
          properties: {
            app_name: selectedAppId,
            app_close_reason: WidgetCloseReason.PatientOutOfContext,
            app_opened_for_time: getAppOpenedTime(appOpenedTime),
            app_hovered: mouseEnteredAppUI,
          },
        });
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimHubClosed,
          properties: {
            hub_last_focused_app: selectedAppId,
            hub_close_method: WidgetCloseReason.PatientOutOfContext,
            app_opened_for_time: getAppOpenedTime(appOpenedTime),
            app_hovered: mouseEnteredAppUI,
          },
        });
        analyticsClient.track({
          event: VimConnectUiAnalyticsEventTypes.VimHubAppTransition,
          properties: {
            origin_app: selectedAppId,
            active_app: null,
            app_transition_count: appTransitionCount.current,
            app_notifications_count: 0,
          },
        });
        setSelectedAppId(undefined);
      }
      const sessions: Partial<Sessions> = {
        vimAppSession: null,
        vimHubSession: null,
        patientSession: null,
      };
      analyticsClient.setSession(sessions);
      appTransitionCount.current = 0;
      setMouseEnteredAppUI(false);
    },
    dependencies,
  );

  useSubscription(
    WidgetIncomingEvent.Logout,
    () => {
      setApplications({});
      setMouseEnteredAppUI(false);
      setSelectedAppId(undefined);
    },
    [],
  );
  useEffect(() => {
    setAllAppsLoaded(isAllAppsLoaded(applications));
  }, dependencies);

  useEffect(() => {
    if (allAppsLoaded) {
      analyticsClient.track({
        event: VimConnectUiAnalyticsEventTypes.VimHubLoaded,
        properties: {
          hub_activation_status: active,
          hub_notifications_count: getAppsNotificationsCount(applications),
          apps_activation_status: getAppsActivationStatus(organization!, applications, patient!),
        },
      });
    }
  }, [allAppsLoaded, analyticsClient]);

  return (
    <SelectedApplicationIdContext.Provider
      value={[selectedAppId, changeSelectedAppId, () => setMouseEnteredAppUI(true)]}
    >
      <ApplicationsContext.Provider value={applications}>{children}</ApplicationsContext.Provider>
    </SelectedApplicationIdContext.Provider>
  );
};

export const useApplications = (): Record<Application['id'], Application> => {
  return useContext(ApplicationsContext);
};

export const useSelectedApplicationId = () => {
  return useContext(SelectedApplicationIdContext);
};
