import { Button } from '@getvim/atomic-ui';
import { Entities, Infra } from '@getvim/vim-connect';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { shouldShowManagementApp, sortApplications } from '../../../common';
import { Application } from '../../../components/app/types/application';
import { UserManagement } from '../../../components/app/types/management-application';
import { CloseButton } from '../../atoms';
import { AppMenuIcon, HubMenuIcon } from '../../molecules';

import './index.less';

export interface AppsMenuProps {
  /**
   * Is the menu active - controls if it's semi-expanded by default
   */
  active?: boolean;

  /**
   * Is the menu valid - controls if should be interactable
   */
  invalid?: boolean;
  applications: Record<Application['id'], Application>;
  selectedApp?: Application;
  selectedAppId?: string;
  hubPosition: Infra.Common.Types.HubPosition;
  userManagement: UserManagement;
  moveAppMenu: (direction: Infra.Common.Types.HubDirection) => void;
  onApplicationClick: (id: string, expandingType: Entities.UIElements.ExpandingType) => void;
  onClose: () => void;
  onMenuShrinkChange?: (isShrinked: boolean) => void;
  onHubIconHover?: () => void;
  organizationId?: number;
}

export const AppsMenu: React.FC<AppsMenuProps> = ({
  active,
  invalid,
  hubPosition,
  moveAppMenu,
  selectedApp,
  selectedAppId,
  applications,
  userManagement,
  onApplicationClick,
  onMenuShrinkChange,
  onHubIconHover,
  onClose,
  organizationId,
}) => {
  const [shrink, setShrink] = useState(true);
  const [showUserManagementApp, setShowUserManagementApp] = useState(false);

  useEffect(() => {
    const getFF = async () => {
      const showUserManagementAppFF = await shouldShowManagementApp(organizationId);
      setShowUserManagementApp(showUserManagementAppFF);
    };
    getFF();
  }, [organizationId]);

  const expanded = active || !shrink || selectedAppId;
  const isHubPositionUpperLimit =
    hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.High;
  const isHubPositionLowerLimit =
    hubPosition.vertical === Infra.Common.Types.VerticalHubPosition.Low;
  const isHubPositionLeft =
    hubPosition.horizontal === Infra.Common.Types.HorizontalHubPosition.Left;
  const horizontalOppositeDirection = isHubPositionLeft
    ? { horizontal: Infra.Common.Types.HorizontalDirection.Right }
    : { horizontal: Infra.Common.Types.HorizontalDirection.Left };

  const sortedApps = useMemo(
    () => Object.values(applications).sort(sortApplications),
    [applications],
  );

  const totalNotifications =
    sortedApps.reduce((sum, curr) => sum + (curr?.notifications ?? 0), 0) +
    (userManagement?.notifications || 0);

  const loading = Object.values(applications).some((app) => app.loading);

  const changeShrink = (newVal: boolean) => {
    if (selectedAppId || invalid) {
      return;
    }
    setShrink(newVal);

    if (shrink !== newVal) {
      onMenuShrinkChange?.(newVal);
    }
  };

  const handleMouseLeave = () => {
    changeShrink(true);
  };

  const handleMouseEnter = () => {
    changeShrink(false);
  };
  const onSelectedAppClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!selectedAppId) {
      changeShrink(true);
    }
  }, [selectedAppId]);

  const [sholdHideHubCloseButton] = useFeatureFlag({
    flagName: 'shouldHideHubCloseButtonInAppsMenu',
    defaultValue: false,
  });

  return (
    <div
      className={classNames('apps-menu', {
        'hub-positioned-left': isHubPositionLeft,
        'selected-mode': selectedAppId,
      })}
    >
      {!expanded || invalid ? (
        <HubMenuIcon
          notifications={totalNotifications}
          ignoreNotificationsValue={shrink}
          invalid={invalid}
          shrink
          inactive={!active && !loading}
          loading={loading}
          onMouseEnter={() => {
            handleMouseEnter();
            onHubIconHover?.();
          }}
        />
      ) : (
        <div className="expanded" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {selectedAppId ? (
            <>
              {sholdHideHubCloseButton ? (
                <HubMenuIcon
                  notifications={shrink ? totalNotifications : 0}
                  inactive={!active && !loading}
                  loading={loading}
                  onMouseEnter={onHubIconHover}
                />
              ) : (
                <div role="none" className="icon-container menu-close" onClick={onSelectedAppClose}>
                  <CloseButton />
                </div>
              )}
            </>
          ) : (
            <>
              <Button
                className={classNames('icon-chevron-up', 'chevron-button', 'up', {
                  disabled: isHubPositionUpperLimit,
                })}
                buttonType="link"
                onClick={() => {
                  moveAppMenu({ vertical: Infra.Common.Types.VerticalDirection.Up });
                }}
              />
              <div className="expanded-hub-menu-icon-wrapper">
                <HubMenuIcon
                  notifications={shrink ? totalNotifications : 0}
                  inactive={!active && !loading}
                  loading={loading}
                  onMouseEnter={onHubIconHover}
                />
                <Button
                  className="icon-chevron-left chevron-button side-arrow"
                  buttonType="link"
                  onClick={() => {
                    moveAppMenu(horizontalOppositeDirection);
                  }}
                />
              </div>
            </>
          )}
          {(!shrink || selectedAppId) && (
            <div className="applications-menu-icons">
              {sortedApps.map((app) => (
                <AppMenuIcon
                  key={app.id}
                  selected={selectedApp?.id === app.id}
                  onClick={() => {
                    onApplicationClick(app.id, Entities.UIElements.ExpandingType.VIM_HUB_MANUAL);
                  }}
                  {...app}
                  hubPosition={hubPosition}
                />
              ))}
              {showUserManagementApp && (
                <AppMenuIcon
                  key={userManagement.id}
                  selected={selectedAppId === userManagement.id}
                  onClick={() => {
                    onApplicationClick(
                      userManagement.id,
                      Entities.UIElements.ExpandingType.VIM_HUB_MANUAL,
                    );
                  }}
                  {...userManagement}
                  hubPosition={hubPosition}
                />
              )}
            </div>
          )}
          {!selectedAppId && (
            <Button
              className={classNames('icon-chevron-down', 'chevron-button', 'down', {
                disabled: isHubPositionLowerLimit,
              })}
              buttonType="link"
              onClick={() => {
                moveAppMenu({ vertical: Infra.Common.Types.VerticalDirection.Down });
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
